import axios from 'axios'
import authHeader from '@/helpers/auth_header'
import unauthorized from '@/helpers/unauthorized'
import badge_event from '@/helpers/badge_event'
import { reactive } from 'vue';

const REST_ENDPOINT = process.env.VUE_APP_API_SERVER

export const messageService = {
  list,
  view,
  post,
  chat
};

function list (id) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}messages`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function chat (data) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}messages/chat`,
      method: 'POST',
      data: data,
      headers: authHeader()
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function view (id) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}messages/${id}`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function post (id,data) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}messages/${id}`,
      method: 'POST',
      data: data,
      headers: authHeader()
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}