<template>
  <div class="main-content loading-area">
    <div class="header-back-nav"><router-link to="/">&larr; กลับไปก่อนหน้า</router-link></div>
    
    <div class="card-container">
      <hgroup>
        <span>เกี่ยวกับเรา</span>
        <h1>โครงการเพื่อนเพื่อเพื่อน</h1>
      </hgroup>
      <div class="card-body">
      <p>“เพื่อนเพื่อเพื่อน” (Peer to Peer Harm Reduction Development) คือหลักสูตรเนื้อหาในรูปแบบ E-learning ที่ครอบคลุมองค์ความรู้และทักษะที่จำเป็นในการเข้าถึงผู้ใช้สารเสพติดชนิดฉีด หรือที่เรียกสั้น ๆ ว่า PWID โดยย่อมาจาก People who inject drugs เพื่อช่วยให้ผู้ใช้สารเสพติดชนิดฉีดได้ตระหนักถึงความสำคัญในการป้องกันการติดเชื้อเอชไอวี รับรู้แนวทางในการป้องกันที่ถูกต้อง รวมถึงเข้ารับการรักษาที่เหมาะสม เพื่อลดอันตรายจากการติดเชื้อเอชไอวี </p>
      <p>หลักสูตร “เพื่อนเพื่อเพื่อน” เป็นสิ่งจำเป็นสำหรับ “อาสาสมัคร” ซึ่งเป็นผู้ปฏิบัติงานที่ต้องลงพื้นที่เพื่อเข้าถึงกลุ่มผู้ใช้สารเสพติดชนิดฉีด เพราะเนื้อหาในบทเรียนต่าง ๆ จะช่วยพัฒนาศักยภาพของอาสาสมัครให้มีความรู้และความเข้าใจอย่างลึกซึ้งและรอบด้าน โดยเนื้อหาทั้งหมดได้แบ่งออกเป็น 3 หมวดหลัก ดังนี้ </p>

      <h3>ปฐมนิเทศ</h3>
      <ul>
        <li>สร้างความเข้าใจที่ตรงกันเกี่ยวกับวัตถุประสงค์ของโครงการและหลักสูตร</li>
        <li>ความรู้พื้นฐาน: ถ่ายทอดความรู้เรื่องสารเสพติด วิธีการใช้สารเสพติด เทคนิคการทำงานร่วมกับผู้ใช้สารเสพติด และข้อควรรู้ด้านกฎหมาย </li>
        <li>วิชาเพิ่มเติม: ถ่ายทอดความรู้เชิงลึกในหัวข้อต่าง ๆ เช่น เชื้อเอชไอวี วัณโรค และการเลิกยาขั้นสูง เป็นต้น </li>
      </ul>

      <p>ตลอดหลักสูตร “เพื่อนเพื่อเพื่อน” ผู้เรียนจะได้เพลิดเพลินไปกับบทเรียนต่าง ๆ ที่ถ่ายทอดออกมาในรูปแบบที่เข้าใจง่าย ได้ฟังเรื่องราวประสบการณ์จริงของผู้รู้และผู้เชี่ยวชาญ นอกจากเนื้อหาแล้วยังมีเกมหลากหลายรูปแบบที่สอดแทรกอยู่ภายในบทเรียนเพื่อช่วยให้ผู้เรียนได้ทบทวนบทเรียนในรูปแบบที่แตกต่างไปจากเดิม รวมถึงช่วยเสริมสร้างบรรยากาศให้การเรียนสนุกสนานยิ่งขึ้นด้วย</p>
      </div>
    </div>
    <LoadingSpinner v-if="loading"/>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
export default ({
  components: {
    LoadingSpinner
  },
  data() {
    return { 
      loading: false,
    }
  },
  created() {
  },
  methods: {
  },
  computed: {
  }
})
</script>

<style lang="scss" scoped>
</style>
