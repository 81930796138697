<template>
  <Modal @close="hide" size="medium">
    <hgroup>
      <span>รูปประจำตัว</span>
      <h1>เปลี่ยนรูปภาพประจำตัว</h1>
    </hgroup>
    <div class="upload-content">
      <file-upload title="คลิกหรือลากไฟล์ภาพเข้ามาเพื่ออัพโหลด"  @change="doUpload"/>
    </div>
    <LoadingSpinner v-if="loading"/>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { userService } from '@/services/user';
import FileUpload from '@/components/FileUpload.vue'

export default {
  components: {
    Modal,
    LoadingSpinner,
    FileUpload
  },
  data () {
    return {
      loading: false,
      code: ''
    }
  },
  emits: ['cancel'],
  props: ['course'],
  methods: {
    hide() {
      this.$emit('cancel');
    },
    doUpload(data) {
      this.loading = true
      userService.uploadAvatar(data)
        .then((res)=>{
          console.log(res)
          let profile = this.$store.state.user.profile
          profile.profile_pic = res.url
          this.$store.dispatch('user/profile',profile)
          this.loading = false
          this.hide()
        },(res)=>{
          alert('ไม่สามารถอัพโหลดได้')
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>