<template>
  <div class="main-content loading-area">
    <transition name="slide-fade">
    <div v-if="content">
      <section v-if="hasNext">
        <div class="course-next">
          <div class="course-next-thumbnail"  @click="gotoCourse(content.next)">
            <background-video :video="content.next.trailer"  />
          </div>
          <div class="course-next-detail">
            <div class="course-next-content">
              <hgroup>
                <span>{{content.next.type}}</span>
                <h1>{{content.next.title}}</h1>
              </hgroup>
              {{content.next.content}}
            </div>
            <div class="course-next-button">
              <button v-if="content.next.is_enrolled" class="btn-primary btn-lg btn-block" @click="gotoCourse(content.next)">เรียนต่อ</button>
              <button v-else class="btn-primary btn-lg btn-block" @click="showEnroll(content.next)">ลงเรียนเลย</button>
            </div>
          </div><!-- .course-next-detail -->
        </div><!-- .course-next -->
      </section>
      
      <section v-if="hasCourses">
        <hgroup class="header-secondary">
          <h1>คอร์สทั้งหมด</h1>
          <span>ข้อมูลเจาะลึกเนื้อหาในแต่ละเรื่องราว เพื่อเข้าถึง เข้าใจ และแก้ปัญหาเชิงลึกได้อย่างตรงจุด</span>
          <div class="header-search">
            <input type="text" class="header-search-input" ref="coursesearch" v-model="course_search" placeholder="ค้นหาชื่อคอร์ส"/>
            <div class="header-search-clear">
              <img v-if="course_search" @click="course_search=null" src="~@/assets/images/icon-x.svg"/>
              <img v-else @click="$refs.coursesearch.focus()" src="~@/assets/images/icon-search.svg"/>
            </div>
          </div>
        </hgroup>
        <div class="courses-list">
          <div v-for="item in courseList" class="course-item" @click="gotoCourse(item)" :key="item.id">
            <div class="course-item-image">
              <div class="course-item-image-wrapper">
                <thumbnail-video :video="item.trailer" :thumbnail="item.thumbnail"  :autoplay="false" :hoverstop="true" :clickstop="false" />
              </div>
              <span class="course-item-type">{{item.type}}</span>
              <span class="course-item-lesson">{{item.chapters_count}} บทเรียน</span>
            </div>
            <div class="course-item-detail">
              <h2><span>{{item.title}}</span></h2>
              <p>{{item.content}}</p>
            </div>
          </div>
        </div>
        <div class="no-course-found" v-if="courseList.length==0">
          ไม่พบคอร์สที่ต้องการค้นหา
        </div>
      </section>

      <section v-if="hasInspirations">
        <inspirations :list="content.inspirations"/>
      </section>

      <section v-if="hasNews">
        <news :list="content.news"/>
      </section>
    </div>
    </transition>
    <loading-spinner v-if="loading"/>
  </div>

  <transition name="slide-fade">
    <enroll v-if="isEnroll" :course="show_enroll" @cancel="showEnroll(null)"/>
  </transition>
</template>

<script>
import ThumbnailVideo from '@/components/ThumbnailVideo.vue'
import BackgroundVideo from '@/components/BackgroundVideo.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import Enroll from '@/components/Enroll.vue'
import Inspirations from '@/components/content/Inspirations.vue'
import News from '@/components/content/News.vue'
import { pageService } from '@/services/page';

export default ({
  components: {
    BackgroundVideo,
    ThumbnailVideo,
    LoadingSpinner,
    Inspirations,
    News,
    Enroll
  },
  data() {
    return { 
      content: null,
      loading: true,
      show_enroll: null,
      course_search: null
    }
  },
  created() {
    pageService.home().then((res)=>{
      this.loading = false
      this.content = res
    },(res)=>{
      this.loading = false
    })
  },
  methods: {
    showEnroll(item) {
      this.show_enroll = item;
    },
    gotoCourse(item) {
      if(item.is_enrolled) {
        if(item.next_content_id) {
          this.$router.push({ path: '/course/'+item.id+'/'+item.next_content_id })
        } else {
          this.$router.push({ path: '/course/'+item.id })
        }
      } else {
        this.showEnroll(item)
      }
    }
  },
  computed: {
    isEnroll() {
      return this.show_enroll!==null
    },
    hasInspirations() {
      return this.content.inspirations && this.content.inspirations.length > 0
    },
    hasNews() {
      return this.content.news && this.content.news.length > 0
    },
    hasCourses() {
      return this.content.all_courses && this.content.all_courses.length > 0
    },
    hasNext() {
      return this.content.next && this.content.next.id > 0
    },
    courseList: function () {
      if(!this.course_search) return this.content.all_courses;
      if(this.content.all_courses) {
        return this.content.all_courses.filter( (item)=> {
          let itemUpper = item.title.toLowerCase();
          let searchUpper = this.course_search.toLowerCase();
          return itemUpper.indexOf(searchUpper) > -1;
        })
      } else {
        return []
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.main-content {
  padding-top: 0;
}
.course-next-thumbnail,
.course-item {
  cursor: pointer;
}
.no-course-found {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 1.4em;
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid $color-primary;
  color: $color-primary;
  line-height: 2em;
}
</style>
