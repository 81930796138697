<template>
  <div class="video-player-wrapper">
    <video v-if="src" ref="videoPlayer" class="video-js vjs-theme-fantasy"></video>
  </div>
</template>

<script>
import videojs from 'video.js'

export default {
  name: 'VideoPlayer',
  props: ['src','autoplay'],
  data () {
    return {
      player: null
    }
  },
  mounted () {
    if(this.src) {
      let options = {
        sources: [{src:this.src}],
        autoplay: this.autoplay,
        controls: true,
        muted: false,
        responsive: true,
        fluid: true,
        loop: false,
        fill: true
      }
      this.player = videojs(this.$refs.videoPlayer, options, () => {
        
      })
    }
    
  },
  beforeUnmount () {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    showOverlay() {
      
    }
  }
}
</script>

<style lang="scss" scoped>

</style>