<template>
  <div id="header-profile-bg" :class="{hasBadge:showBadge}"></div>
  <div id="header-profile" :class="{hasBadge:showBadge}">
    <div class="container loading-area" v-if="!loading">
      <div class="profile-content">
        <div class="profile-avatar">
          <div>
          <router-link to="/profile">
            <img v-if="profile.profile_pic" :src="profile.profile_pic" />
            <svg v-else xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="40" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="7" r="4" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg>
          </router-link>
          </div>
        </div>
        <div class="profile-detail">
          <router-link to="/profile"><strong>{{profile.display_name?profile.display_name:profile.fullname}}</strong></router-link>
          <small><strong>{{profile.organization}}</strong> / {{profile.rank}}</small>
        </div>
        <div class="profile-buttons">
          <div class="notification-dropdown" @click="notiDropdown">
            <button>
              <span>แจ้งเตือน</span>
              <img src="../assets/images/icon-envelope.svg" />
              <small :class="{ hasBadge: notifications.length }">{{notifications.length}}</small>
            </button>
            <transition name="slide-fade">
              <div class="notification-list-wrapper" v-show="dropdown_noti">
                <div class="notification-homework-list" v-if="notifications.length>0">
                  <div class="notification-homework-item" v-for="noti in notifications" :key="noti.id" @click="gotoNoti(noti)">
                    <div class="notification-item-wrapper">
                      <div class="notification-icon">
                        <img v-if="noti.type==='chat'" src="~@/assets/images/noti/icon-chat.svg"/>
                        <img v-else-if="noti.type==='assignment'" src="~@/assets/images/noti/icon-assignment.svg"/>
                        <img v-else src="~@/assets/images/noti/icon-news.svg"/>
                      </div>
                      <div class="notification-detail">
                        <strong>{{noti.title}}</strong>
                        <p>{{noti.content}}</p>
                      </div>
                    </div>
                  </div><!-- .notification-homework-item -->
                </div><!-- .notification-homework-list -->
                <div v-else class="notification-none">
                  ไม่มีการแจ้งเตือนใหม่
                </div>
              </div>
            </transition>
          </div>
          <div class="notification-dropdown" @click="homeworkDropdown">
            <button>
              <span>การบ้าน</span>
              <img src="../assets/images/icon-homework.svg" />
              <small :class="{ hasBadge: profile.assignments_count }">{{profile.assignments_count}}</small>
            </button>
            <transition name="slide-fade">
              <div class="notification-list-wrapper" v-show="dropdown_homework">
                <div class="notification-homework-list" v-if="assignments.length>0">
                  <div class="notification-homework-item" v-for="ass in assignments" :key="ass.id" @click="gotoHw(ass)">
                    <strong>{{ass.course_title}}</strong>
                    <span>{{ass.title}}</span>
                    <div class="notification-homework-footer"  :class="hwClass(ass.handle)">
                      <strong>{{hwText(ass.handle)}}</strong>
                      <span v-if="ass.upload_at">วันที่ส่ง: {{showTime(ass.upload_at)}}</span>
                    </div>
                  </div>
                </div><!-- .notification-homework-list -->
                <div v-else class="notification-none">
                  ไม่มีการบ้าน
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <transition name="slide-fade">
        <div v-if="showBadge" class="header-badges-wrapper">
          <div class="header-badges">
            <swiper :watchSlidesVisibility="true" :breakpoints="swiper_breakpoints" :navigation="swiper_navigation" :loop="false" >
              <swiper-slide v-for="(badge) in profile.badges" :key="badge.id">
                <div class="header-badge">
                  <span>{{badge.title}}</span>
                  <img v-if="badge.thumbnail" :src="badge.thumbnail" :alt="badge.title" />
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </transition>
      <LoadingSpinner v-if="loading"/>
    </div>
  </div>
</template>

<script>

import { userService } from '@/services/user';
import { etcService } from '@/services/etc';
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
SwiperCore.use([Navigation]);
export default {
  components: {
    LoadingSpinner,
    Swiper,
    SwiperSlide
  },
  data() {
    return { 
      dropdown_noti: false,
      dropdown_homework: false,
      loading: true,
      notifications: [],
      assignments: [],
      swiper_navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      swiper_breakpoints: {
        320: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 5
        },
        480: {
          slidesPerView: 5,
          slidesPerGroup: 5,
          spaceBetween: 5
        },
        640: {
          slidesPerView: 8,
          slidesPerGroup: 8,
          spaceBetween: 5
        },
        780: {
          slidesPerView: 10,
          slidesPerGroup: 10,
          spaceBetween: 5
        },
        960: {
          slidesPerView: 12,
          slidesPerGroup: 12,
          spaceBetween: 5
        },
        1024: {
          slidesPerView: 14,
          slidesPerGroup: 14,
          spaceBetween: 5
        },
        1200: {
          slidesPerView: 16,
          slidesPerGroup: 16,
          spaceBetween: 5
        }
      }
    }
  },
  props: {
    showBadge: {
      type: Boolean,
      default: false
    }
  },
  name: "Header",
  methods: {
    logout(event) {
      userService.logout()
      this.$store.dispatch('user/logout')
      this.$router.push('login')
    },
    notiDropdown() {
      this.dropdown_noti = !this.dropdown_noti
      if(this.dropdown_noti)this.dropdown_homework = !this.dropdown_noti
    },
    homeworkDropdown() {
      this.dropdown_homework = !this.dropdown_homework
      if(this.dropdown_homework)this.dropdown_noti = !this.dropdown_homework
      
    },
    refreshNotification() {
      etcService.notification().then((res)=>{
        this.assignments = res.assignments
        this.notifications = res.notifications
      },(res)=>{
        console.log(res)
      })
    },
    hwClass(handle) {
      let tclass = ''
      switch (handle) {
        case "pending": tclass=''; break;
        case "grading": tclass='homework-pending'; break;
        case "failed": tclass='homework-done'; break;
        case "pass": tclass='homework-done'; break;
      }
      return tclass;
    },
    hwText(handle) {
      let text = ''
      switch (handle) {
        case "pending": text='ค้างส่ง'; break;
        case "grading": text='รอตรวจ'; break;
        case "failed": text='ตรวจแล้ว'; break;
        case "pass": text='ตรวจแล้ว'; break;
      }
      return text;
    },
    showTime(date) {
      const d = new Date(date)
      let options = { dateStyle: 'short', timeStyle: 'short' };
      return d.toLocaleString('th-TH',options);
    },
    gotoHw(hw) {
      this.$router.push('/course/'+hw.course_id+'/'+hw.content_id)
    },
    gotoNoti(noti) {
      /* if(noti.type==="assignment") {
        this.$router.push('/course/'+noti.course_id+'/'+noti.content_id)
      } else if(noti.type==="news") {
        this.$router.push('/news/'+noti.news_id)
      } else */
      if (noti.type==="chat") {
        this.$store.dispatch('message/setActiveChat',noti.chat_id)
        this.$store.dispatch('message/showMessage')
      } else if (noti.url) {
        window.location.href = noti.url
      }
    }
  },
  created() {
    if( !this.$store.state.user.profile ) {
      userService.profile().then((res)=>{
        this.loading = false
        this.$store.dispatch('user/profile',res)
      },(res)=>{
        this.loading = false
      })
    } else {
      this.loading = false
    }

    this.refreshNotification()
    this.notiInt = setInterval(()=>{
      this.refreshNotification()
    },20000)
  },
  computed: {
    isAuth() {
      return this.$store.state.user.loggedIn
    },
    profile() {
      if( this.$store.state.user.profile ) {
        return this.$store.state.user.profile;
      } else {
        return null;
      }
    }
  },
  unmounted() {
    clearInterval( this.notiInt )
  }
}
</script>

<style lang="scss" scoped>
#header-profile {
  position: relative;
  color: $color-text;
  margin:  0;
  height:80px;
  transition: height 0.3s ease-in-out;
  width: 100%;
  z-index:2;

  font-family: $font-family-heading;
  .avatar {
    width: 50px;
  }

  .container {
    position: relative;
    z-index:2;
  }

  @media only screen and (max-width: 768px) {
    height: 70px;
  }

  .profile-content {
    display: flex;
    padding: 12px 0 2px 0;

    .profile-avatar {
      width: 60px;
      height: 60px;
      border-radius: 60px;
      box-shadow: $shadow-small;
      overflow: hidden;
      cursor: pointer;
      background: #f1f1f1;
      text-align:center;

      svg {
        margin: 10px 0 0 0;
      }


      @media only screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
      }
    }
    .profile-detail {
      padding: 0 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 1.4em;
      color: #fff;
      text-shadow: $shadow-text;

      a {
        color: #fff;
      }
      small {
        font-weight: 500;
      }

      @media only screen and (max-width: 768px) {
        font-size: 0.9em;
        padding: 0 10px;
      }
    }
    .profile-buttons {
      display: flex;
      padding: 10px 0 0 0;

      &>div {
        margin: 0 0 0 10px;
      }
      button {
        color: $color-trinary;
        white-space: nowrap;
        padding: 0 22px 2px;
        line-height: 25px;
        height: 38px;
        margin: 0 0 0 10px;
        font-weight: 500;
        font-size: 0.8em;
        position: relative;

        &:hover {
          background: #fff;
          color: $color-primary;
        }

        img {
          width: 24px;
          margin: 0 0 0 3px;
          vertical-align: middle;
        }

        small {
          position: absolute;
          top: -5px;
          right: 0;
          padding: 2px 5px;
          margin: 0;
          line-height: 1.3em;
          border-radius: 10px;
          font-size: 0.7em;
          color: #666;
          background: $color-primary;
          color: #fff;

          display:none;
        }

        small.hasBadge {
          display: inline-block;
        }

        @media only screen and (max-width: 768px) {
          width: 30px;
          height: 30px;
          line-height: 30px;
          padding: 0;
          margin: -5px 0 5px;
          text-align: center;
          span {
            display: none;
          }
          img {
            width: 18px;
            padding: 0;
            margin: -3px 0 0 0;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
#header-profile-bg {
  width: 100%;
  position: absolute;
  height: 130px;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    height: 160px;
  }

  &.hasBadge {
    height: 170px;

    @media only screen and (max-width: 768px) {
      height: 200px;
    }
  }

  &:after{
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: -15px;
    right: -15px;
    height: 100%;
    background: $color-secondary-80;
    border-radius: 200px;
    z-index: 1;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
    overflow: hidden;

    @media only screen and (max-width: 768px) {
      border-radius: 80px;
      left: -30px;
      right: -30px;
    }
  }
}
#header-profile.hasBadge {
  height: 150px;
  @media only screen and (max-width: 768px) {
    height: 140px;
  }
}
.header-badges-wrapper {
  margin: 0 auto;
  height: 30px;
  position: relative;
  width: 100%;
  .header-badges {
    background: #fff;
    height: 60px;
    padding: 5px 50px;
    border-radius: 60px;
    position: absolute;
    top: 15px;
    box-shadow: $shadow-small;
    width: 100%;
    text-align: center;
  }
  .header-badge {
    height: 50px;
    width: 100%;
    text-align: center;
    position: relative;

    img {
      height: 100%;
    }

    span {
      position: absolute;
      font-size: 0.8em;
      top: -22px;
      white-space: nowrap;
      background: #fff;
      text-align: center;
      padding: 2px 5px;
      border-radius: 5px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0 0 3px rgba(0,0,0,0.2);
      opacity: 0;
      transition: 0.3s;
    }
    &:hover span {
      opacity: 1;
    }
  }
}
.notification-dropdown {
  position: relative;
  line-height: 0;
  z-index: 9999;

  .notification-list-wrapper {
    display: block;
    list-style: none;
    padding: 20px 20px 10px;
    margin: 0;
    position: absolute;
    background: #fff;
    width: 300px;
    color: $color-gray;
    right: 0px;
    top: 50px;
    border-radius: 10px;
    box-shadow: $shadow-big;
    line-height: 1.6em;
    z-index: 9999;

    @media only screen and (max-width: 768px) {
      right: -10px;
      width: 250px;
    }

    &:before {
      content:"";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      display: block;
    }
    .notification-homework-item {
      position: relative;
      padding: 0 0 5px;
      margin: 0 0 5px;
      font-size: 0.8em;
      line-height: 1.4em;
      cursor:pointer;
      &>strong {
        display: block;
      }
      &>span {
        display: block;
      }
      border-bottom: 1px dashed #ddd;
      &:last-child {
        border-bottom: none;
      }
      position: relative;
      z-index: 999;
    }
    .notification-homework-footer {
      display: flex;
      justify-items: space-between;
      margin: 4px 0 2px 0;
      line-height: 1.2em;
      &>strong {
        flex: 1;
        color: $color-primary;
        position: relative;
        padding: 0 0 0 20px;
        &:before {
          content: "";
          display: block;
          position: absolute;
          background: url(~@/assets/images/icon-alert.svg) center center;
          background-size: 14px;
          width: 14px;
          height: 14px;
          left: 0;
          top: 1px;
        }
      }
      &.homework-pending>strong {
        color: $color-secondary;
        &:before {
          background-image: url(~@/assets/images/icon-clock.svg);
        }
      }
      &.homework-done>strong {
        color: $color-success;
        &:before {
          background-image: url(~@/assets/images/icon-click.svg);
        }
      }
      &>span {
        flex: 1;
        white-space: nowrap;
      }
    }

    &:after {
      bottom: 100%;
      right: 20px;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #ffffff;
      border-width: 8px;
      margin-left: -8px;
    }
  }
}
.notification-none {
  text-align: center;
  padding: 0 0 10px;
}
.notification-item-wrapper {
  display: flex;
  padding: 5px 0 0 0;
  position: relative;
  .notification-icon {
    margin-right: 10px;
  }
  .notification-detail {
    flex:1;
  }
  // &:after {
  //   content: "";
  //   display:block;
  //   width: 8px;
  //   height: 8px;
  //   border-radius: 10px;
  //   background: $color-primary;
  //   position:absolute;
  //   top: 50%;
  //   right: 10px;
  //   margin-top: -10px;
  // }
}
</style>
