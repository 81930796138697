<template>
  <div class="article-wrapper">
    <hgroup>
      <span>Certificate</span>
      <h2>{{content.infos.title}}</h2>
    </hgroup>
    <p class="text-center"><br/>ขอแสดงความยินดี คุณได้รับประกาศนียบัตรแล้ว<br/><br/></p>
    <p class="text-center"><button class="btn-primary" @click="downloadCert">ดาวน์โหลดประกาศนียบัตร</button></p>
  </div>
</template>

<script>
import { courseService } from '@/services/course';
export default {
  name: 'Certificate',
  props: ['content'],
  components: {
  },
  data () {
    return {
      downloaded: false
    }
  },
  mounted () {
  },
  beforeUnmount () {
  },
  methods: {
    downloadCert() {
      courseService.downloadCert(this.$route.params.id,this.$route.params.content_id).then((res)=>{
        this.loading = false
        //console.log(res)
        if(res.success) {
          this.downloaded = true
        }
      },(res)=>{
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.article-wrapper {
  min-height: 400px;
  background: #fff;
  padding: 20px 40px;
}
</style>