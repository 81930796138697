<template>
  <template v-if="question.type==='multiple_choice'">
    <div class="question-choice" v-for="choice in question.choices" :key="choice.id" :class="{'picked-choice':shouldBeChecked(choice.id)}">
      <label class="label-radio">
        <input type="radio" :checked="shouldBeChecked(choice.id)" :value="choice.id" @change="updateInput"/>
        {{choice.title}}
      </label>
    </div>
  </template>
  <template v-if="question.type==='multiple_selection'">
    <div class="question-choice" v-for="choice in question.choices" :key="choice.id" :class="{'picked-choice':shouldBevalues(choice.id)}">
      <label class="label-checkbox">
        <input type="checkbox" :value="choice.id" v-model="values"/>
        {{choice.title}}
      </label>
    </div>
  </template>
  <template v-else-if="question.type==='short_answer'">
    <div class="question-short-answer">
      <input type="text" :value="modelValue"  @input="$emit('update:modelValue', $event.target.value)" >
    </div>
  </template>
  <template v-else-if="question.type==='matching'">
    <div class="question-matching-wrapper">
      <div class="question-matching">
        <div class="question-matching-left" v-for="choice in leftChoices" :key="choice.id" :id="'matching'+choice.id">
          <div class="question-matching-choice" :class="{'choice-focus':focusLeft===choice.id}" @click="matchingClick('left',choice.id)">
            {{choice.title}}
          </div>
        </div>
      </div>
      <div class="question-matching-lines">
        <div class="question-matching-line">
          <svg>
            <line x1="5" x2="98%" y1="0" y2="0" v-for="choice in leftChoices" :key="choice.id" :id="'matchingline'+choice.id"  class="question-matching-svg-line"/>
          </svg>
        </div>
      </div>
      <div class="question-matching">
        <div class="question-matching-right" v-for="choice in rightChoices" :key="choice.id" :id="'matching'+choice.id">
          <div class="question-matching-choice" :class="{'choice-focus':focusRight===choice.id}" @click="matchingClick('right',choice.id)">
            {{choice.title}}
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else-if="question.type==='assay'">
    <p class="question-short-answer">
      <textarea rows="5" :value="modelValue"  @input="$emit('update:modelValue', $event.target.value)" ></textarea>
    </p>
  </template>
</template>

<script>
export default {
  name: 'LessonQuiz',
  props: ['modelValue','question'],
  data() {
    return{
      values:[],
      focusLeft: null,
      focusRight: null,
    }
  },
  mounted() {
    if(this.question.type==='matching') {
      if(this.question.picked_answer) {
        this.values = this.question.picked_answer
      } else {
        this.values = this.leftChoices.map((val)=>{
          return {id:val.id,value:null}
        })
      }
      this.matchingLine()
    }
  },
  computed: {
    leftChoices() {
      return this.question.choices.filter((choice)=>{return choice.side==='left'})
    },
    rightChoices() {
      return this.question.choices.filter((choice)=>{return choice.side==='right'})
    },
  },
  methods: {
    updateInput(e) {
      this.$emit('update:modelValue', e.target.value)
    },
    shouldBeChecked(val) {
      return this.modelValue == val
    },
    shouldBevalues(val) {
      return this.values.indexOf(val) >= 0
    },
    matchingClick(side, id) {
      let left = this.focusLeft
      let right = this.focusRight
      if(side === 'left')left = id
      if(side === 'right')right = id
      if(left !== null && right !== null) {
        this.values = this.values.map((val)=>{
          if(val.id===left) val.value = right
          return val
        })

        left = null
        right = null
        this.matchingLine()
      }

      this.focusLeft = left
      this.focusRight = right
    },
    matchingLine(){
      this.values.map((val)=>{
        let left = val.id
        let right = val.value
        if(left !== null && right !== null) {
          const leftEle = document.getElementById('matching'+left)
          const rightEle = document.getElementById('matching'+right)
          const line = document.getElementById('matchingline'+left)
          line.setAttribute('y1',leftEle.offsetTop+20)
          line.setAttribute('y2',rightEle.offsetTop+20)
          line.style.opacity = 1
        }
      })
    }
  },
  watch: {
    values: {
      handler: function (newValues) {
        this.$emit('update:modelValue', newValues)
      },
      deep: true
    }
  },
}
</script>

<style lang="scss" scoped>
.question-matching-wrapper {
  display: flex;
  position: relative;
  .question-matching {
    flex: 1;
  }
  .question-matching-choice {
    padding: 10px 20px;
    border-radius: 5px;
    position: relative;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      background: #f1f1f1;
    }
    &:active,
    &.choice-focus {
      background: lighten($color-secondary,20) !important
    }
  }
  .question-matching-left {
    margin: 0;
    padding: 0;
    text-align: right;
    .question-matching-choice {
      position: relative;
      &:before {
        content: "";
        position:absolute;
        display: block;
        width: 10px;
        height: 10px;
        border: 2px solid #888;
        top: 15px;
        right: 0;
        margin-top: -1px;
        border-radius: 20px;
      }
    }
  }
  .question-matching-right {
    padding: 0;
    text-align: left;
    .question-matching-choice {
      position: relative;
      &:before {
        content: "";
        position:absolute;
        display: block;
        width: 10px;
        height: 10px;
        border: 2px solid #888;
        top: 15px;
        left: 0;
        margin-top: -1px;
        border-radius: 20px;
      }
    }
  }

  .question-matching-lines {
    position: relative;
    flex: 1;
    .question-matching-line {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      transform-origin: left center;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .question-matching-svg-line {
    opacity: 0;
    stroke: $color-secondary;
    stroke-width: 3px;
    stroke-linecap: round;
    stroke-dasharray: 5,5;
    transition: opacity 0.3s;
  }
  .question-matching-svg-line:nth-child(2) {
    stroke: $color-primary;
  }
  .question-matching-svg-line:nth-child(3) {
    stroke: $color-success;
  }
  .question-matching-svg-line:nth-child(4) {
    stroke: $color-trinary;
  }
  .question-matching-svg-line:nth-child(5) {
    stroke: $color-gray;
  }
}
</style>