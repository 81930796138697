import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Content from '../views/Course/Content.vue'
import Summery from '../views/Course/Summery.vue'
import Inspiration from '../views/Inspiration.vue'
import News from '../views/News.vue'
import About from '../views/About.vue'
import Policy from '../views/Policy.vue'
import Profile from '../views/Profile.vue'
import ProfileEdit from '../views/ProfileEdit.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/course/:id/:content_id',
    name: 'Content',
    component: Content
  },
  {
    path: '/course/:id',
    name: 'Course',
    component: Summery
  },
  {
    path: '/inspiration/:id',
    name: 'Inspiration',
    component: Inspiration
  },
  {
    path: '/news/:id',
    name: 'News',
    component: News
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/policy',
    name: 'Policy',
    component: Policy,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/profile_edit',
    name: 'ProfileEdit',
    component: ProfileEdit
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters['user/loggedIn']
  window.scrollTo(0, 0);
  if (to.name == 'Login' && isLoggedIn) {
    next({ path: '/' })
  } else if (!to.meta.allowAnonymous && !isLoggedIn) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
})

export default router
