const AUTH_TOKEN_KEY = 'authToken'
const token = localStorage.getItem(AUTH_TOKEN_KEY)

const state = token ? { 
  loggedIn: true, 
  token: token ,
  profile: null
} : { 
  loggedIn: false, 
  token: null ,
  profile: null
}

const actions = {
  login(context, token) {
    context.commit('login',token)
    localStorage.setItem(AUTH_TOKEN_KEY, token)
  },
  logout(context) {
    context.commit('logout')
    localStorage.removeItem(AUTH_TOKEN_KEY)
  },
  profile(context, profile) {
    context.commit('profile',profile)
  },
}

const mutations = {
  login(state,token) {
    state.token = token
    state.loggedIn = true
  },
  logout(state) {
    state.token = null
    state.loggedIn = false
  },
  profile(state,profile) {
    state.profile = profile
  }
}

const getters = {
  loggedIn(state) {
    return state.loggedIn;
  },
  token(state) {
    return state.token;
  }
}

export const user = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}