<template>
  <div class="main-content loading-area">
    <div class="profile-page-header">
      <h1>ข้อมูลสมาชิก</h1>
      <div class="profile-edit-buttons">
        <button @click="changingAvatar=true">แก้ไขรูปโปรไฟล์</button>
        &nbsp;
        <router-link to="profile_edit" class="btn">
          แก้ไขข้อมูลสมาชิก <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
          <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
          <line x1="16" y1="5" x2="19" y2="8" /></svg>
      </router-link>
      </div>
    </div>
    <transition name="slide-fade">
    <div v-if="content">
      <section v-if="hasRanks">
        <div class="card-container">
          <hgroup>
            <span>บันไดสู่ความสำเร็จ</span>
            <h1>จากผู้รับสู่ผู้ให้</h1>
          </hgroup>
          <div class="ranks-list">
            <div class="ranks-item" v-for="item in content.ranks" :key="item.id" :class="{'current-rank':item.is_current}">
              <div>
                <img :src="item.thumbnail" :v-if="item.thumbnail"/>
                <small>{{item.name}}</small>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section v-if="hasBadges">
        <div class="card-container">
          <hgroup>
            <span>เหรียญตรา</span>
            <h1>ความสำเร็จของคุณ</h1>
          </hgroup>
          <div class="badges-list">
            <div class="badges-item" v-for="item in content.badges" :key="item.id">
              <div class="badges-item-image"><img v-if="item.thumbnail" :src="item.thumbnail"/></div>
              <div  class="badges-item-detail">
                <strong>{{item.title}}</strong>
                <span>{{item.content}}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section v-if="hasCourses">
        <div class="card-container">
          <hgroup>
            <span>ประวัติการเรียนรู้</span>
            <h1>วิชาที่กำลังเรียน</h1>
          </hgroup>
          <div class="profile-courses-list">
            <div class="profile-course-item" v-for="item in content.courses" :key="item.id" :class="{'profile-course-done':item.is_completed}">
              <div class="profile-course-item-image">
                <img v-if="item.thumbnail" :src="item.thumbnail"/>
              </div>
              <div class="profile-course-item-detail">
                <div class="profile-course-item-content">
                  <div class="profile-course-item-header">
                    <span>{{item.type}}</span>
                    <h1>{{item.title}}</h1>
                  </div>
                  <p>{{item.content}}</p>
                </div>
                <div class="profile-course-item-footer">
                  <div class="profile-course-item-progress">
                    <span v-if="item.is_completed===100">เรียนจบแล้ว</span>
                    <span v-else>กำลังเรียน</span>
                    <small v-if="item.percent_completed<100">{{item.percent_completed}}%</small>
                    <div class="profile-course-item-percentage-wrapper">
                      <div class="profile-course-item-percentage" :style="{ width: item.percent_completed + '%' }"></div>
                    </div>
                  </div>
                  <div class="profile-course-item-buttons">
                    <router-link :to="'/course/'+item.id" class="button btn-block">ดูบทเรียน</router-link>
                    <button class="btn btn-block" v-if="item.certificate_id" @click="downloadCert(item.id,item.certificate_id)">ดาวน์โหลดประกาศนียบัตร</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </transition>
    <loading-spinner v-if="loading"/>
    <change-avatar v-if="changingAvatar" @cancel="changingAvatar=false"/>
  </div>
</template>

<script>
import { pageService } from '@/services/page';
import { courseService } from '@/services/course';
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import ChangeAvatar from '@/components/ChangeAvatar.vue'
export default ({
  components: {
    LoadingSpinner,
    ChangeAvatar
  },
  data() {
    return { 
      content: null,
      loading: true,
      changingAvatar: false
    }
  },
  created() {
    pageService.profile().then((res)=>{
      this.loading = false
      this.content = res
    },(res)=>{
      this.loading = false
    })
  },
  methods: {
    showEnroll(item) {
      this.show_enroll = item;
    },
    downloadCert(course_id,content_id) {
      courseService.downloadCert(course_id,content_id).then((res)=>{
        this.loading = false
        //console.log(res)
        if(res.success) {
          this.downloaded = true
        }
      },(res)=>{
        this.loading = false
      })
    }
  },
  computed: {
    hasRanks() {
      return this.content.ranks && this.content.ranks.length > 0
    },
    hasBadges() {
      return this.content.badges && this.content.badges.length > 0
    },
    hasCourses() {
      return this.content.courses && this.content.courses.length > 0
    }
  }
})
</script>

<style lang="scss" scoped>
.main-content {
  padding-top: 10px;
}
.card-container {
  @extend %box;
}
.ranks-list {
  display: flex;
  margin: 10px 0;
  font-family: $font-family-heading;

  @media screen and (max-width:567px) {
    font-size: 0.7em;
    line-height: 1em;
  }

  .ranks-item {
    flex: 1;
    text-align: center;
    position: relative;

    img {
      height: 45px;
    }
    small {
      display: block;
      margin: 50px 5px 0;
    }

    &:after {
      content: "";
      background: $color-success url(~@/assets/images/icon-check-white.svg) no-repeat center center;
      background-size: 60%;
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      position: absolute;
      bottom: 27px;
      left: 50%;
      margin-left: -15px;
      z-index: 2;
      box-shadow: 1px 1px 4px rgba(0,0,0,0.4);
    }
    &:before {
      content: "";
      background: $color-success;
      display: block;
      width: 100%;
      height: 5px;
      position: absolute;
      bottom: 39px;
      left: calc(15px + -50%);
      z-index: 1;
    }
    &:first-child:before {
      display: none;
    }
  }
  .current-rank {
    &:after {
      width: 40px;
      height: 40px;
      bottom: 25px;
      background-image: url(~@/assets/images/icon-star-white.svg);
      margin-left: -21px;
    }
  }
  .current-rank ~ .ranks-item {
    opacity: 0.5;

    &:after {
      background-image: url(~@/assets/images/icon-dot-white.svg);
      background-size: 14px;
    }
    
  }
}
.badges-list {
  padding: 10px 20px 20px;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 40px;
  width: 100%;

  @media screen and (max-width:768px) {
    padding: 10px;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 20px;
  }
  @media screen and (max-width:567px) {
    padding: 0;
    grid-gap: 10px;
    grid-template-columns: repeat(1,1fr);
  }
  .badges-item {
    display: flex;
    .badges-item-image {
      width: 90px;
      margin: 0 20px 0 0;
    }
    .badges-item-detail {
      flex: 1;
      color: $color-gray;
      strong {
        display: block;
        font-size: 1.2em;
        margin: 0 0 5px;
        font-family: $font-family-heading;
      }
    }
  }
}
.profile-courses-list {
  padding: 20px 20px 0;
  @media screen and (max-width:768px) {
    padding: 10px 0;
  }
  .profile-course-item {
    display: flex;
    margin: 0 0 40px;
    
    @media screen and (max-width:576px) {
      flex-direction: column;
    }

    .profile-course-item-image {
      width: 45%;
      margin: 0 30px 0 0;
      @media screen and (max-width:576px) {
        width: 100%;
      }

      img{
        border-radius: $border-radius-small;
      }
    }
    .profile-course-item-detail {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: $color-gray;
      line-height: 1.6em;

      .profile-course-item-content p {
        font-size: 0.9em;
      }
    }
    .profile-course-item-header {
      h1 {
        color: $color-primary;
        font-size: 1.6em;
        line-height: 1.4em;
        margin: 0 0 10px;
      }
    }
    .profile-course-item-buttons {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr;

      @media screen and (max-width:576px) {
        gap: 0;
        grid-template-columns: 1fr;
      }
    }
    .profile-course-item-progress {
      color: $color-secondary;
      font-weight: bold;
      font-size: 1.2em;

      small {
        float:right;
        font-size: 1.4em;
        font-weight:normal;
      }
    }
    &.profile-course-done {
      .profile-course-item-progress {
        color: $color-success;
      }
    }
    .profile-course-item-percentage-wrapper {
      display: block;
      width: 100%;
      height: 10px;
      border-radius: 10px;
      margin: 10px 0;
      background: lighten($color-success,35);
      overflow: hidden;
      .profile-course-item-percentage {
        display: block;
        background: $color-success;
        border-radius: 10px;
        height: 100%;
      }
    }
  }
}
.profile-page-header {
  margin-top: -20px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width:567px) {
    flex-direction: column;
    text-align: center;
  }

  h1 {
    font-size: 2em;
    color: $color-primary;
    margin-top: 10px;
  }
  .profile-edit-buttons {
    text-align: right;
    @media screen and (max-width:567px) {
      margin: 30px 0 0 0;
      zoom: 0.85;
      text-align: center;
    }
  }
}
</style>
