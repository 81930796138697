export default {
  "for_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account"])},
  "access_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Classroom"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "login_with_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Facebook"])},
  "not_a_member_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a member? Sign Up"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "more_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Information"])},
  "back_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Login"])},
  "choose_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Organization"])},
  "group_code_if_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Code (optional)"])},
  "group_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Code"])},
  "login_to_the_system_means_that_you_agree_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to the system means that you agree to"])},
  "register_means_that_you_agree_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register means that you agree to"])},
  "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Privacy Policy"])},
  "accept_and_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm and Register"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullname"])},
  "set_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Password"])},
  "set_password_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Password (again)"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "register_to_access_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register to access classroom"])},
  "enter_your_information_to_reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your information to reset password"])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "home_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A great combination of knowledge, skills and experience"])},
  "home_intro_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To help you reach your full potential<br/>to stop TB and AIDS among PWID<br/>for their better lives."])},
  "knowledge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge"])},
  "knowledge_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about<br/>drugs and addiction."])},
  "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
  "skill_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build useful skills to work<br>with PWID more effectively."])},
  "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
  "experience_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listen to inspiring stories<br/>from our people."])},
  "many_contents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore Lessons!"])},
  "many_contents_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn from experts and staff with years of experience."])},
  "many_contents_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listen to inspiring stories from our people."])},
  "many_contents_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various type of content and activities."])},
  "many_contents_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoy fun minigames during lessons."])},
  "easy_fun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy & Fun"])},
  "easy_fun_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn anytime, anywhere at your own pace. Enjoy collecting badges during the lessons."])},
  "result_watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring & Evaluation"])},
  "result_watch_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lessons that can be reviewed anytime"])},
  "result_watch_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-tests to assess learners instantly"])},
  "result_watch_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online staff to support every learner "])},
  "all_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peer to Peer Harm Reduction Development Program (PHD)"])},
  "seven_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 courses"])},
  "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
  "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lessons"])},
  "knowledges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topics"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
  "inspiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspiring Stories"])},
  "from_frient_to_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from Our People"])},
  "sponsors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
  "peer_to_peer_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peer to Peer Harm Reduction Development Program (PHD)"])},
  "peer_to_peer_project_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported by Raks Thai Foundation and Partners to improve standards of health and living"])},
  "raksthai_foundation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raks Thai Foundation"])},
  "contact_rakthai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Raks Thai Foundation"])},
  "raksthai_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["185 Soi Pradiphat 6, Phaya Thai, Phaya Thai, Bangkok 10400, Thailand"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "member_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Profile"])},
  "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Province"])},
  "choose_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Gender"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
  "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
  "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
  "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
  "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
  "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
  "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
  "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
  "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
  "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
  "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
  "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
  "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
  "other_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Organization"])},
  "peer_to_peer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peer to Peer Harm Reduction Development Program (PHD)"])}
}