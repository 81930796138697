<template>
  <div class="loading-container">
    <div class="loading-spinner" :class="'loading-'+size"></div>
  </div>
</template>

<script>
export default {
  props: ['size']
}
</script>

<style lang="scss" scoped>
.loading-container {
  display:block;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.8);
  border-radius: $border-radius;
  z-index: 999;
}
.loading-spinner {
  width: 60px; 
  height: 60px; 
  border-width: 5px; 
  border-style: solid; 
  border-color: #FA5E1D #FA5E1D #FFAD2B; 
  border-radius: 100px;
  box-sizing: border-box;
  border-radius: 100%;
  animation: circle-spin 1s infinite linear;

  &.loading-mini {
    width: 20px;
    height: 20px;
    border-width: 2px;
  }
}
@keyframes circle-spin {
  100% {
    transform: rotate(1turn);
  }
}
</style>