import axios from 'axios'
import authHeader from '@/helpers/auth_header'
import unauthorized from '@/helpers/unauthorized'
import badge_event from '@/helpers/badge_event'

const REST_ENDPOINT = process.env.VUE_APP_API_SERVER

export const pageService = {
  home,
  profile
};

function home () {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}home`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      badge_event(res.data)
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}


function profile () {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}profile`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      badge_event(res.data)
      let result = res.data;
      resolve(result)
    }).catch(function (error) {
      if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}