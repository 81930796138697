import { createStore } from 'vuex'
import { user } from './modules/user';
import { event } from './modules/event';
import { message } from './modules/message';

export default createStore({
  state: {

  },
  mutations: {

  },
  actions: {
  },
  modules: {
    user,
    event,
    message
  }
})
