<template>
<div class="game-wrapper">
  <div class="game-content">
    <div :id="containerId"></div>
  </div>
  <LoadingSpinner v-if="!downloaded"/>
</div>
<div id="fullscreen"></div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
export default {
  name: 'LessonGame',
  props: ['content'],
  emits: ['done'],
  components: {
    LoadingSpinner
  },
  data() {
    return {
      downloaded: false,
      gameInstance: null,
      containerId: 'game-container'
    }
  },
  async mounted() {
    let game_name;
    switch(this.content.type) {
      case 'game1': game_name = 'game1';break;
      case 'game2': game_name = 'game2';break;
      case 'game3': game_name = 'game3';break;
      case 'game4': game_name = 'game4';break;
      case 'game5': game_name = 'game5';break;
      case 'game6': game_name = 'game6';break;
      case 'game7': game_name = 'game7';break;
    }
    if(game_name) {
      const game = await import('@/games/'+game_name+'/launch')
      this.downloaded = true
      this.$nextTick(() => {
        this.gameInstance = game.launch(this.containerId)
        this.gameInstance.events.addListener('done', (action) => {
          this.$emit('done',action)
        });

      })
    }

  },
  unmounted () {
    if(this.gameInstance)this.gameInstance.destroy(false)
  },
  methods: {
  }
}
</script>

<style lang="scss">
.game-wrapper {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  .game-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #ddd;  
  }
}

</style>