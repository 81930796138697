<template>
  <div class="main-content loading-area">
    <template v-if="errors">
      <h1 v-for="(e,i) in errors" :key="i">{{e}}</h1>
      <br/>
      <router-link :to="'/course/'+$route.params.id"><strong>ไปหน้ารายการบทเรียน</strong></router-link>
    </template>
    <template v-if="content">
      <div class="chapter-header">
        <div class="chapter-nav">
          <div class="chapter-nav-item previous-chapter"></div>
          <div class="chapter-nav-item" v-for="chapter in content.chapters" :key="chapter.order" :class="chapterNavClass(chapter.id)" @click="navChapter(chapter)">
            {{chapter.order}} <span v-if="content.chapter_infos.id === chapter.id">{{content.chapter_infos.title}}</span>
          </div>
          <div class="chapter-nav-item next-chapter"></div>
        </div>
        <div class="course-syllabus">
          <router-link :to="'/course/'+content.course.id">แสดงรายการบทเรียน <img src="~@/assets/images/icon-list.svg"/></router-link>
        </div>
      </div>

      <div class="chapter-contents">
        <div class="chapter-current-content">
          <content-display :content="content.content"/>
        </div>
        <div class="chapter-content-list">
          <div class="chapter-content-list-item" v-for="c in content.contents" :key="c.id">
            <div :class="contentClass(c)" @click="$router.push('/course/'+content.course.id+'/'+c.id)"><span>{{c.title}}</span></div>
          </div>
        </div>
      </div>

      <div class="chapter-detail">
        <div class="chapter-main-content">
          <div class="chapter-main-content-header">
            <div class="chapter-teachers">
              <div class="chapter-teacher" v-for="item in content.teachers" :key="item.id">
                <div class="chapter-teacher-image">
                  <img :src="item.profile_pic" v-if="item.profile_pic" />
                </div>
                <div class="chapter-teacher-info">
                  <small>พี่เลี้ยง</small>
                  <strong>{{item.display_name}}</strong>
                  <button @click="sendMessage(item)">ส่งข้อความ <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="3" y="5" width="18" height="14" rx="2" /><polyline points="3 7 12 13 21 7" /></svg></button>
                </div>
              </div>
            </div><!-- chapter-teacher -->
            <div class="chapter-downloads">
              <div class="chapter-download" v-for="item in content.attachments" :key="item.id">
                <a @click="downloadAttachment(item)" :class="'file-type-icon-'+item.type">
                  <small>ดาวน์โหลด</small>
                  <strong>{{item.title}}</strong>
                </a>
              </div>
            </div><!-- chapter-downloads -->
          </div><!-- chapter-content-header -->
          <div class="chapter-content-body">
            <div class="chapter-content-description">
              <span class="chapter-content-course-name">{{content.course.title}}</span>
              <span class="chapter-content-title">{{content.chapter_infos.title}}</span>
              <span class="chapter-content-content">{{content.chapter_infos.content}}</span>
              <span class="chapter-content-detail">{{content.chapter_infos.detail}}</span>
            </div>
            <div class="chapter-comments">
              <div class="chapter-comments-header">
                ความคิดเห็นเกี่ยวกับบทนี้
              </div>
              <div class="chapter-comment" v-for="item in content.comments" :key="item.id">
                <div class="chapter-comment-image">
                  <img :src="item.user.profile_pic" v-if="item.user.profile_pic" />
                </div>
                <div class="chapter-comment-content">
                  <div class="chapter-comment-author">{{item.user.display_name}}</div>
                  <div class="chapter-comment-message">{{item.content}}</div>
                </div>
              </div><!-- .chapter-comment -->
              <div class="chapter-comment-form">
                <form @submit.prevent="commentSubmit">
                  <div class="chapter-comment-form-image">
                    <img :src="profile_pic" v-if="profile_pic" />
                  </div>
                  <div class="chapter-comment-form-input">
                    <textarea v-model="inputComment"></textarea>
                    <div class="text-right"><button class="button btn-inline btn-primary">ส่งความคิดเห็น</button></div>
                  </div>
                </form>
              </div><!-- .chapter-comment-form -->
            </div><!-- .chapter-comments -->
          </div>
        </div>
        <div class="chapter-side-content">
          <form class="chapter-note-form">
            <label for="chapter-note">จดบันทึก...</label>
            <div class="chapter-note-loading"><LoadingSpinner size="mini" v-if="savingNote"/></div>
            <textarea id="chapter-note" name="chapter-note" class="chapter-note" v-model="chapterNote"></textarea>
          </form>
        </div>
      </div>
    </template>
    <LoadingSpinner v-if="loading"/>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import ContentDisplay from '@/components/ContentDisplay.vue'
import { courseService } from '@/services/course';
import _ from 'lodash';

export default ({
  components: {
    LoadingSpinner,
    ContentDisplay
  },
  data() {
    return { 
      errors: null,
      content: null,
      course: null,
      loading: true,
      inputComment:"",
      chapterNote: "",
      savingNote: false,
      sendPartner: null
    }
  },
  created() {
    courseService.content(this.$route.params.id,this.$route.params.content_id).then((res)=>{
      this.loading = false
      if(res.success===false) {
        this.errors = res.errors
      } else {
        res.content.infos.course_id = this.$route.params.id
        this.content = res
        this.chapterNote = this.content.chapter_infos.note
      }
    },(res)=>{
      this.errors = res
      this.loading = false
    })
  },
  methods: {
    showEnroll(item) {
      this.show_enroll = item;
    },
    chapterNavClass(id) {
      if(this.content.chapter_infos.id===id) {
        return 'current-chapter'
      } else if(this.content.chapter_infos.id < id) {
        return 'locked-chapter'
      } else {
        return ''
      }
    },
    contentClass(content) {
      let result = '';
      if(this.content.content.infos.id === content.id) {
        result += ' content-current'
      } else if(content.is_completed) {
        result += ' content-completed'
      } else if(content.is_locked) {
        result += ' content-locked'
      }

      switch (content.type_handle) {
        case 'video': result += ' content-type-video'; break;
        case 'game': result += ' content-type-game'; break;
        case 'quiz': result += ' content-type-quiz'; break;
        case 'article': result += ' content-type-article'; break;
        case 'assignment': result += ' content-type-assignment'; break;
        case 'certification': result += ' content-type-cert'; break;
      }

      return result
    },
    commentSubmit() {
      let data = {
        "content": this.inputComment,
      }
      this.loading = true
      courseService.comment(this.$route.params.id,this.content.chapter_infos.id,data)
        .then((res)=>{
          if(res.comments)this.content.comments = res.comments
          this.inputComment = ""
          this.loading = false
        },(res)=>{
          this.loading = false
        })
    },
    debouncedSaveNote: _.debounce(function () { if(this.content)this.saveNote(); }, 1000),
    saveNote() {
      if(!this.savingNote) {
        this.savingNote = true
        let data = {}
        data.content = (this.chapterNote)?this.chapterNote:""
        courseService.save_note(this.$route.params.id,this.content.chapter_infos.id,data).then((res)=>{
          this.savingNote = false
        },(res)=>{
          this.savingNote = false
        })
      }
    },
    sendMessage(user) {
      this.$store.dispatch('message/sendMessage',user.id)
    },
    navChapter(chapter) {
      this.$router.push('/course/'+this.$route.params.id+'/'+chapter.content_id)
    },
    downloadAttachment(item) {
      courseService.downloadAttachment(this.$route.params.id,this.content.chapter_infos.id,item.id).then((res)=>{
        //this.savingNote = false
      },(res)=>{
        //this.savingNote = false
      })
    }
  },
  computed: {
    isEnroll() {
      return this.show_enroll!==null
    },
    profile_pic() {
      if( this.$store.state.user.profile ) {
        return this.$store.state.user.profile.profile_pic;
      } else {
        return null;
      }
    }
  },
  beforeUnmount () {
    //this.savingNote = false
    //this.saveNote()
  },
  watch: {
    chapterNote: function (newnote, oldnote) {
      if(newnote!=undefined)this.debouncedSaveNote()
    }
  },
})
</script>

<style scoped lang="scss">
$nav-size: 40px;
$sidebar-size: 260px;
$sidebar-margin: 20px;
.chapter-header {
  display: flex;

  .course-syllabus {
    width: $sidebar-size;
    border-radius: $nav-size;
    height: $nav-size;
    box-shadow: $shadow-small;
    background: #fff;
    text-align: center;
    margin: 0 0 0 $sidebar-margin;
    line-height: 40px;
    font-size: 0.9em;
    color: lighten($color-primary,10);
    font-weight: 500;
    font-family: $font-family-heading;

    a {
      color: inherit;
    }

    img {
      display: inline-block;
      margin: 0 0 0 5px;
      height: 14px;
      padding: 0 0 2px;
      vertical-align: middle;
    }



    @media only screen and (max-width: 768px) {
      flex: 1;
      margin: 0;
    }
  }
}
.chapter-nav {
  background: #fff;
  display: flex;
  flex-direction: row;
  border-radius: $nav-size;
  height: $nav-size;
  box-shadow: $shadow-small;
  padding: 0;
  width: 100%;
  flex: 1;
  font-family: $font-family-heading;

  @media only screen and (max-width: 768px) {
    display:none;
  }

  .chapter-nav-item {
    cursor: pointer;
    min-width: $nav-size - 10px;
    height: $nav-size - 10px;
    border-radius: $nav-size - 10px;
    line-height: $nav-size - 13px;
    font-size: 0.9em;
    font-family: $font-family-heading;

    display: block;
    margin: 5px 5px;
    text-align: center;
    border: 2px solid lighten($color-primary, 10);
    color: lighten($color-primary, 10);
    font-weight: bold;

  }
  .current-chapter {
    flex: 1;
    color: #fff;
    background: lighten($color-primary,10);//lighten($color-trinary, 20);
    border-color: lighten($color-primary,10);//lighten($color-trinary, 20);
    text-align: left;
    padding: 0 10px;
    span {
      padding: 0 0 0 5px;
      font-weight: 500;
      display: inline-block;
    }
  }
  .locked-chapter {

  }
  .previous-chapter,
  .next-chapter {
    border: none;
    font-family: nav-icons;
    font-size: 12px;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: $nav-size - 10px;;
    color: lighten($color-primary, 10);
  }

  .previous-chapter:after {
    content: "prev";
  }
  .next-chapter:after {
    content: "next";
  }
}
.chapter-contents {
  margin: 20px 0 0 0;
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  .chapter-current-content {
    flex: 1;
    border-radius: $border-radius;
    overflow:hidden;
    box-shadow: $shadow-big;
  }
  .chapter-content-list {
    width: $sidebar-size;
    margin: 0 0 0 $sidebar-margin;
    border-radius: $border-radius;
    background: #fff;
    box-shadow: $shadow-big;
    padding: 12px 0;
    overflow: hidden;
    font-family: $font-family-heading;
    @media only screen and (max-width: 567px) {
      width: 100%;
      margin: 10px 0;
    }
  }
  .chapter-content-list-item {
    line-height: 2.2em;
    height: 2.2em;
    overflow: hidden;
    
    &>div {
      height: 100%;
      width: 100%;
      position: relative;
      padding: 0 35px;
      border-left: 5px solid transparent;
      cursor: pointer;
      transition: background 0.3s;

      span {
        display: block;
        height: 100%;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.9em;
        font-weight: 500;
        color: $color-gray;
      }
      &:hover {
        background: #f1f1f1;
      }
    }

    &>div:before {
      content: "";
      display: block;
      position: absolute;
      height: 16px;
      width: 18px;
      left: 10px;
      top: 50%;
      margin-top: -9px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0.8;
    }

    .content-type-video:before {
      background-image: url(~@/assets/images/icon-play-outline.svg);
    }
    .content-type-game:before {
      background-image: url(~@/assets/images/icon-game-outline.svg);
    }
    .content-type-quiz:before {
      background-image: url(~@/assets/images/icon-quiz-outline.svg);
    }
    .content-type-article:before {
      background-image: url(~@/assets/images/icon-article-outline.svg);
    }
    .content-type-assignment:before {
      background-image: url(~@/assets/images/icon-assignment-outline.svg);
    }
    .content-type-cert:before {
      background-image: url(~@/assets/images/icon-cert-outline.svg);
    }


    &>div:after {
      content: "";
      display: block;
      position: absolute;
      height: 16px;
      width: 18px;
      right: 10px;
      top: 50%;
      margin-top: -9px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0.8;
      box-sizing: border-box;
    }

    .content-current {
      border-left: 5px solid $color-secondary;
    }
    .content-current:after {
      background-size: 9px 9px;
      background-image: url(~@/assets/images/icon-dot.svg);
    }
    .content-completed:after {
      background-image: url(~@/assets/images/icon-check-circle.svg);
    }
    .content-locked:after {
      background-image: url(~@/assets/images/icon-lock.svg);
    }

    .content-locked,
    .content-current {
      background: none !important;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}
.chapter-detail {
  margin: 20px 0 0 0;
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
  .chapter-main-content {
    flex: 1;
    border-radius: $border-radius;
    overflow:hidden;
    box-shadow: $shadow-big;
    background: #fff;
    padding: 30px 30px 20px;
    @media only screen and (max-width: 768px) {
      padding: 10px;
    }
  }
  .chapter-side-content {
    width: $sidebar-size;
    margin: 0 0 0 $sidebar-margin;

    @media only screen and (max-width: 768px) {
      margin: -10px 0 20px 0;
      width: 100%;
    }
  }
}
.chapter-main-content-header {
  display: flex;
  flex-direction: column;
  justify-items: space-between;
  align-items: flex-start;
  padding: 0 0 10px;
  border-bottom: 1px solid #eee;
  font-family: $font-family-heading;

  .chapter-teachers {
    display: flex;
    margin: 0 0 5px;


    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    .chapter-teacher {
      display: flex;
      align-items: center;
      margin: 0 20px 5px 0;

      .chapter-teacher-image {
        width: 55px;
        height: 55px;
        border-radius: 55px;
        margin: 0 10px 0 0;
        overflow:hidden;
        background: #f1f1f1;
      }

      .chapter-teacher-info {
        small {
          display: block;
          color: $color-gray;
          opacity: 0.7;
          line-height: 1em;
        }
        strong {
          display: block;
          color: $color-gray;
          line-height: 1.2em;
          font-weight: normal;
          font-weight: 500;
        }
        button {
          width: auto;
          display: inline-block;
          padding: 4px 8px 4px 10px;
          margin: 0;
          font-size: 0.7em;
          line-height: 1.3em;
          svg {
            margin: -1px 0 0 0;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .chapter-downloads {
    flex: 1;
    text-align: right;

    .chapter-download {
      width: 160px;
      margin: 0 10px 10px 0;
      display: inline-block;
    }

    a {
      width: 100%;
      display: inline-block;
      padding: 6px 30px 6px 42px;
      background: #fff;
      box-shadow: $shadow-small;
      border-radius: 200px;
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: 13px 6px;
      position: relative;

      small {
        display: block;
        font-size: 0.6em;
        line-height: 1.3em;
        text-align: left;
        color: $color-gray;
        opacity: 0.8;
      }
      strong {
        display: block;
        font-size: 0.8em;
        line-height: 1.3em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: normal;
        text-align: left;
        letter-spacing: -0.05em;
        color: $color-gray;
      }

      &:after {
        content: "";
        display: block;
        height: 16px;
        width:16px;
        background: url(~@/assets/images/arrow-down.png) no-repeat center center;
        position: absolute;
        background-size: contain;
        top: 12px;
        right: 10px;
      }
    }

    .file-type-icon {
      &-pdf {
        background-image: url(~@/assets/images/filetype/pdf.png);
      }
      &-image {
        background-image: url(~@/assets/images/filetype/image.png);
      }
    }
  }
}
.chapter-content-description {
  padding: 20px 0;
  border-bottom: 1px solid #eee;

  .chapter-content-course-name {
    display: inline-block;
    background: $color-secondary;
    color: #fff;
    padding: 3px 10px 1px;
    border-radius: 30px;
    font-size: 0.9em;
    line-height: 1.3em;
    font-family: $font-family-heading;
  }
  .chapter-content-title {
    display: block;
    color: $color-primary;
    font-weight: bold;
    font-size: 1.2em;
    line-height: 1.4em;
    padding: 5px 0;
    font-family: $font-family-heading;
  }
  .chapter-content-content {
    display: block;
  }
  .chapter-content-detail {

  }
}
.chapter-comments {
  color: $color-gray;
  .chapter-comments-header {
    font-size: 1.2em;
    line-height: 1.4em;
    padding: 20px 0 0;
    font-weight: bold;
    font-family: $font-family-heading;
  }
  .chapter-comment {
    display: flex;
    border-bottom: 1px dashed #eee;
    padding: 10px 0;
  }
  .chapter-comment-image {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    overflow:hidden;
    margin: 0 20px 0 0;
    background: #f1f1f1;

    img {
      border-radius: 50px;
      line-height: 0;
    }
  }
  .chapter-comment-content {
    flex: 1;
    .chapter-comment-author {
      font-weight: bold;
      padding: 0 0 10px;
      font-family: $font-family-heading;
    }
    .chapter-comment-message {

    }
  }
  .chapter-comment-form {
    padding: 20px 0 0 0;
    form {
      display: flex;
      margin: 0;
      padding: 0;
    }
    .chapter-comment-form-image {
      margin: 0 20px 0 0;

      width: 50px;
      height: 50px;
      border-radius: 50px;
      overflow:hidden;

      background: #f1f1f1;
    }
    .chapter-comment-form-input {
      flex: 1;
      textarea {
        display: block;
        width: 100%;
        margin: 0 0 10px;
      }
    }
  }
}
.chapter-note-form {
  background: rgba(87,87,87,0.05);
  border-radius: $border-radius-small;
  padding: 20px;
  position: relative;

  .chapter-note {
    margin: 10px 0 0 0;
    display: block;
    border: none;
    resize: none;
    height: 11em;
    line-height: 30px;
    background:  url(~@/assets/images/note-line.png) top left;
    padding: 0;
    color: $color-gray;
  }
  .chapter-note-loading {
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 10px;
    right: 10px;
  }
}
</style>
