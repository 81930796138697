<template>
  <Modal @close="hide" size="small">
    <hgroup>
      <span>ลงทะเบียนเรียน</span>
      <h1>{{course.title}}</h1>
    </hgroup>
    <p class="text-center"><small>{{course.type}}</small></p>
    <form @submit.prevent="doEnroll">
      <button type="submit" class="btn-primary btn-block">เริ่มเรียนเลย</button>
    </form>
    <LoadingSpinner v-if="loading"/>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { courseService } from '@/services/course';

export default {
  components: {
    Modal,
    LoadingSpinner
  },
  data () {
    return {
      loading: false,
      code: ''
    }
  },
  emits: ['cancel'],
  props: ['course'],
  methods: {
    hide() {
      this.$emit('cancel');
    },
    doEnroll() {
      console.log(courseService)
      let data = {
        "code": this.code
      }
      this.loading = true
      courseService.enroll(this.course.id,data)
        .then((res)=>{
          this.loading = false
          if(res.success) {
            this.$router.push('/course/'+this.course.id+'/'+this.course.next_content_id)
          }
        },(res)=>{
          console.log(res)
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>