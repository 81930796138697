<template>
  <transition name="slide-fade">
  <div id="messages-wrapper" v-if="isActive"  @click.self="hide">
    <div class="messages-container loading-area">
      <div class="messages-box-header">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail" width="22" height="22" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="3" y="5" width="18" height="14" rx="2" /><polyline points="3 7 12 13 21 7" /></svg> กล่องข้อความ
        <span @click="hide">&times;</span>
      </div>
      <div class="messages-box-body" :class="{chat_active:chat_id}">
        <div class="messages-list loading-area">
          <div v-if="list">
            <div class="messages-list-item" v-for="item in list" :key="item.id" @click="loadChat(item.id)">
              <template v-for="chat_user in item.users" :key="chat_user.id">
                <div class="messages-list-item-image">
                  <img :src="chat_user.profile_pic" v-if="chat_user.profile_pic"/>
                </div>
                <div class="messages-list-item-name">{{chat_user.display_name}} {{item.id}}</div>
              </template>
              <div class="messages-list-item-count" v-if="item.new_messages_count>0">{{item.new_messages_count}}</div>
            </div>
            <LoadingSpinner v-if="listLoading"/>
          </div>
        </div>
        <div class="messages-content loading-area">
          <template v-if="chat">
            <div class="message-content-header">
              <span class="message-content-back" @click="clearChat"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-left" width="40" height="40" viewBox="0 0 24 24" stroke-width="2" stroke="#ddd" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline points="15 6 9 12 15 18" /></svg></span>
              
              <div class="message-content-header-user" v-for="user in notme(chat.users)" :key="user.id">
                <div class="message-content-header-user-image">
                  <img :src="user.rofile_pic" v-if="user.rofile_pic"/>
                </div>
                <div class="message-content-header-user-detail">
                  <div class="message-content-header-user-name">{{user.display_name}}</div>
                  <div class="message-content-header-user-rank">{{user.role}}</div>
                </div>
              </div>
            </div>
            <div class="message-content-messages">
              <div class="message-content-messages-display" ref="messageDisplay">
                <div class="message-content-message"  v-for="message in chat.messages" :key="message.id" :class="{'message-content-message-partner':message.user_id!=me.user_id}">
                  <div class="message-content-message-header">
                    <strong>{{findChatUserName(message.user_id)}}</strong>
                    <time>{{showDate(message.created_at)}} <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clock" width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="9" /><polyline points="12 7 12 12 15 15" /></svg></time>
                  </div>
                  <div class="message-content-message-body">
                    {{message.content}}
                  </div>
                </div>
              </div>
            </div>
            <div class="message-content-form">
              <form @submit.prevent="replySubmit">
                <div class="chapter-comment-form-input">
                  <textarea v-model="inputComment" placeholder="พิมพ์ข้อความ"></textarea>
                  <div class="text-right"><button class="button btn-inline btn-primary">ส่งข้อความ</button></div>
                </div>
              </form>
            </div>
          </template>
          <div class="messages-no-chat" v-else>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-message-circle" width="200" height="200" viewBox="0 0 24 24" stroke-width="1" stroke="#ddd" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/> <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" /><line x1="12" y1="12" x2="12" y2="12.01" /><line x1="8" y1="12" x2="8" y2="12.01" /><line x1="16" y1="12" x2="16" y2="12.01" /></svg>
            </div>
          </div>
          <LoadingSpinner v-if="chatLoading"/>
        </div>
      </div>
      <LoadingSpinner v-if="loading"/>
    </div>
  </div>
  </transition>
</template>

<script>
import { messageService } from '@/services/message';
import LoadingSpinner from '@/components/LoadingSpinner.vue'
export default {
  components: {
    LoadingSpinner
  },
  data() {
    return { 
      select_id: null,
      inputComment: "",
      loading: false,
      hidden: true,
      listLoading: false,
      chatLoading: false,
      list: null,
      chat: null,
      chat_id: null
    }
  },
  name: "Messages",
  methods: {
    getMessageId(receiver_id) {
      this.hidden = false
      this.loading = true
      let data = {receiver_id:receiver_id}
      messageService.chat(data).then((res)=>{
        this.loading = false
        this.show()
        this.loadChat(res.chat_id)
      },(res)=>{
        this.loading = false
      })
    },
    show() {
      this.listLoading = true
      this.hidden = false
      messageService.list().then((res)=>{
        res.chats = res.chats.map((item)=>{
          if(this.chat_id===item.id)item.new_messages_count = 0
          return item
        })
        this.listLoading = false
        this.list = res.chats
      },(res)=>{
        this.listLoading = false
      })
    },
    clearChat() {
      this.chat_id = null
      this.chat = null
    },
    loadChat(id) {
      this.hidden = false
      this.chatLoading = true
      messageService.view(id).then((res)=>{
        this.inputComment = ""
        this.chat_id = id
        this.chat = res
        this.chatLoading = false
        setTimeout(()=>{
          var messageDisplay = this.$refs.messageDisplay;
          messageDisplay.scrollTop = messageDisplay.scrollHeight;
        },300)
      },(res)=>{
        this.chatLoading = false
      })
    },
    replySubmit() {
      if(!this.inputComment)return;

      this.chatLoading = true
      messageService.post(this.chat_id,{content:this.inputComment}).then((res)=>{
        this.chatLoading = false
        this.inputComment = ""
        this.loadChat(this.chat_id)
      },(res)=>{
        this.chatLoading = false
      })
    },
    select(id) {
      this.$store.dispatch('message/setActiveChat',id)
    },
    hide() {
      this.hidden = true
    },
    findChatUserName(id) {
      let result = ""
      if(this.chat && this.chat.users) {
        this.chat.users.map((user)=>{
          if(user.id===id)result=user.display_name
        });
      }
      return result;
    },
    showDate(date) {
      const d = new Date(date)
      let options = { dateStyle: 'short', timeStyle: 'short' };
      return d.toLocaleString('th-TH',options);
    },
    notme(users) {
      let results = []
      users.map((user)=>{
        if(user.id != this.me.user_id)results.push(user)
      })
      return results
    }
  },
  computed: {
    isActive() {
      return !this.hidden
    }
  },
  created() {
    this.unsub = this.$store.subscribe((mutation, state) => {
      if(mutation.type==='message/receiver_id') {
        if( mutation.payload ) {
          this.getMessageId(mutation.payload)
        }
      } else if(mutation.type==='message/active_chat') {
        console.log('activechat',mutation.payload)
        if( mutation.payload ) {
          this.loadChat(mutation.payload)
        }
      } else if(mutation.type==='user/profile') {
        this.me = this.$store.state.user.profile
      } else if(mutation.type==='message/show') {
        this.show()
      }
    })
  },
   watch: {
    chat_id: function (val) {
      if(val && this.list) {
        this.list = this.list.map((item)=>{
          if(val===item.id)item.new_messages_count = 0
          return item
        })
      }
    },
  },
  mounted() {
    this.loading = false
    this.me = this.$store.state.user.profile
  },
  unmounted() {
    this.unsub()
  }
}
</script>

<style lang="scss">
#messages-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.2);
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  padding: 100px;

  @media only screen and (max-width: 1300px) {
    padding: 50px
  }
  @media only screen and (max-width: 1024px) {
    padding: 40px
  }
  @media only screen and (max-width: 768px) {
    padding: 20px;
  }

  .messages-container {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 20px; 
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .messages-box-header {
    border-radius: 20px 20px 0 0;
    background: $color-primary;
    color: #fff;
    padding: 10px 20px;
    font-size: 1;
    font-weight: bold;
    position: relative;
    line-height: 30px;
    font-family: $font-family-heading;


    span {
      font-size: 80px;
      font-weight: 200;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      line-height: 45px;
      width: 50px;
      text-align: center;
      cursor: pointer;
    }
    svg {
      vertical-align: middle;
    }
  }
  .messages-box-body {
    display: grid;
    grid-template-columns: 250px 1fr;
    overflow: hidden;
    flex: 1;
    @media only screen and (max-width: 768px) {
      grid-template-columns: 200px 1fr;
    }

    @media only screen and (max-width: 480px) {
      grid-template-columns: 50% 50%;
      position: relative;
      left: 0%;
      width: 200%;
      transition: left 0.5s ease-in-out;
    }

    &.chat_active {
      @media only screen and (max-width: 480px) {
        grid-template-columns: 50% 50%;
        position: relative;
        left: -100%;
        width: 200%;
        transition: left 0.3s ease-out;
      }
    }
    
    .messages-list {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      background: #fff;
      padding:  0;
      border-right: 1px solid #ddd;
      background: #f1f1f1;

      font-family: $font-family-heading;
      .messages-list-item {
        border-bottom: 1px solid #ddd;
        padding: 6px 10px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
      }
      .messages-list-item-image {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background: #ddd;
        overflow: hidden;
      }
      .messages-list-item-name {
        flex: 1;
        padding: 0 30px 0 10px;
        font-size: 13px;
        font-weight: bold;
        color: #666;
        width: 100%;
        line-height: 1.5em;
        height: 1.5em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .messages-list-item-count {
        position: absolute;
        top: 50%;
        right: 10px;
        height: 20px;
        line-height: 20px;
        min-width: 20px;
        padding: 0 5px;
        background: $color-primary;
        margin-top: -10px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        border-radius: 10px;
      }
    }
    .messages-content {
      padding: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow:hidden;
      .message-content-header {
        padding: 6px 20px 6px 60px;
        border-bottom: 1px solid #f1f1f1;
        position: relative;
        display: flex;

        .message-content-back {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          padding: 6px 0 0 0;
          width: 55px;
          text-align: center;
        }
        
        .message-content-header-user {
          display: flex;
          align-items: center;
          font-family: $font-family-heading;

          .message-content-header-user-image {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            background: #ddd;
            overflow: hidden;
          }
          .message-content-header-user-detail {
            padding: 0 20px 0 10px;
            line-height: 16px;
            color: #444;

            .message-content-header-user-name {
              font-size: 13px;
              font-weight: bold;
            }
            .message-content-header-user-rank {
              font-size: 11px;
              font-weight: normal;
            }
          }
        }
      }
      .messages-no-chat {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 90%;
        color: #ddd;
        text-align: center;
        font-size: 20px;

        font-family: $font-family-heading;
      }
      .message-content-messages {
        flex: 1;
        position: relative;
        .message-content-messages-display {
          position: absolute;
          height: 100%;
          top: 0;
          overflow-x: hidden;
          overflow-y: auto;
          padding: 10px 20px;
          width: 100%;
        }
        .message-content-message {
          border-radius: 10px;
          margin: 0 0 10px;
          padding: 10px;
          background: #f1f1f1;
          border: 1px solid #f1f1f1;
        }
        .message-content-message-partner {
          border: 1px solid #ddd;
          background: none;
        }
        .message-content-message-header {
          display: flex;
          justify-content: space-between;
          margin: 0 0 5px;
          font-size: 0.9em;
          color: #999;
          font-weight: normal;

          font-family: $font-family-heading;
          time {
            font-size: 13px;
            line-height: 16px;
            svg {
              vertical-align: top;
            }
          }
        }
        .message-content-message-body {
          white-space: pre-wrap;
        }
      }
      .message-content-form {
        padding: 10px 20px;
        background: #f5f5f5;
        box-shadow: -1px 0 8px rgba(0,0,0,0.3);
        textarea {
          margin-bottom: 10px;
          height: 5em;
        }
      }
    }
  }

}
</style>
