<template>
  <footer id="main-footer">
    <div class="container">
      <div class="main-footer-content">
        <div>
          <h3>{{$t('peer_to_peer_project')}}</h3>
          <p>{{$t('peer_to_peer_project_text')}}</p>
          <div class="main-footer-line">
            <div>
              <a href="https://lin.ee/fDOvH4v" target="_blank"><img src="https://qr-official.line.me/gs/M_741qidpq_BW.png"/></a>
            </div>
            <div>
              <strong>สอบถามการใช้งาน</strong><br/>
              <a href="https://lin.ee/fDOvH4v" target="_blank">LineID: @741qidpq</a>
            </div>
          </div>
        </div>
        <div>
          <h3>{{$t('contact_rakthai')}}</h3>
          <p>{{$t('raksthai_address')}}</p>
          <p>
          {{$t('phone')}}: 02-265-6888<br/>
          {{$t('email')}}: info@raksthai.org</p>
          <a href="https://www.instagram.com/raksthai_foundation/" target="_blank"><img src="~@/assets/images/icon-instagram.png" width="22"/></a>&nbsp;
          <a href="https://www.facebook.com/raksthaifoundation" target="_blank"><img src="~@/assets/images/icon-facebook.png" width="22"/></a>&nbsp;
          <a href="https://twitter.com/RaksthaiF" target="_blank"><img src="~@/assets/images/icon-twitter.png" width="22"/></a>
        </div>
      </div>
      <div class="copyright">Copyright 2020 &copy; Raks Thai Foundation</div>
    </div>
    <div class="main-footer-logo">
      <img src="~@/assets/images/raksthai.png"/>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss">
#main-footer {
  margin: 0;
  background: $color-primary-80;
  color: #fff;
  position: relative;
  min-height: 120px;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.2);

  .main-footer-content {
    max-width: 1100px;
    padding: 40px 200px 40px 0;
    line-height: 1.4em;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 40px;

    @media only screen and (max-width: 1200px) {
      width: 100%;
    }

    @media only screen and (max-width: 768px) {
      padding-right: 0;
      padding-top: 140px;
    }
    @media only screen and (max-width: 600px) {
      grid-template-columns: repeat(1,1fr);
      gap: 20px;
    }
    h3 {
      font-size: 1.6em;
      margin: 0 0 20px;
      &:after {
        clear: both;
        content: "";
        display: block;
        margin: 10px 0 0 0;
        height: 3px;
        width: 40px;
        background: $color-secondary;
        border-radius: 3px;
      }
    }
    p {
      margin: 0 0 10px;
    }
    small {
      font-size: 0.7em;
      font-family: $font-family-heading;
    }
  }

  .main-footer-logo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    height: 90px;
    background: $color-primary;
    padding: 15px 20px;
    float: right;
    border-radius: 10px 0 0 10px;
    img {
      height: 100%;
    }

    @media only screen and (max-width: 768px) {
      top: 20px;
      transform: none;
    }
  }
  .copyright {
    font-size: 0.8em;
    font-weight: bold;
    padding: 0 0 10px;
  }
}
.main-footer-line {
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 10px;
  color: #fff;
  a {
    color: #fff;
  }
}
</style>
