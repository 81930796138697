<template>
  <div class="inspirations-container">
    <hgroup>
      <span>เสียงจากเพื่อนสู่เพื่อน</span>
      <h1>เรื่องราวบันดาลใจ</h1>
    </hgroup>
    <div class="inspirations-list">
      <div class="inspirations-item" v-for="item in view_list" :key="item.id" @click="go(item.id)">
        <div class="inspirations-item-image">
          <img :src="item.thumbnail"/>
        </div>
        <div class="inspirations-item-detail">
          <small>{{item.author}}</small>
          <p>{{item.title}}</p>
        </div>
        <div class="inspirations-item-footer">
          <span class="inspirations-item-duration" v-if="item.duration">{{item.duration}} นาที</span>
          <span class="inspirations-item-like">{{item.likes}}</span>
        </div>
      </div>
    </div>
    <div v-if="!view_all" class="text-center">
      <button type="button" class="btn btn-text" @click="view_all=true">&plus; แสดงทั้งหมด</button>
    </div>
    <div v-else class="text-center">
      <button type="button" class="btn btn-text" @click="view_all=false">&minus; แสดงบางส่วน</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['list'],
  data() {
    return { 
      view_all: false,
    }
  },
  methods: {
    go(id) {
      this.$router.push('/inspiration/'+id)
    }
  },
  computed: {
    view_list() {
      let list = []
      if(this.view_all) {
        list = this.list
      } else {
        list = this.list.slice(0,3)
      }
      return list
    }
  }
}
</script>

<style lang="scss" scoped>
.inspirations-container {
  @extend %box;
  position: relative;

  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
}
.inspirations-list {
  margin: 20px 25px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 40px;


  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1,1fr);
    grid-gap: 20px;
    margin: 10px;
  }

  .inspirations-item {
    padding: 0 0 15px;
    position: relative;
  
    cursor: pointer;
  }
  .inspirations-item-image {
    position: relative;
    width: 100%;
    padding: 60% 0 0 0;
    border-radius: $border-radius-small;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  .inspirations-item-detail {
    text-align: left;
    padding: 10px 0;
    font-size: 1em;
    line-height: 1.3em;
    font-weight: bold;
    font-family: $font-family-heading;

    small {
      display: block;
      padding: 0;
      color: $color-gray;
      font-weight: normal;
      font-size: 0.8em;
    }
  }
  .inspirations-item-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 0.8em;
    color: $color-gray;

    font-family: $font-family-heading;

    .inspirations-item-duration {
      float: left;
      padding: 0 0 0 20px;
      background: url(~@/assets/images/icon-play.svg) no-repeat left center;
      line-height: 16px;
      background-size: 14px;
    }
    .inspirations-item-like {
      float: right;
      padding: 0 20px 0 0;
      background: url(~@/assets/images/icon-heart.svg) no-repeat right center;
      line-height: 16px;
      background-size: 14px;
    }
  }
}
</style>