<template>
  <div class="main-content loading-area">
    <template v-if="content">
      <div class="course-summery-wrapper">
        <main class="course-summery-main">
          <div class="course-summery-chapters">
            <div class="course-summery-chapters-header">
              <div class="course-summery-info">
                <small>{{content.course_infos.type}}</small>
                <h1>{{content.course_infos.title}}</h1>
              </div>
              <div class="course-summery-percent"><div class="pie" :class="'p'+content.course_infos.percent_completed.toFixed(0)"></div></div>
            </div>
            <div class="course-summery-chapters-list">
              <div class="course-summery-chapter" v-for="item in content.chapters" :key="item.id">
                <div class="course-summery-chapter-header" @click="toggleContent(item.id)">
                  <div class="pie" :class="'p'+item.percent_completed.toFixed(0)"></div>
                  {{item.title}}
                  <span :class="{'icon-active':activeContent===item.id}"></span>
                </div>
                <div class="course-summery-chapter-contents-wrapper" v-if="activeContent===item.id">
                  <div class="course-summery-chapter-contents">
                    <div class="course-summery-chapter-content" v-for="c in item.contents" :key="c.id"  @click="$router.push('/course/'+content.course_infos.id+'/'+c.id)">
                      <div class="chapter-content-image">
                        <img :src="c.thumbnail" v-if="c.thumbnail" />

                        <div class="chapter-content-type" :class="contentClass(c)">{{c.type}}</div>
                      </div>
                      <div class="chapter-content">
                        <div class="content-done" v-if="c.is_completed"></div>
                        <strong>{{c.title}}</strong>
                        <small>{{c.content}}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <aside class="course-summery-aside">
          <div class="course-summery-action-buttons" v-if="content.course_infos.next_content_id">
            <router-link :to="'/course/'+content.course_infos.id+'/'+content.course_infos.next_content_id" class="button btn-block">กลับไปเรียนต่อ</router-link>
          </div>
          <div class="course-summery-teachers" v-if="content.teachers.length>0">
            <div class="chapter-teacher" v-for="item in content.teachers" :key="item.id">
              <div class="chapter-teacher-image">
                <img :src="item.profile_pic" v-if="item.profile_pic" />
              </div>
              <div class="chapter-teacher-info">
                <small>พี่เลี้ยง</small>
                <strong>{{item.display_name}}</strong>
                <button @click="sendMessage(item)">ส่งข้อความ <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail" width="16" height="16" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="3" y="5" width="18" height="14" rx="2" /><polyline points="3 7 12 13 21 7" /></svg></button>
              </div>
            </div><!-- .chapter-teacher -->
          </div><!-- .course-summery-teachers -->

          <div class="course-summery-homework" v-if="content.assignments.length>0">
            <hgroup>
              <h1>งานของฉัน</h1>
            </hgroup>
            <div class="course-summery-homework-list">
              <div class="course-summery-homework-item"  v-for="item in content.assignments" :key="item.id">
                <strong>{{item.title}}</strong>
                <span>{{item.content}}</span>
                <div class="course-summery-homework-footer"  :class="'homework-due'">
                  <strong>ค้างส่ง</strong>
                  <span v-if="item.due_date">ภายในวันที่: {{item.due_date}}</span>
                </div>
              </div>
            </div><!-- .course-summery-homework-list -->
          </div><!-- .course-summery-homework -->

          <div class="course-summery-scoreboard"  v-if="content.scoreboard.length>0">
            <hgroup>
              <h1>นักเรียนดีเด่น</h1>
            </hgroup>
            <div class="course-summery-scoreboard-list">
              <div class="course-summery-scoreboard-item"  v-for="item in content.scoreboard" :key="item.id">
                <small>{{item.order}}.</small>
                <strong>{{item.username}}</strong>
                <span>{{item.score}}</span>
              </div>
            </div><!-- .course-summery-scoreboard-list -->
          </div><!-- .course-summery-scoreboard -->

        </aside>
      </div><!-- .course-summery-wrapper -->
    </template>
    <LoadingSpinner v-if="loading"/>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { courseService } from '@/services/course';
export default ({
  components: {
    LoadingSpinner
  },
  data() {
    return { 
      content: null,
      loading: true,
      activeContent: null
    }
  },
  created() {
    courseService.course(this.$route.params.id).then((res)=>{
      this.loading = false
      this.content = res
      console.log(res)
    },(res)=>{
      this.loading = false
    })
  },
  methods: {
    toggleContent(id) {
      if(this.activeContent===id)this.activeContent=null
      else this.activeContent = id
    },
    contentClass(content) {
      let result = '';
      switch (content.type_handle) {
        case 'video': result += ' content-type-video'; break;
        case 'game': result += ' content-type-game'; break;
        case 'quiz': result += ' content-type-quiz'; break;
        case 'article': result += ' content-type-article'; break;
        case 'assignment': result += ' content-type-assignment'; break;
        case 'cert': result += ' content-type-cert'; break;
      }
      return result
    },
    sendMessage(user) {
      this.$store.dispatch('message/sendMessage',user.id)
    }
  },
  computed: {
  }
})
</script>

<style scoped lang="scss">
.course-summery-wrapper {
  margin: -10px 0 0 0;
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
}
.course-summery-main {
  flex: 1;
  .course-summery-chapters {
    background: #fff;
    box-shadow: $shadow-big;
    border-radius: $border-radius;
  }

  .course-summery-chapters-header {
    padding: 15px 30px 20px;
    position: relative;
    small {
      color: $color-gray;
    }
    h1 {
      padding: 0;
      margin: 0;
      color: $color-primary;
      font-size: 1.4em;
    }
    .course-summery-percent {
      position: absolute;
      top: 14px;
      right: 14px;
      zoom: 1.4;
      color: $color-gray;
    }
  }

  .course-summery-chapter-contents-wrapper {
    
    /*
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;

    &.active {
      max-height:3000px;
    }
    */
  }
  .course-summery-chapter-contents {
    border-top: 1px dashed #ddd;
    padding: 25px 30px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 30px;
    .course-summery-chapter-content {
      background: #fff;
      box-shadow: $shadow-big;
      border-radius: $border-radius-small;
      overflow: hidden;
      cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
      padding: 15px;
      grid-template-columns: repeat(1,1fr);
      grid-gap: 20px;
    }
  }

  .course-summery-chapter {
    .course-summery-chapter-header {
      padding: 10px 30px 10px 70px;
      border-top: 1px solid #ddd;
      position: relative;
      color: $color-gray;
      cursor: pointer;

      font-family: $font-family-heading;
      .pie {
        position: absolute;
        top: 7px;
        left: 30px;
      }

      &>span {
        position: absolute;
        width: 12px;
        height: 12px;
        top: 13px;
        right: 25px;
        background: transparent url(~@/assets/images/icon-chevron-down.svg) no-repeat center center;
        background-size: contain;
        transition: all 0.2s;
        &.icon-active {
          transform: rotate(180deg)
        }
      }
    }

    .chapter-content-image {
      position: relative;
      width: 100%;
      padding: 56% 0 0 0;
      overflow: hidden;
      margin: 0 0 10px;
      font-family: $font-family-heading;
      &>img {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
      }
      .chapter-content-type {
        color: #fff;
        font-size: 0.8em;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 3;
        padding: 5px 8px 5px 22px;
        display: block;
        background-size: 16px;
        background-position: left center;
        background-repeat: no-repeat;
        line-height: 1.3em;

        &.content-type-video {
          background-image: url(~@/assets/images/icon-play-outline.svg);
        }
        &.content-type-game {
          background-image: url(~@/assets/images/icon-game-outline.svg);
        }
        &.content-type-quiz {
          background-image: url(~@/assets/images/icon-quiz-outline.svg);
        }
        &.content-type-article {
          background-image: url(~@/assets/images/icon-article-outline.svg);
        }
        &.content-type-assignment {
          background-image: url(~@/assets/images/icon-assignment-outline.svg);
        }
        &.content-type-cert {
          background-image: url(~@/assets/images/icon-cert-outline.svg);
        }
      }
      &:after {
        pointer-events: none;
        content: "";
        display: block;
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.2) 80%,  rgba(0, 0, 0, 0.6) 100%);
      }
    }
    .chapter-content {
      padding: 5px 15px 10px;
      color: $color-gray;
      position: relative;
      strong {
        display: block;
        line-height: 1.3em;
        margin: 0 30px 0 0;

        font-family: $font-family-heading;
      }
      small {
        display: block;
        font-size: 0.9em;
        line-height: 1.4em;
        margin: 10px 0 0;
      }
      .content-done {
        background: #42B9B4 url(~@/assets/images/icon-check-white.svg) no-repeat center center;
        background-size: 60%;
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 50px;
        position: absolute;
        top: 5px;
        right: 10px;
      }
    }
  }
}
.course-summery-aside {
  width: 250px;
  margin-left: 30px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }

  /*
  &>div {
    padding: 30px;
    background: #fff;
    box-shadow: $shadow-small;
    border-radius: $border-radius-small;
    margin: 0 0 30px;
  }
  */
}
.course-summery-teachers {
  @extend %box;
  padding: 10px 5px 10px 15px;
  margin: 20px 0;
  
  font-family: $font-family-heading;
  display: flex;
  flex-direction: column;

  .chapter-teacher {
    display: flex;
    align-items: center;
    padding: 5px 0;

    .chapter-teacher-image {
      width: 55px;
      height: 55px;
      border-radius: 55px;
      margin: 0 10px 0 0;
      overflow:hidden;
      background: #f1f1f1;
    }

    .chapter-teacher-info {
      small {
        display: block;
        color: $color-gray;
        opacity: 0.7;
        line-height: 1em;
      font-size: 0.7em;
      }
      strong {
        display: block;
        color: $color-gray;
        line-height: 1.2em;
        font-weight: normal;
        font-weight: bold;
        font-size: 0.8em;
      }
      button {
        width: auto;
        display: inline-block;
        padding: 4px 8px 4px;
        margin: 0;
        font-size: 0.7em;
        line-height: 1.3em;
        svg {
          margin: -2px 0 0 0;
          vertical-align: middle;
        }
      }
    }
  }
}
.course-summery-homework {
  @extend %box;
  padding: 20px 10px 10px;
  margin: 20px 0;

  hgroup {
    position: relative;
    h1 {
      font-size: 1.1em;
      color: $color-gray;
    }
    &:after {
      content: "";
      display: block;
      width: 32px;
      height: 4px;
      background: $color-secondary;
      left: 50%;
      margin-left: -16px;
      bottom: -8px;
      position: absolute;
    }
  }

  .course-summery-homework-list {
    text-align: left;
    margin: 10px;
    color: $color-gray;
    .course-summery-homework-item {
      position: relative;
      padding: 0 0 10px;
      margin: 0 0 10px;
      font-size: 0.8em;
      line-height: 1.4em;
      &>strong {
        display: block;

        font-family: $font-family-heading;
      }
      &>span {
        display: block;
      }
      border-bottom: 1px solid #ddd;
      &:last-child {
        border-bottom: none;
      }
    }
    .course-summery-homework-footer {
      display: flex;
      justify-items: space-between;
      margin: 5px 0 0 0;

      font-family: $font-family-heading;
      &>strong {
        flex: 1;
        color: $color-primary;
        position: relative;
        padding: 0 0 0 20px;
        &:before {
          content: "";
          display: block;
          position: absolute;
          background: url(~@/assets/images/icon-alert.svg) center center;
          background-size: 16px;
          width: 16px;
          height: 16px;
          left: 0;
          top: 0;
        }
      }
      &.homework-pending>strong {
        color: $color-secondary;
        &:before {
          background-image: url(~@/assets/images/icon-clock.svg);
        }
      }
      &.homework-done>strong {
        color: $color-success;
        &:before {
          background-image: url(~@/assets/images/icon-click.svg);
        }
      }
      &>span {
        flex: 1;
        white-space: nowrap;
      }
    }
  }
}
.course-summery-scoreboard {
  @extend %box;
  padding: 20px 10px 10px;
  margin: 20px 0;

  hgroup {
    position: relative;
    h1 {
      font-size: 1.1em;
      color: $color-gray;
    }
    &:after {
      content: "";
      display: block;
      width: 32px;
      height: 4px;
      background: $color-secondary;
      left: 50%;
      margin-left: -16px;
      bottom: -8px;
      position: absolute;
    }
  }

  .course-summery-scoreboard-list {
    text-align: left;
    margin: 10px;
    color: $color-gray;
  
    .course-summery-scoreboard-item {
      position: relative;
      padding: 0 0 5px;
      font-size: 0.9em;
      &>small {
        font-weight: bold;
        display: inline-block;
        font-size: 0.9em;
        width: 20px;
      }
      &:first-child>small {
        position: relative;
        &:after {
          content: "";
          display: inline-block;
          width:20px;
          height:20px;
          background: url(~@/assets/images/icon-crown.svg) center center no-repeat;
          background-size: contain;
          position: absolute;
          left: -6px;
        }
      }
      &>strong {
        font-weight: normal;
      }
      &>span {
        position: absolute;
        top: 0;
        right: 0;
        font-weight: bold;
        color: $color-primary;
      }
    }
  }
}
</style>
