<template>
  <div class="main-content loading-area">
    <div class="header-back-nav"><router-link to="/">&larr; กลับไปก่อนหน้า</router-link></div>
    <div class="video-card" v-if="item">
      <div class="video-card-video">
        <video-player :src="item.url" />
      </div>
      <div class="video-card-content">
        <div class="video-card-content-meta">
          <span class="video-card-content-meta-type">เรื่องราวบันดาลใจ</span>
          <span class="video-card-content-meta-like" :class="{isliked:liked}" @click="doLike">{{item.likes}}</span>
        </div>
        <div class="video-card-content-header">
          <h1>{{item.title}}</h1>
          <h2>ถ่ายทอดโดย <strong>{{item.author}}</strong></h2>
        </div>
        <div class="video-card-content-detail">
          {{item.content}}
        </div>
      </div>
    </div>
    <LoadingSpinner v-if="loading"/>
  </div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { etcService } from '@/services/etc';
export default ({
  components: {
    LoadingSpinner,
    VideoPlayer
  },
  data() {
    return { 
      loading: false,
      item: null,
      liked: false
    }
  },
  created() {
    this.loading = true
    etcService.inspiration(this.$route.params.id).then((res)=>{
      console.log(res)
      this.loading = false
      this.item = res
      this.liked = this.item.liked
    },(res)=>{
      this.loading = false
    })
  },
  methods: {
    doLike() {
      this.loading = true
      etcService.inspiration_like(this.$route.params.id).then((res)=>{
        if(res.like) {
          this.item = {...this.item,...{likes:this.item.likes+1}}
        } else {
          this.item = {...this.item,...{likes:this.item.likes-1}}
        }
        this.liked = res.like
        this.loading = false
      },(res)=>{
        this.loading = false
      })
    }
  },
  computed: {
  }
})
</script>

<style lang="scss" scoped>
.video-card-content-meta-type {
  font-size: 0.8em;
  color: $color-gray;
  float:left;

  &:after {
    content:"";
    display: block;
    width: 20px;
    height: 3px;
    background: $color-secondary;
    margin: 10px 0;
  }
}
.video-card-content-meta-like {
  float: right;
  border-radius: 20px;
  font-size: 0.9em;
  line-height: 18px;
  padding: 3px 10px 3px 32px;
  border: 1px solid $color-secondary;
  color: $color-secondary;
  font-weight: normal;

  background: url(~@/assets/images/icon-heart-red.svg) no-repeat 10px center;
  background-size: 15px;

  &.isliked {
    background-image: url(~@/assets/images/icon-heart.svg);
  }
}
.video-card-content-header {
  clear: both;

  h1 {
    color: $color-primary;
    font-size: 1.6em;
    margin: 0 0 8px;
  }
  h2 {
    color: $color-gray;
    font-size: 1.3em;
    font-weight: normal;
    margin: 0 0 20px;
  }
}
.video-card-video {
  border-radius: $border-radius-small;
  overflow: hidden;
}
</style>
