<template>
  <div class="styled-input">
    <input v-bind="$attrs" :placeholder="placeholder" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" />
    <label>{{placeholder}}</label>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: ['placeholder','modelValue']
}
</script>

<style lang="scss" scoped>
.styled-input {
  position: relative;
  margin: 0 0 15px;
}
.styled-input > input {
}
.styled-input > label {
  display: block;
  position: absolute;
  opacity: 0;
  bottom: 1.9rem;
  color: #5d5d5d;
  transition: 0.2s ease-in-out transform;
  font-size: 10px;
}
.styled-input > input:placeholder-shown + label {
  visibility: hidden;
  z-index: -1;
  transition: 0.2s ease-in-out;
}
.styled-input > input:not(:placeholder-shown) + label,
.styled-input > input:focus:not(:placeholder-shown) + label {
  visibility: visible;
  z-index: 1;
  opacity: 1;
  transform: translateY(-3px);
  transition: 0.2s ease-in-out transform;
}
</style>
