<template>
  <div class="news-container">
    <hgroup>
      <span>ข้อมูลล่าสุด</span>
      <h1>ข่าวสารจากโครงการ</h1>
    </hgroup>
    <div class="news-list">
      <swiper :watchSlidesVisibility="true" :breakpoints="swiper_breakpoints" :navigation="swiper_navigation" :loop="false" >
        <swiper-slide  v-for="item in list" :key="item.id">
          <div class="news-item" @click="go(item.id)">
            <div class="news-item-image">
              <img :src="item.thumbnail"/>
            </div>
            <div class="news-item-detail">
              <p>{{item.title}}</p>
              <small>{{showDate(item.created_at)}}</small>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['list'],
  data() {
    return { 
      swiper_navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      swiper_breakpoints: {
        480: {
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 10
        },
        780: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 15
        },
        1200: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 15
        }
      }
    }
  },
  methods: {
    go(id) {
      this.$router.push('/news/'+id)
    },
    showDate(date) {
      const options = {  year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString('th-TH',options);
    }
  }
}
</script>

<style lang="scss" scoped>
.news-container {
  @extend %box;
  position: relative;
}
.news-list {
  padding: 0 20px;
  margin: 30px 0 15px;
  position: relative;

  .news-item {
    position: relative;
    box-shadow: $shadow-small;
    margin: 0 5px;
    border-radius: $border-radius-small;
    overflow: hidden;
    min-height: 100%;

    cursor: pointer;
  }
  .news-item-image {
    position: relative;
    width: 100%;
    padding: 55% 0 0 0;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  .news-item-detail {
    text-align: left;
    padding: 10px 15px;
    font-size: 0.9em;
    line-height: 1.3em;
    font-family: $font-family-heading;

    small {
      display:block;
      padding: 5px 0 0;
      color: $color-gray;
    }
  }
}
</style>

<style lang="scss">
.news-list {
  .swiper-button-prev {
    left: -8px;
  }
  .swiper-button-next {
    right: -8px;
  }
  .swiper-slide {
    height: auto !important;
    padding: 0 0 5px;
  }
}
</style>