<template>
  <div class="login">
    <div class="header-video">
      <background-video video="/static/videos/home.mp4" :clickstop="false" :hoverstop="false" :autoplay="true"/>
      <div class="container header-video-container">
        <div v-show="action==='login'" class="header-video-login loading-area">
          <hgroup>
            <span>{{$t('for_member')}}</span>
            <h1>{{$t('access_classroom')}}</h1>
          </hgroup>
          <div v-if="login_message" class="errors">{{login_message}}</div>
          <form @submit.prevent="doLogin">
            <styled-input type="text" v-model="username" :placeholder="$t('username')" required/>
            <styled-input type="password" v-model="password" :placeholder="$t('password')" required/>
            <a @click.prevent="forgot" class="forgot-password">{{$t('forgot_password')}}?</a>

            <button class="btn-block btn-primary">{{$t('login')}}</button>
          </form>
          <div class="line-header"><span>{{$t('or')}}</span></div>
          

          <button @click="facebook" class="btn-block btn-fb">{{$t('login_with_facebook')}}</button>
          <button @click="register" class="btn-block">{{$t('not_a_member_register')}}</button>

          <LoadingSpinner v-if="loading"/>
        </div>
        <div v-show="action==='facebook'" class="header-video-login loading-area">
          <hgroup>
            <span>{{$t('login_with_facebook')}}</span>
            <h1>{{$t('more_information')}}</h1>
          </hgroup>
          <div v-if="register_message" class="register_message">
            <div class="errors">{{register_message}}</div>
            <br/>
            <button @click.prevent="login" class="btn">{{$t('back_to_login')}}</button>
          </div>
          <form @submit.prevent="doFbRegister" v-else>
            <div v-if="register_error" class="errors">{{register_error}}</div>

            <styled-select v-model="organization_id">
              <option value="">{{$t('choose_organization')}}</option>
              <option v-for="org in organizations" :key="'orgfb'+org.id" :value="org.id">{{org.name}}</option>
            </styled-select>
            <template v-if="organization_id==10">
              <styled-input type="text" v-model="other_organization" :placeholder="$t('other_organization')+' *'" required/>
            </template>
            <template v-else>
              <div class="row">
                <styled-select v-model="province_id" required>
                  <option value="">{{$t('choose_province')}}</option>
                  <option v-for="province in provinces" :key="'provincefb'+province.id" :value="province.id">{{province.name}}</option>
                </styled-select>
                <styled-select v-model="gender" required>
                  <option value="">{{$t('choose_gender')}}</option>
                  <option value="M">{{$t('male')}}</option>
                  <option value="F">{{$t('female')}}</option>
                </styled-select>
              </div>     
              
              <small>{{$t('birthday')}}</small>
              <div class="row">
                <styled-select v-model="birthday" required>
                  <option value="">{{$t('day')}}</option>
                  <option :value="da" v-for="da in 31" :key="'da'+da">{{da}}</option>
                </styled-select>
                <styled-select v-model="birthmonth" required>
                  <option value="">{{$t('month')}}</option>
                  <option :value="mon" v-for="mon in 12" :key="'bm'+mon">{{mon}}</option>
                </styled-select>
                <styled-select v-model="birthyear" required>
                  <option value="">{{$t('year')}}</option>
                  <option :value="yer+543" v-for="yer in years" :key="'yr'+yer">{{yer+543}}</option>
                </styled-select>
              </div>
            </template>

            <styled-input type="text" v-model="groupcode" :placeholder="$t('group_code_if_any')"/>
            <styled-input type="text" v-model="display_name" :placeholder="$t('display_name')" required/>
            <styled-input type="email" v-model="email" :placeholder="$t('email')"/>

            <p class="text-center">{{$t('login_to_the_system_means_that_you_agree_to')}} <a href="/#/policy" target="_blank">{{$t('privacy_policy')}}</a></p>
            <div class="form-bottom">
              <div><button type="submit" class="btn-block btn-primary">{{$t('accept_and_register')}}</button></div>
              <div class="text-left"><a @click.prevent="login" class="btn btn-subtle">{{$t('cancel')}}</a></div>
            </div>
          </form>
          <LoadingSpinner v-if="loading"/>
        </div>
        <div v-show="action==='register'" class="header-video-register loading-area">
          <hgroup>
            <span>{{$t('register_to_access_classroom')}}</span>
            <h1>{{$t('register')}}</h1>
          </hgroup>

          <div v-if="register_message" class="register_message">
            <p>{{register_message}}</p>
            <button @click.prevent="login" class="btn">{{$t('back_to_login')}}</button>
          </div>
          <form @submit.prevent="doRegister" v-else>
            <div v-if="register_error" class="errors">{{register_error}}</div>
            <div class="row">
              <styled-input type="text" v-model="username" :placeholder="$t('username')+' *'" required/>
              <styled-select v-model="organization_id">
                <option value="">{{$t('choose_organization')}}</option>
                <option v-for="org in organizations" :key="'org'+org.id" :value="org.id">{{org.name}}</option>
              </styled-select>
            </div>
            <template v-if="organization_id==10">
              <styled-input type="text" v-model="other_organization" :placeholder="$t('other_organization')+' *'" required/>
            </template>
            <template v-else-if="organization_id">
              <div class="row">
                <styled-select v-model="province_id" required>
                  <option value="">{{$t('choose_province')}}</option>
                  <option v-for="province in provinces" :key="'province'+province.id" :value="province.id">{{province.name}}</option>
                </styled-select>
                <styled-select v-model="gender" required>
                  <option value="">{{$t('choose_gender')}}</option>
                  <option value="M">{{$t('male')}}</option>
                  <option value="F">{{$t('female')}}</option>
                </styled-select>
              </div>
              <small>{{$t('birthday')}}</small>
              <div class="row">
                <styled-select v-model="birthday" required>
                  <option value="">{{$t('day')}}</option>
                  <option :value="da" v-for="da in 31" :key="'da'+da">{{da}}</option>
                </styled-select>
                <styled-select v-model="birthmonth" required>
                  <option value="">{{$t('month')}}</option>
                  <option :value="mon.no" v-for="mon in months" :key="'bm'+mon.no">{{$t(mon.name)}}</option>
                </styled-select>
                <styled-select v-model="birthyear" required>
                  <option value="">{{$t('year')}}</option>
                  <option :value="yer+543" v-for="yer in years" :key="'yr'+yer">{{yer+543}}</option>
                </styled-select>
              </div>
            </template>

            <div class="row">
              <styled-input type="text" v-model="fullname" :placeholder="$t('fullname')"/>
              <styled-input type="text" v-model="groupcode" :placeholder="$t('group_code')"/>
            </div>
            <div class="row">
              <styled-input type="password" v-model="password" :placeholder="$t('set_password')+' *'" required/>
              <styled-input type="password" v-model="confirmPassword" :placeholder="$t('set_password_again')+' *'" required/>
            </div>
            <div class="row">
              <styled-input type="email" v-model="email" :placeholder="$t('email')"/>
              <styled-input type="number" class="phone-number" v-model="phone" :placeholder="$t('phone_number')"/>
            </div>
            <p class="text-center"><small>{{$t('register_means_that_you_agree_to')}}<a href="/#/policy" target="_blank"> <u>{{$t('privacy_policy')}}</u></a></small></p>
            
            <div id="recapcha-container"></div>
            <div class="form-bottom row">
              <div class="text-right"><a @click.prevent="login" class="btn btn-subtle">{{$t('cancel')}}</a></div>
              <div><button type="submit" class="btn-block btn-primary">{{$t('register')}}</button></div>
            </div>
          </form>
          <LoadingSpinner v-if="loading"/>
        </div>
        <div v-if="action==='forgot'" class="header-video-register loading-area">
          <hgroup>
            <span>{{$t('forgot_password')}}</span>
            <h1>{{$t('enter_your_information_to_reset_password')}}</h1>
          </hgroup>
          <form @submit="doForgot">
            <div v-if="forgot_message" class="notice">{{forgot_message}}</div>
            <styled-input type="email" v-model="email" placeholder="อีเมลล์" required/>
            <div class="form-bottom row">
              <div class="text-right"><a @click.prevent="login" class="btn btn-subtle">{{$t('cancel')}}</a></div>
              <div><button type="submit" class="btn-primary">{{$t('reset_password')}}</button></div>
            </div>
          </form>
          <LoadingSpinner v-if="loading"/>
        </div>
      </div>
    </div>

    <div class="home-intro">
      <div class="home-intro-guide">
        <h1 @click="scrollTo('#home-main')">{{$t('peer_to_peer')}}</h1>
        <div class="home-intro-arrow"><img src="~@/assets/images/intro/arrow-down.png" width="30"/></div>
      </div>
      
      <div data-aos="fade-up" id="home-main">
        <h2>{{$t('home_intro')}}</h2>
        <p class="text-center" v-html="$t('home_intro_text')"></p>
      </div>

      <div class="intro-cards container">
        <div class="intro-card" data-aos="zoom-in" data-aos-delay="50">
          <h5>{{$t('knowledge')}}</h5>
          <div><img src="~@/assets/images/intro/icon1-kn.png"/></div>
          <p v-html="$t('knowledge_text')"></p>
        </div>
        <div class="intro-card" data-aos="zoom-in" data-aos-delay="100">
          <h5>{{$t('skill')}}</h5>
          <div><img src="~@/assets/images/intro/icon2-skill.png"/></div>
          <p v-html="$t('skill_text')"></p>
        </div>
        <div class="intro-card" data-aos="zoom-in"  data-aos-delay="150">
          <h5>{{$t('experience')}}</h5>
          <div><img src="~@/assets/images/intro/icon3-exp.png"/></div>
          <p v-html="$t('experience_text')"></p>
        </div>
      </div>

      <div class="intro-lesson">
        <div class="intro-lesson-content container">
          <div class="intro-lesson-content-left" data-aos="fade-right">
            <img src="~@/assets/images/intro/contents/00.jpeg"/>
          </div>
          <div class="intro-lesson-content-right" data-aos="fade-left">
            <h4>{{$t('many_contents')}}</h4>
            <ul>
              <li>{{$t('many_contents_1')}}</li>
              <li>{{$t('many_contents_2')}}</li>
              <li>{{$t('many_contents_4')}}</li>
            </ul>
          </div>
        </div>
        <div class="container" data-aos="fade-up" data-aos-delay="300"> 
          <div class="intro-lesson-slide">
              <swiper :watchSlidesVisibility="true" :breakpoints="swiper_breakpoints" :navigation="swiper_navigation" :loop="true" >
                <swiper-slide v-for="(item,key) in lessons" :key="key">
                  <div>
                    <img :src="item.src" />
                  </div>
                </swiper-slide>
              </swiper>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>

      <div class="intro-badges">
        <div class="intro-badges-content">
          <div data-aos="fade-up" data-aos-delay="200">
            <h4>{{$t('easy_fun')}}</h4>
            <p class="text-center">{{$t('easy_fun_text')}}</p>
          </div>
          <div class="intro-badges-images">
            <div v-for="index in 8" :key="'badge'+index" data-aos="zoom-in" :data-aos-delay="100*index">
              <img :src="require('@/assets/images/intro/badge-'+index+'.png')"/>
            </div>
          </div>

        </div>
      </div>

      <div class="intro-result">
        <div data-aos="fade-up">
          <h4>{{$t('result_watch')}}</h4>
        </div>
        <div class="intro-result-content container">
          <div class="intro-result-content-chart grid-a" data-aos="fade-right" data-aos-delay="300">
            <div class="intro-result-card">
              <img src="~@/assets/images/intro/test-result.png"/>
            </div>
          </div>
          <div class="intro-result-card intro-result-card-invert grid-b" data-aos="fade-left" data-aos-delay="400">
            <ul>
              <li>{{$t('result_watch_1')}}</li>
              <li>{{$t('result_watch_2')}}</li>
              <li>{{$t('result_watch_3')}}</li>
            </ul>
          </div>
          <div class="intro-result-card grid-c" data-aos="fade-left" data-aos-delay="500">
            <img src="~@/assets/images/intro/step-done.png"/>
          </div>
        </div>
      </div>

      <div class="intro-courses">
        <div class="intro-courses-content" data-aos="fade-up">
          <h4>{{$t('all_course')}}</h4>
          <p class="text-center">{{$t('seven_courses')}}</p>
          <div class="intro-courses-slide">
            <swiper :watchSlidesVisibility="true" :effect="'coverflow'" :centeredSlides="true" :slidesPerView="'auto'" :coverflowEffect="slide3deffect" :navigation="swiper_navigation" :allowSlideNext="true" :allowSlidePrev="true" :initialSlide="3">
              <swiper-slide v-for="(item,key) in courses" :key="'slide3d'+key">
                <div class="intro-courses-slide-wrapper">
                  <img :src="item.src" />
                  <div class="intro-courses-slide-content">
                    <h6>{{item.title[$i18n.locale]}}</h6>
                    {{item.desc[$i18n.locale]}}
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>

      <div class="intro-count">
        <div class="intro-count-content container">
          <div class="intro-count-content-left" data-aos="fade-up">
            <h1>6 {{$t('courses')}}</h1>
          </div>
          <div class="intro-count-content-right" data-aos="fade-right" data-aos-delay="200">
            <div class="intro-count-card">
              <div><img src="~@/assets/images/intro/icon4-lesson.png"/></div>
              <strong>25 {{$t('lessons')}}</strong>
            </div>
            <div class="intro-count-card" data-aos="fade-right" data-aos-delay="400">
              <div><img src="~@/assets/images/intro/icon6-time.png"/></div>
              <strong>8 {{$t('hours')}}</strong>
            </div>
          </div>
        </div>
      </div>

      <div class="intro-inspire">
        <div class="intro-inspire-content container" data-aos="fade-up">
          <h4>{{$t('inspiration')}}</h4>
          <p class="text-center">{{$t('from_frient_to_friend')}}</p>

          <div class="intro-inspire-slide">
              <swiper :watchSlidesVisibility="true" slidesPerColumnFill="row" :slidesPerView="2" :slidesPerColumn="2" :spaceBetween="0" :loop="false" :navigation="swiper_navigation" :breakpoints="{320: {slidesPerView: 1,slidesPerColumn:1},768: {slidesPerView: 1},769: {slidesPerView: 2}}">
                <swiper-slide v-for="(item,key) in inspires" :key="key">
                  <div class="intro-inspire-slide-card">
                    <strong>&ldquo;{{item.content[$i18n.locale]}}&rdquo;</strong>
                    <div class="person">
                      <small>{{item.name[$i18n.locale]}}<br/>{{item.position[$i18n.locale]}}<template v-if="item.title"><br/>{{item.title[$i18n.locale]}}</template></small>
                      <img :src="item.src" />
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>

      <div class="intro-sponsors">
        <div class="intro-sponsors-content container">
          <h4 data-aos="fade-up">{{$t('sponsors')}}</h4>
          <div class="intro-sponsors-images">
            <div v-for="index in 10" :key="'badge'+index" data-aos="zoom-in" :data-aos-delay="100*index">
              <img :src="require('@/assets/images/intro/sponsors/sponsor-'+index+'.png')"/>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'aos/dist/aos.css'
import AOS from 'aos'
import VueScrollTo from 'vue-scrollto'

import BackgroundVideo from '@/components/BackgroundVideo.vue'
import StyledInput from '@/components/Styled/Input.vue'
import StyledSelect from '@/components/Styled/Select.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { userService } from '@/services/user';
import { load } from 'recaptcha-v3'
import Swal from 'sweetalert2'
import SwiperCore, { EffectCoverflow,Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
SwiperCore.use([EffectCoverflow,Navigation]);
export default ({
  components: {
    BackgroundVideo,
    StyledInput,
    StyledSelect,
    LoadingSpinner,
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      lang: 'th',
      action: 'login',
      username: '',
      password: '',
      confirmPassword: '',
      email: '',
      phone: '',
      fullname: '',
      loading: false,
      organization_id: '',
      other_organization: null,
      birthday: null,
      birthmonth: null,
      birthyear: null,
      gender: null,
      province_id: null,
      recaptcha: null,
      organizations: null,
      provinces: null,
      register_message: "",
      login_message: "",
      facebook_profile: null,
      groupcode: null,
      display_name: null,
      register_error: "",
      rest_endpoint: "",
      forgot_message: null,
      slide3deffect: {
        rotate: 0,
        stretch: 30,
        depth: 100,
        modifier: 3,
        slideShadows: false,
      },
      swiper_navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      swiper_breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 10
        }
      },
      lessons: [
        {src:require('@/assets/images/intro/contents/01.jpg')},
        {src:require('@/assets/images/intro/contents/02.png')},
        {src:require('@/assets/images/intro/contents/03.jpg')},
        {src:require('@/assets/images/intro/contents/04.jpg')},
        {src:require('@/assets/images/intro/contents/05.png')},
        {src:require('@/assets/images/intro/contents/06.jpg')},
        {src:require('@/assets/images/intro/contents/07.jpg')},
        {src:require('@/assets/images/intro/contents/08.png')},
        {src:require('@/assets/images/intro/contents/09.jpg')},
        {src:require('@/assets/images/intro/contents/10.png')},
        {src:require('@/assets/images/intro/contents/11.png')},
        {src:require('@/assets/images/intro/contents/12.jpg')},
        {src:require('@/assets/images/intro/contents/13.jpg')},
        {src:require('@/assets/images/intro/contents/14.png')}
      ],
      inspires: [
        {
          content:{
            'th':"การมาอบรมเรียนครั้งนี้ เพื่อจะไปเป็นด่านหน้า เข้าถึงกลุ่มเพื่อนผู้ใช้ยาให้ได้มากที่สุด",
            'en':"These courses are necessary for all frontline workers who need to reach PWID and work with them."
          },
          position:{
            'th':"อดีตรองปลัดกระทรวงสาธารณสุข",
            'en':"Former Deputy Permanent Secretary of ministry of public health"
          },
          name:{
            'th':"นพ.สุริยะ วงศ์คงคาเทพ",
            'en':"Suriya Wongkongkathep, M.D."
          },
          title:{
            'th':"ประธานคณะกรรมการกลไกความร่วมมือของประเทศไทย ภายใต้การสนับสนุนจากกองทุนโลก",
            'en':null
          },
          src:require('@/assets/images/intro/people/01.jpg')}, 
        {
          content:{
            'th':"ส่งต่อความรู้ แบ่งปันประสบการณ์ จากเพื่อนถึงเพื่อน",
            'en':"This is all about sharing knowledge and experience from friends to friends."
          },
          position:{
            'th':"เจ้าหน้าที่ฝ่ายสุขภาพ  หน่วยงาน รักษ์ไทย",
            'en':"Program officer - Health"
          },
          name:{
            'th':"สุภาวดี กกรัมย์",
            'en':"Suphawadee Kokram"
          },title:null,src:require('@/assets/images/intro/people/02.jpg')},
        {
          content:{
            'th':"เข้าถึงเพื่อน เพื่อเข้าถึงโอกาส สู่ความสำเร็จ ด้านสุขภาพและการลดอันตรายจากการใช้สารเสพติด",
            'en':"If we can reach our friends, they will be able to reduce the negative effects of their drug use and have better health."
          },
          position:{
            'th':"ผู้จัดการ หน่วยงาน Together",
            'en':"Manager"
          },
          name:{
            'th':"ปิลันธนา แซ่ชี",
            'en':"Pilanthana Sae-chee"
          },title:null,src:require('@/assets/images/intro/people/03.jpg')},
        {
          content:{
            'th':"ปลุกพลังการเรียนรู้ให้ถึงขีดสุด E-learning มีคำตอบ",
            'en':"These courses will definitely boost your learning."
          },
          position:{
            'th':"ผู้จัดการกลุ่ม หน่วยงาน ACTTEAM",
            'en':"Manager"
          },
          name:{
            'th':"ผ่องศรี บุตรแสน",
            'en':"Pongsri Bootsan"
          },title:null,src:require('@/assets/images/intro/people/04.png')},
        {
          content:{
            'th':"พัฒนาชีวิต คุณค่าศักดิ์ศรีความเป็นมนุษย์ ผู้ใช้ยา",
            'en':"The lessons are meant to improve quality of life and promote human dignity of every single drug user."
          },
          position:{
            'th':"เจ้าหน้าที่ภาคสนาม  หน่วยงาน TDN",
            'en':"Field Officer"
          },
          name:{
            'th':"นัฐรพงษ์ บุณโยทยาน",
            'en':"Nuttarapong Punyodyana"
          },title:null,src:require('@/assets/images/intro/people/05.jpeg')},
        {
          content:{
            'th':"ลดข้อจำกัดด้านเวลาและสถานที่เรียน ด้วยการเรียนผ่านระบบ E-Learning",
            'en':"E-learning is very convenient. I can learn anytime, anywhere."
          },
          position:{
            'th':"ผู้จัดการศูนย์  หน่วยงาน TDN",
            'en':"DIC Manager"
          },
          name:{
            'th':"พิมพ์นารา ตันเสียดี",
            'en':"Pimnara Tanseiydi"
          },title:null,src:require('@/assets/images/intro/people/06.jpeg')},
        {
          content:{
            'th':"ส่งเสริมการป้องกัน ลดการตีตราและเลือกปฏิบัติต่อเพื่อนผู้ใช้ยาเสพติด",
            'en':"We need to reduce the stigma of addiction and discrimination against drug users. "
          },
          position:{
            'th':"ผู้จัดการศูนย์ หน่วยงาน TDN",
            'en':"DIC Manager"
          },
          name:{
            'th':"กุลศิริ  ทิวทอง",
            'en':"Kunsiri  Tiwtong"
          },title:null,src:require('@/assets/images/intro/people/08.jpeg')},
        {
          content:{
            'th':"ก้าวสู่โครงการ STAR นำพา RRTTPR อาสาไปกระจายบอกเพื่อน จากเพื่อนหนึ่งสู่เพื่อนหนึ่ง",
            'en':"Let’s share how we can apply RRTTPR to help our friends."
          },
          position:{
            'th':"เจ้าหน้าที่ภาคสนาม หน่วยงาน TDN",
            'en':"Field Officer"
          },
          name:{
            'th':"วนิดา  มาเอียด",
            'en':"Wanida Maiad"
          },title:null,src:require('@/assets/images/intro/people/09.jpg')},
        {
          content:{
            'th':"มุ่งเน้นการป้องกันอย่างถูกวิธีเพื่อสุขภาพที่ดี",
            'en':"Harm reduction is good for the health of all drug users. "
          },
          position:{
            'th':"เจ้าหน้าที่ภาคสนาม หน่วยงาน TDN",
            'en':"Field Officer"
          },
          name:{
            'th':"อัฟด๊อล สูเหม",
            'en':"Afdol Soohem"
          },title:null,src:require('@/assets/images/intro/people/10.jpg')},
        {
          content:{
            'th':"เน้นเข้าถึง ส่งต่อบริการ ลดการใช้ยา",
            'en':"Reaching our friends is important. They need to access health services and reduce the health harms associated with addiction"
          },
          position:{
            'th':"เจ้าหน้าที่ธุรการบัญชี หน่วยงาน TDN",
            'en':"Accounting Officer(site)"
          },
          name:{
            'th':"อมราวดี ผ่าพลู",
            'en':"Amaravadee Phaplu"
          },title:null,src:require('@/assets/images/intro/people/11.jpg')},
        {
          content:{
            'th':"ลด ละ เพื่อความปลอดภัยจากการใช้เข็ม",
            'en':"We need to enhance safer drug use among PWID. "
          },
          position:{
            'th':"ผู้จัดการศูนย์ หน่วยงาน TDN",
            'en':"DIC Manager"
          },
          name:{
            'th':"ภัทราวุธ ชูแก้ว",
            'en':"Pattrawut Chukaew"
          },title:null,src:require('@/assets/images/intro/people/12.jpg')},
        {
          content:{
            'th':"เสริมความรู้โรคติดต่อ ช่วยให้ลดอันตราย จากการใช้ยาได้",
            'en':"Learning more about infectious diseases can help promote safer drug use."
          },
          position:{
            'th':"เจ้าหน้าที่ภาคสนาม หน่วยงาน TDN",
            'en':"Field Officer"
          },
          name:{
            'th':"Chatree Kantawong",
            'en':"These courses are necessary for all frontline workers who need to reach PWID and work with them."
          },title:null,src:require('@/assets/images/intro/people/13.jpg')},
      ],
      courses: [
        {
          title:{
            'th':"ปฐมนิเทศ",
            'en':"Welcome"
          },
          desc:{
            'th':"ทำความรู้จักโครงการ STAR รูปแบบการดำเนินงาน RRTTPR และหลักสูตร \"เพื่อนเพื่อเพื่อน\"",
            'en':"Get to know ‘STAR’, ‘RRTTPR’ and the Peer to Peer Harm Reduction Development Program."
          },
          src:require('@/assets/images/intro/courses/01.png')
        }, 
        {
          title:{
            'th':"ความรู้พื้นฐาน",
            'en':"Basic Lessons"
          },
          desc:{
            'th':"เตรียมความพร้อมอาสาสมัครและคนทำงาน เสริมความรู้และความเข้าใจเกี่ยวกับแนวทางการลดอันตรายจากการใช้ยา เพิ่มทักษะในการเข้าหาเพื่อนผู้ใช้ยา",
            'en':"Learn about drugs, addiction and harm reduction. Develop skills and work attitudes to prepare yourself to work with PWID effectively. "
          },
          src:require('@/assets/images/intro/courses/02.jpeg')
        }, 
        {
          title:{
            'th':"เอชไอวี และเอดส์",
            'en':"HIV & AIDS"
          },
          desc:{
            'th':"เสริมความรู้เกี่ยวกับไวรัสเอชไอวี เพื่อยุติปัญหาโรคเอดส์ตามเป้าหมายของโครงการ STAR",
            'en':"Learn about HIV and AIDS and how to prevent transmission among PWID."
          },
          src:require('@/assets/images/intro/courses/03.png')
        }, 
        {
          title:{
            'th':"โรคติดต่อ ",
            'en':"Infectious diseases"
          },
          desc:{
            'th':"รู้จักโรคติดต่อที่เกิดจากการใช้เข็มและโรคติดต่อทางเพศสัมพันธ์ เพื่อเข้าใจแนวทางในการป้องกันโรคต่าง ๆ",
            'en':"Learn about the infectious diseases caused by injection drug use and STIs to understand symstoms, treatment and prevention."
          },
          src:require('@/assets/images/intro/courses/04.jpeg')
        }, 
        {
          title:{
            'th':"ขั้นตอนการเปลี่ยนแปลงพฤติกรรม",
            'en':"Stages of Behavior"
          },
          desc:{
            'th':"เข้าใจเพื่อนผู้ใช้ยาผ่านหลักบันไดแห่งการเปลี่ยนแปลงพฤติกรรม เพื่อสนับสนุนเพื่อนได้อย่างตรงจุด และช่วยให้เพื่อนคงสภาพปลอดยาได้ต่อไป",
            'en':"Understand PWID in different stages of behavior change to provide the best support for them and help them maintain a drug-free lifestyle. "
          },
          src:require('@/assets/images/intro/courses/05.jpeg')
        }, 
        {
          title:{
            'th':"การเข้าหาผู้ใช้สารเสพติดเฉพาะกลุ่ม ",
            'en':"Working with PWID from Different Groups"
          },
          desc:{
            'th':"รู้จักเพื่อนผู้ใช้ยาเฉพาะกลุ่มที่มีลักษณะเฉพาะที่แตกต่างกันไปและเรียนรู้แนวทางในการเข้าหาเพื่อทำงานร่วมกับเพื่อนผู้ใช้ยาเฉพาะกลุ่มให้ได้ดียิ่งขึ้น",
            'en':"Learn how to reach different groups of PWID - female, young adult, Muslim and ethnics."
          },
          src:require('@/assets/images/intro/courses/06.jpeg')
        }, 
        {
          title:{
            'th':"เรื่องราวบันดาลใจ",
            'en':"Inspiring Stories"
          },
          desc:{
            'th':"ฟังเรื่องราวบันดาลใจจากอาสาสมัครและคนทำงานที่มีประสบการณ์การทำงานร่วมกับเพื่อนผู้ใช้ยามานาน",
            'en':"Listen to inspiring stories from our people who work with PWID for several years."
          },
          src:require('@/assets/images/intro/courses/07.png')
        }, 
      ],
    }
  },
  created() {
    this.loadFacebookSDK(document, "script", "facebook-jssdk");
    this.initFacebook();
    userService.organizations()
        .then((res)=>{
          this.organizations = res.organizations
          this.provinces = res.provinces
        },(res)=>{
          //nothing
        })
    this.rest_endpoint = process.env.VUE_APP_API_SERVER

  },
  mounted() {
    this.$i18n.locale='th'
    let container = document.getElementById('recapcha-container')
    load('6Le7edsZAAAAAPnuaq1QVcBjyI6TVYtnU3t3dIV3',{
      explicitRenderParameters: {
        badge: 'inline',
        container: container,
      }}).then((recaptcha) => {
        this.recaptcha = recaptcha
    }).catch(console.error)

    setTimeout(()=>{
      AOS.init({ duration: 400 })
    },3000)
  },
  methods: {
    scrollTo(element) {
      var options = {
        container: 'body',
        easing: 'ease-in-out',
        offset: -40,
        force: true,
        cancelable: true,
        onStart: function(element) {
          // scrolling started
        },
        onDone: function(element) {
          // scrolling is done
        },
        onCancel: function() {
          // scrolling has been interrupted
        },
        x: false,
        y: true
      }
      var cancelScroll = VueScrollTo.scrollTo(element, 500, options)
    },
    register(event) {
      this.action = 'register'
    },
    login(event) {
      this.action = 'login'
    },
    forgot(event) {
      this.action = 'forgot'
    },
    doRegister(event) {
      this.register_message = null
      this.register_error = null
      //console.log(this.recaptcha.recaptchaID)
      if(this.recaptcha && this.recaptcha.recaptchaID) {
        var capcha = this.recaptcha.recaptcha.getResponse()
        if(!capcha) {
          Swal.fire({
            title: 'Capcha ไม่ถูกต้อง',
            icon: 'error',
            confirmButtonText: 'ตกลง'
          })
          return;
        }
      }
      let data = {
        "username": this.username,
        "display_name": this.username,
        "email" : this.email,
        "phone" : this.phone,
        "fullname" : this.fullname,
        "password": this.password,
        "password_confirmation": this.confirmPassword,
        "organization_id": this.organization_id,
        "other_organization": this.other_organization,
        "dob": this.dob,
        "gender": this.gender,
        "province_id": this.province_id,
        "code": this.groupcode
      }
      this.loading = true
      userService.register(data)
        .then((res)=>{
          if(res.token) {
            this.$store.dispatch('user/login',res.token.access_token)
            this.$router.push('/')
          } else if(res.messages) {
            this.register_message = res.messages[0]
          }
          this.loading = false
        },(res)=>{
          if(res) {
            this.register_error = res[0]
          }
          this.loading = false
        })
    },
    doLogin(event) {
      this.login_message = null
      this.loading = true
      let data = {
        "username": this.username,
        "password": this.password,
      }
      userService.login(data)
        .then((res)=>{
          if(res.token) {
            this.$store.dispatch('user/login',res.token.access_token)
            this.loading = false
            this.$router.push('/')
          }
        },(res)=>{
          //console.log(res)
          if(res) {
            this.login_message = res[0]
          }
          this.loading = false
        })
    },
    doForgot(event) {
      this.forgot_message = null;
      let data = {
        'email':this.email
      }
      userService.forgot(data)
        .then((res)=>{
          this.loading = false
          if(res.status) {
            this.forgot_message = res.status
          } else if (res.email) {
            this.forgot_message = res.email
          }
          this.email = "";
        },(res)=>{
          if(res) {
            this.register_error = res[0]
          }
          this.loading = false
        })
    },
    async facebook() {
      window.FB.login((response) => {
        if (response.authResponse) {
          this.loggedInFacebook(response)
        } else {
          /*
          Swal.fire({
            title: 'ผู้ใช้งานยกเลิก Facebook Login',
            icon: 'error',
            confirmButtonText: 'ตกลง'
          })
          */
        }
      }, {scope: 'public_profile,email'});
      return false;
    },
    async initFacebook() {
      window.fbAsyncInit =  () => {
        window.FB.init({
          appId: "1058594111213977",
          cookie: true,
          xfbml: true,
          version: 'v9.0'
        });
        /*
        window.FB.getLoginStatus((response) => {
          this.loggedInFacebook(response)
        }, {scope: 'public_profile,email'});
        */
      };
    },
    loggedInFacebook(response) {
      if(response.status=="connected" && response.authResponse && response.authResponse.accessToken) {
        this.login_message = null
        this.loading = true
        let data = {
          "fb_access_token": response.authResponse.accessToken
        }
        this.fbAccessToken = response.authResponse.accessToken;
        userService.fblogin(data)
          .then((res)=>{
            this.loading = false
            if(res.is_new_user) {
              this.action = 'facebook';
              this.display_name = res.user.display_name
              this.email = res.user.email
            } else if(res.token) {
              this.$store.dispatch('user/login',res.token.access_token)
              this.$router.push('/')
            } else {
              Swal.fire({
                title: 'ไม่สามารถเข้าระบบได้',
                icon: 'error',
                confirmButtonText: 'ตกลง'
              })
            }

          },(res)=>{
            if(res) {
              this.login_message = res[0]
            }
            this.loading = false
          })
      }
    },
    loadFacebookSDK(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    doFbRegister() {
      this.register_message = null
      this.register_error = null
      let data = {
        "fb_access_token": this.fbAccessToken,
        "display_name": this.display_name,
        "code": this.groupcode,
        "email" : this.email,
        "phone" : this.phone,
        "organization_id": this.organization_id,
        "other_organization": this.other_organization,
        "dob": this.dob,
        "gender": this.gender,
        "province_id": this.province_id,
      }
      this.loading = true
      userService.fbregister(data)
        .then((res)=>{
          console.log(res)
          if(res.token) {
            this.$store.dispatch('user/login',res.token.access_token)
            this.$router.push('/')
          } else if(res.messages) {
            this.register_message = res.messages[0]
          }
          this.loading = false
        },(res)=>{
          if(res) {
            this.register_error = res[0]
          }
          this.loading = false
        })
    },
  },
  computed: {
    dob() {
      if(this.birthyear) {
        return this.birthyear-543+'-'+this.birthmonth.padStart(2, '0')+'-'+this.birthday.padStart(2, '0')
      } else {
        return null;
      }
    },
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: 100}, (value, index) => year - index)
    },
    months() {
      return [
        {"no":1,"name":"january"},
        {"no":2,"name":"february"},
        {"no":3,"name":"march"},
        {"no":4,"name":"april"},
        {"no":5,"name":"may"},
        {"no":6,"name":"june"},
        {"no":7,"name":"july"},
        {"no":8,"name":"august"},
        {"no":9,"name":"september"},
        {"no":10,"name":"october"},
        {"no":11,"name":"november"},
        {"no":12,"name":"december"}
      ]
    }
  }
})
</script>

<style scoped lang="scss">
@keyframes jumping {
  0% { top: 0 }
  20% { top: -10px }
  40% { top: 0 }
}
.home-intro {
  background: #fff;
  h1 {
    text-align: center;
    font-size: 2.2em;
    color: #000;
    margin: 0.5em;
    line-height: 1.4em;

    @media only screen and (max-width: 768px) {
      font-size: 1.8em;
      line-height: 1.3em;
    }
  }
  h2 {
    text-align: center;
    font-size: 1.8em;
    color: #000;
    margin: 0.5em;
    line-height: 1.4em;
    &:after {
      clear: both;
      content: "";
      display: block;
      margin: 10px auto 0;
      height: 4px;
      width: 200px;
      background: $color-secondary;
      border-radius: 4px;
    }


    @media only screen and (max-width: 768px) {
      font-size: 1.6em;
      line-height: 1.3em;
    }
  }
  h4 {
    text-align: center;
    font-size: 1.8em;
    color: #000;
    margin: 0.5em 0.5em 0.5em;
    line-height: 1.4em;
    &:after {
      clear: both;
      content: "";
      display: block;
      margin: 10px auto 0;
      height: 4px;
      width: 40px;
      background: $color-secondary;
      border-radius: 4px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 1.5em;
      line-height: 1.3em;
    }
  }
  h6 {
    text-align: center;
    font-size: 1.3em;
    margin: 0;
    line-height: 1.4em;
    &:after {
      clear: both;
      content: "";
      display: block;
      margin: 5px auto 10px;
      height: 3px;
      width: 30px;
      background: $color-secondary;
      border-radius: 3px;
    }

    @media only screen and (max-width: 600px) {
      font-size: 1.2em;
      line-height: 1.3em;
    }
  }

  .home-intro-guide {
    text-align: center;
    padding: 20px 0 60px;

    @media only screen and (max-width: 600px) {
      padding: 20px 0 40px;
    }
    h1 {
      margin-bottom: 0;
      font-size: 2.6em;
      color: $color-primary;
    }
    .home-intro-arrow {
      position: relative;
      animation-name: jumping;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
    
  }

  #home-main {
    @media only screen and (max-width: 600px) {
      p {
        padding: 0 10px;
        font-size: 0.8em;
      }
    }
  }

  .intro-cards {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 40px;
    margin: 40px auto 60px;
    @media only screen and (max-width: 600px) {
      grid-template-columns: repeat(1,1fr);
      gap: 20px;
    }
    .intro-card {
      box-shadow: $shadow-small;
      border-radius: 15px;
      padding: 40px 10px 10px;
      background: #fff;
      position: relative;
      text-align: center;

      @media only screen and (max-width: 600px) {
        margin: 10px 0;
      }

      &> h5 {
        font-size: 1.6em;
        display: block;
        position: absolute;
        background: $color-primary-80;
        color: $color-primary-tint;
        line-height: 1.8em;
        left: 20px;
        right: 20px;
        top: -0.9em;
        border-radius: 20px;

      }

      &> div {
        width: 160px;
        height: 160px;
        margin: 10px auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-items: center;
        @media only screen and (max-width: 600px) {
          width: 100px;
          height: 100px;
        }
      }
    }
  }

  .intro-lesson {
    background: #F4F4F5;
    padding: 60px 0;
    .intro-lesson-content {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }
      .intro-lesson-content-left {
        padding-right: 60px;
        width: 65%;
        @media only screen and (max-width: 600px) {
          padding: 10px;
          width: 100%;
        }
        img {
          border-radius: 20px;
        }
      }
      .intro-lesson-content-right {
        ul {
          margin: 20px 0;
          li {
            background: url(~@/assets/images/icon-check-circle.svg) no-repeat center left;
            background-size: 20px;
            line-height: 1.3em;
            margin: 10px 0;
            padding: 0 0 0 30px;
          }
        }
      }
    }
  }

  .intro-lesson-slide {
    margin: 40px 0 0;
    position: relative;

    @media only screen and (max-width: 768px) {
      margin: 20px 70px 0 70px;
    }

    .swiper-slide {
      img {
        box-shadow: $shadow_small;
      }
    }
    
    .swiper-button-prev {
      left: -70px;
      display: block;
      width: 50px;
      height: 120px;
      background: url(~@/assets/images/intro/arrow-left.png) no-repeat center left;
      background-size: 50px;
      top: 50%;
      margin-top: -60px;
      &:after {
        display: none;
      }
    }
    .swiper-button-next {
      right: -70px;
      display: block;
      width: 50px;
      height: 120px;
      background: url(~@/assets/images/intro/arrow-right.png) no-repeat center left;
      background-size: 50px;
      top: 50%;
      margin-top: -60px;
      &:after {
        display: none;
      }
    }
  }

  .intro-badges {
    background: #fff;
    padding: 60px 0;
    .intro-badges-content {
      max-width: 1100px;
      margin: 0 auto;
    }
    .intro-badges-images {
      margin: 20px 0;
      display: grid;
      grid-template-columns: repeat(8,1fr);
      gap: 30px;
      text-align: center;
      @media only screen and (max-width: 768px) {
        gap: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
      @media only screen and (max-width: 600px) {
        grid-template-columns: repeat(4,1fr);
      }
      img {
        max-height: 100px;
      }
    }
  }

  .intro-result {
    background: #F4F4F5;
    padding: 60px 0;
    .intro-result-content {
      margin: 40px 0 0 0;
      display: grid; 
      grid-template-columns: 3fr 2fr; 
      gap: 40px;
      .grid-a { 
        grid-row-start: 1;
        grid-row-end: 3; 
      }
      .grid-b { 
        
      }
      .grid-c {
        
      }

      @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr; 
        .grid-a { 
          grid-row-start: 1;
          grid-row-end: 2; 
          grid-column-start: 1;
          grid-column-end: 3; 
        }
      }
      @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr; 
        gap: 20px;
        .grid-a { 
          grid-row-start: 1;
          grid-row-end: 2; 
          grid-column-start: 1;
          grid-column-end: 2; 
        }
      }
    }
    .intro-result-card {
      box-shadow: $shadow-small;
      border-radius: 15px;
      padding: 20px;
      background: #fff;
      position: relative;
      text-align: center;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;


      &.intro-result-card-invert {
        background: $color-primary-80;
        color: $color-primary-tint;

        ul {
          margin: 0;
          li {
            text-align: left;
            font-weight: bold;
            background: url(~@/assets/images/intro/check-white.png) no-repeat center left;
            background-size: 20px;
            line-height: 1.4em;
            margin: 15px 0;
            padding: 0 0 0 30px;
          }
        }
      }
    }
  }

  .intro-courses {
    background: #fff;
    padding: 60px;

    .intro-courses-slide {
      position: relative;
    }
    .intro-courses-slide-wrapper {
      background: $color-primary-80;
      color: $color-primary-tint;
      border-radius: 10px 20px;
      overflow: hidden;
      box-shadow: 1px 1px 8px rgba(0,0,0,0.5);
      .intro-courses-slide-content {
        padding: 5px 20px 20px;
        font-weight: bold;
        @media only screen and (max-width: 600px) {
          padding: 5px 10px 10px;
          font-size: 0.8em;
        }
      }
    }

    .swiper-button-prev {
      left: 50%;
      margin-left: -300px;
      display: block;
      width: 50px;
      height: 120px;
      background: url(~@/assets/images/intro/arrow-left.png) no-repeat center left;
      background-size: 50px;
      top: 50%;
      margin-top: -60px;
      &:after {
        display: none;
      }
      @media only screen and (max-width: 600px) {
        left: 0;
        margin-left: -60px;
      }
    }
    .swiper-button-next {
      right: 50%;
      margin-right: -300px;
      display: block;
      width: 50px;
      height: 120px;
      background: url(~@/assets/images/intro/arrow-right.png) no-repeat center left;
      background-size: 50px;
      top: 50%;
      margin-top: -60px;
      &:after {
        display: none;
      }
      @media only screen and (max-width: 600px) {
        right: 0;
        margin-right: -60px;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
        &.swiper-button-disabled {
        opacity: 1;
        cursor: pointer;
        pointer-events: auto;
      }
    }
  }

  .intro-count {
    background: $color-primary-80;
    padding: 60px;
    .intro-count-content {
      display: grid;
      grid-template-columns: 3fr 5fr;
      gap: 60px;

      @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 20px;
      }
      
      .intro-count-content-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        h1 {
          color: $color-primary-tint;
          display: inline-block;
          border-bottom: 3px solid $color-secondary;
          font-size: 2.5em;
          width: 5em;
          margin: 0;
        }
      }

      .intro-count-content-right {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
        @media only screen and (max-width: 600px) {
          grid-template-columns: repeat(1,1fr);
        }
        .intro-count-card {
          max-width: 180px;
          background: #fff;
          padding: 20px;
          border-radius: 20px;
          box-shadow: $shadow-big;
          text-align: center;

          @media only screen and (max-width: 600px) {
            max-width: 400px;
          }
          &>strong {
            display: block;
            font-weight: bold;
            font-size: 1.2em;
            margin: 0 0 0 0;
          }
          &>div {
            margin: 0 auto;
            width: 130px;
            height: 150px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            img {
              max-height: 100px;
            }
            @media only screen and (max-width: 600px) {
              width: 100px;
              height: 80px;
              img {
                max-height: 60px;
              }
            }
          }
        }
      }
    }
  }

  .intro-inspire {
    background: #F4F4F5;
    padding: 60px 0;
    .intro-inspire-slide {
      margin: 30px 0 0 0;
      position: relative;

      @media only screen and (max-width: 768px) {
        margin-left: 70px;
        margin-right: 70px;
      }
      @media only screen and (max-width: 600px) {
        margin-left: 20px;
        margin-right: 20px;
      }
      .swiper-button-prev {
        left: -70px;
        display: block;
        width: 50px;
        height: 120px;
        background: url(~@/assets/images/intro/arrow-left.png) no-repeat center center;
        background-size: 50px;
        top: 50%;
        margin-top: -60px;
        &:after {
          display: none;
        }

        @media only screen and (max-width: 600px) {
          width: 30px;
          left: 0;
          margin-left: -30px;
          background-size: 30px;
        }
      }
      .swiper-button-next {
        right: -70px;
        display: block;
        width: 50px;
        height: 120px;
        background: url(~@/assets/images/intro/arrow-right.png) no-repeat center center;
        background-size: 50px;
        top: 50%;
        margin-top: -60px;
        &:after {
          display: none;
        }
        @media only screen and (max-width: 600px) {
          width: 30px;
          right: 0;
          margin-right: -30px;
          background-size: 30px;
        }
      }
    }
    .intro-inspire-slide-card {
      background: #fff;
      padding: 20px;
      border-radius: 20px;
      box-shadow: $shadow-big;
      margin: 20px;
      @media only screen and (max-width: 768px) {
        margin: 10px;
      }
      strong {
        display: block;
        font-family: $font-family-heading;
        font-size: 1.3em;
        padding: 20px 50px;
        line-height: 1.5em;
        @media only screen and (max-width: 600px) {
          padding: 10px;
          font-size: 1.1em;
          line-height: 1.3em;
        }
      }
      .person {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-align: right;

        @media only screen and (max-width: 600px) {
          flex-direction: column-reverse;
          text-align: center;
          margin-top: 10px;
          
        }
        small {
          font-size: 0.85em;
          line-height: 1.4em;
        }
        img {
          margin-left: 10px;
          width: 60px;
          border-radius: 60px;
        }
      }
    }
  }

  .intro-sponsors {
    background: #fff;
    padding: 60px 0;
    .intro-sponsors-images {
      display: grid;
      grid-template-columns: repeat(4,1fr);
      gap: 20px 40px;
      text-align: center;
      margin: 20px 0;


      @media only screen and (max-width: 600px) {
        grid-template-columns: repeat(3,1fr);
      }
      &>div:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
        align-items: flex-end;
        padding-right: 40px;
        img {
          max-width: 250px;
          max-height: 150px;
        }
        @media only screen and (max-width: 600px) {
          align-items: center;
          padding: 0;
          grid-column-start: 1;
          grid-column-end: 3;
          img {
            max-width: 200px;
          }
        }
      }
      &>div:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 5;
        align-items: flex-start;
        padding-left: 40px;
        img {
          max-width: 100px;
          max-height: 100px;
        }
        @media only screen and (max-width: 600px) {
          align-items: center;
          padding: 0;
          grid-column-start: 3;
          grid-column-end: 5;
        }
      }
      &>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          max-width: 90px;
          max-height: 100px;
          @media only screen and (max-width: 600px) {
            max-width: 60px;
            max-height: 70px;
          
          }
        }
      }
    }
  }
}

.swiper-container-3d {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 400px;
    height: 300px;
    @media only screen and (max-width: 600px) {
      width: 250px;
      height: 250px;
    }
    
  }
  .swiper-slide-active {
    opacity: 1;
  }
}

.row {
  display: flex;
  &>div {
    flex: 1;
    margin-right: 10px;
  }
  &>div:last-child {
    margin-right: 0;
  }
}
.form-bottom {
  margin-top: 10px;
}
.header-video {
  position: relative;
  height: 45vw;
  overflow:hidden;
  min-height: 650px;
  max-height: calc(100vh - 49px);
  background: #000;
  font-family: $font-family-heading;

  .background-video-wrapper {
    width: 100%;
    height: 100%;
  }

  /*
  .video-player-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
  }
  */
}
.header-video-container {
  
  position: absolute;
  width: 380px;
  right: 0;
  top: 0;
  bottom: 0;
  @media only screen and (max-width: 1200px) {

  }
  @media only screen and (max-width: 960px) {

  }
  @media only screen and (max-width: 768px) {
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    width: auto;
  }

  background: rgba(255,255,255,0.75);
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header-video-login {
}
.header-video-register {
}
.forgot-password {
  position:relative;
  top: -10px;
  font-size: 0.8em;
  margin-left: 10px;
  text-decoration: underline;
}
.register_message {
  text-align: center;
  p {
    font-size: 1.5em;
    color: $color-primary-80;
    font-weight: bold;
    line-height: 1.5em;
    padding: 0 20px 10px;
  }
}
.errors {
  text-align: center;
  font-size: 1;
  color: $color-primary-80;
  font-weight: bold;
  line-height: 1.5em;
  padding: 5px 20px;
  border: 1px solid $color-primary-80;
  margin: 0 0 20px;
  border-radius: 10px;
}
.notice {
  text-align: center;
  font-size: 1;
  color: #00A4E3;
  font-weight: bold;
  line-height: 1.5em;
  padding: 5px 20px;
  border: 1px solid #00A4E3;
  margin: 0 0 20px;
  border-radius: 10px;
}
</style>
