<template>
  <div class="video-player-wrapper">
    <video v-if="content.url" ref="videoPlayer" id="lesson-video-player" class="video-js vjs-theme-fantasy" playsinline disablePictureInPicture></video>
    <div v-if="ended" id="ended-overlay">
      <div>
        <button @click="replay" class="btn">ดูวีดีโอซ้ำ <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-rotate" width="20" height="20" viewBox="0 0 24 24" stroke-width="2"  fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M19.95 11a8 8 0 1 0 -.5 4m.5 5v-5h-5" /></svg></button>
        &nbsp;
        <button @click="gotoNext" class="btn btn-primary">ไปยังบทเรียนต่อไป &rarr;</button>
      </div>
    </div>
    <div id="quiz-overlay">
      <div v-if="showQuiz" class="quiz-overlay">
        <div class="quiz-body quiz-wrapper">
          <hgroup>
            <span>คำถามสายฟ้าแลบ</span>
          </hgroup>
          <div>
            <form class="question-form" @submit.prevent="submitQuiz">
              <p><strong>{{currentQuiz.title}}</strong></p>
              <lesson-question :question="currentQuiz" v-model="currentQuiz.picked_answer"/>
              <div class="question-bottom">
                <div></div>
                <div>
                  <button class="btn btn-primary" type="submit" value="submit">ยืนยันคำตอบ</button>
                </div>
              </div>
            </form>
          </div><!-- .question -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videojs from 'video.js'
import markers from 'videojs-markers'
import LessonQuestion from '@/components/lesson/Question.vue'
import Swal from 'sweetalert2'

export default {
  name: 'LessonVideo',
  props: ['content'],
  emits: ['done'],
  components: {
    LessonQuestion
  },
  data () {
    return {
      player: null,
      showQuiz: false,
      currentQuiz: null,
      ended: false,
      quizs: []
    }
  },
  mounted () {
    const canFullscreen = (function() {
      for (const key of [
          'exitFullscreen',
          'webkitExitFullscreen',
          'webkitCancelFullScreen',
          'mozCancelFullScreen',
          'msExitFullscreen',
        ]) {
        if (key in document) {
          return true;
        }
      }
      return false;
    }());
    
    if(this.content.questions) {
      this.quizs = this.content.questions.map((item)=>{
        let a = item.time.split(':');
        let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
        return {
          "time": seconds,
          "id": item.id,
          "type":item.type,
          "version":item.version_id,
          "title":item.title,
          "score":item.score,
          "picked_answer":null,
          "choices": item.choices,
          "answers": item.answers,
          "answer": item.answer
        }
      })
    }

    if(this.content.url) {
      let options = {
        sources: [{src:this.content.url}],
        autoplay: true,
        controls: true,
        muted: false,
        responsive: true,
        fluid: true,
        loop: false,
        fill: true,
        controlBar: {
          pictureInPictureToggle: false,
          volumePanel: false,
          subsCapsButton: false,
          fullscreenToggle:canFullscreen,
          // this is the new control, so, disable it
          volumeMenuBar: false,
          // the old control had a separate mute toggle
          muteToggle: {},
          // the actual volume bar
          volumeControl: {}
        }
      }
      //TypeError: The element or ID supplied is not valid. (videojs)
      //console.log(this.$ref)
      this.player = videojs(this.$refs.videoPlayer, options, () => {
        this.player.playsinline(true);
        let overlay = document.getElementById('quiz-overlay')
        document.getElementById('lesson-video-player').append(overlay)
        this.player.markers({
          onMarkerReached: this.markerReached,
          onMarkerClick: this.markerClicked,
          markerStyle: false,
          markerTip:{
            display: true,
            text: function(marker) {
              return marker.text;
            },
            time: function(marker) {
              return marker.time;
            }
          },
          markers: this.quizs.map((item)=>{
            return {
              time: item.time,
              quiz_id: item.id,
              text: item.title,
              class: 'custom-marker'
            }
          })
        });
      })

      if(!canFullscreen) {
        var videoJsButtonClass = videojs.getComponent('Button');
        var concreteButtonClass = videojs.extend(videoJsButtonClass, {

          // The `init()` method will also work for constructor logic here, but it is 
          // deprecated. If you provide an `init()` method, it will override the
          // `constructor()` method!
          constructor: function() {
            videoJsButtonClass.call(this, this.player);
          }, // notice the comma

          handleClick: ()=>{
            // Do your stuff
            if(this.fullwindow){

              this.fullwindow = false
              this.player.exitFullWindow()
            } else {
              this.fullwindow = true
              this.player.enterFullWindow()
            }
          }
        });

        var concreteButtonInstance = this.player.controlBar.addChild(new concreteButtonClass());
            concreteButtonInstance.addClass("vjs-fullscreen-control");
      }

      this.player.on('seeking',(item)=>{
        let current = Math.round(this.player.currentTime())
        this.quizs.forEach((quiz)=>{
          if(current > quiz.time) {
            if( quiz.picked_answer === null ){
              this.player.currentTime(quiz.time-0.30)
            }
          }
        });
      })
      this.player.on("ended", (event) => { //chrome fix
        //console.log(this.duration())
        this.$emit('done')
        this.ended = true
        if (this.player.isFullscreen()) {
          this.player.exitFullscreen()
        }
      });

      this.player.on('contextmenu', function(e) {
        e.preventDefault()
      });
    }

    //this.player.enterFullWindow()
    
  },
  beforeUnmount () {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    markerReached(marker) {
      let current = Math.round(this.player.currentTime())
      let thisans = this.quizs.find((item)=>{return item.id===marker.quiz_id})
      if(current === marker.time && thisans.picked_answer===null) {
        this.showOverlayQuiz(marker)
      }
    },
    markerClicked(marker) {
      this.showOverlayQuiz(marker)
    },
    showOverlayQuiz(marker) {
      this.player.pause()
      this.showQuiz = true
      this.currentQuiz = this.quizs.find((item)=>{return item.id===marker.quiz_id})
    },
    submitQuiz() {
      let correct = false
      if(this.currentQuiz.picked_answer) {
        if(this.currentQuiz.type === 'multiple_choice') {
          if( this.currentQuiz.answer && this.currentQuiz.picked_answer == this.currentQuiz.answer ) {
            correct = true
          }
        } else if(this.currentQuiz.type === 'matching') {
          correct = true
          this.currentQuiz.answers.map((choice)=>{
            // choice.is_corrent
            this.currentQuiz.picked_answer.map((picked)=>{
              if(picked.id==choice.id && picked.value!=choice.matching_choice_id) {
                correct = false
              }
            })
          })
        } else if ( this.currentQuiz.type === 'multiple_selection' ) {
          //
          if( this.currentQuiz.picked_answer && this.currentQuiz.picked_answer.length === this.currentQuiz.answers.length ) {
            correct = true
            this.currentQuiz.answers.map((ans)=>{
              if(!this.currentQuiz.picked_answer.includes(ans))correct = false
            })
          }
        } else if( this.currentQuiz.type === 'short_answer' ) {
          // todo
          if( this.currentQuiz.picked_answer ) {
            this.currentQuiz.answers.map((ans)=>{
              if( this.currentQuiz.picked_answer == ans )correct = true
            })
          }
        } else {
          correct = true
        }
        this.quizs.map((item)=>{
          if(item.id === this.currentQuiz.id) {
            item.picked_answer = this.currentQuiz.picked_answer
          }
          return item
        })

        if( correct ) {
          this.quizs = this.quizs.map((quiz)=>{
            if( quiz.id == this.currentQuiz.id )return this.currentQuiz
            else return quiz
          })
          this.showQuiz = false
          this.currentQuiz = null
          this.player.play()
        } else {
          //alert('คำตอบไม่ถูกต้อง กรุณาเลือกคำตอบใหม่')
          Swal.fire({
            title: 'คำตอบไม่ถูกต้อง',
            text: 'กรุณาเลือกคำตอบใหม่',
            icon: 'error',
            confirmButtonText: 'ตกลง',
            target: '#quiz-overlay'
          })
        }
      } else {
        // tody
        //alert('กรุณาตอบคำถาม')
        Swal.fire({
          title: 'กรุณาตอบคำถาม',
          icon: 'warning',
          confirmButtonText: 'ตกลง',
          target: '#quiz-overlay'
        })
      }
    },
    isCorrect() {
      let result = false
      return result
    },
    gotoNext() {
      this.$emit('done',true);
    },
    replay() {
      this.player.currentTime(0)
      this.player.play()
      this.ended = false
    }
  }
}
</script>

<style lang="scss">
.vjs-marker.custom-marker  {
  width: 24px;
  height: 24px;
  top: -11px;
  background: url(~@/assets/images/quiz-marker.png) center center;
  background-size: contain;
}
.video-player-wrapper {
  position: relative;
  #ended-overlay {
    color: $color-text;
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 1.4em;
    
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    padding: 10px 20px;
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    &>div {
      margin: 0 auto;
      text-align: center;
    }
  }

  .quiz-overlay {
    color: $color-text;
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 1.4em;
    
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    padding: 10px 20px;
    display: flex;
    justify-items: center;
  }
  .quiz-body {
    flex: 1;
    background: rgba(255,255,255,0.9);
    padding: 20px 40px;
    border-radius: $border-radius-small;
    max-width: 800px;
    margin: auto;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
  }
  .question-bottom .btn {
    background: $color-primary;
    color: #fff;
  }

  .quiz-wrapper {
    min-height: 0;
    padding-bottom: 80px !important;

    hgroup {
      margin-bottom: 10px;
    }
  }
  .question-bottom button {
    line-height: 2em;
  }
  .question-bottom button:hover,
  .question-bottom button:active,
  .question-bottom button:focus {
    background: $color-secondary;
  } 

  .swal2-styled:focus {
    background-color: $color-primary !important;
  }
  .swal2-popup {
    background-color: #fff !important;
  }
}
</style>