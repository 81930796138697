<template>
  <header id="main-header">
    <div id="top-header">
      <div class="header-content container">
        <div id="top-logo">
          <router-link to="/"><strong>{{$t('peer_to_peer_project')}}</strong> <span>{{$t('raksthai_foundation')}}</span></router-link>
        </div>
        <nav id="top-nav">
          <template v-if="isAuth">
            <router-link to="/">{{$t('courses')}}</router-link>
            <div>
              <div id="dropdown-wrapper" @click="userDropdown">
                <div class="dropdown-profile-pic">
                  <img v-if="profile_pic" :src="profile_pic" />
                  <svg v-else xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="7" r="4" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg>
                </div>
                <transition name="slide-fade">
                  <ul v-show="dropdown_user">
                    <li><router-link to="/profile">{{$t('member_profile')}}</router-link></li>
                    <li><router-link to="/profile_edit">{{$t('edit_profile')}}</router-link></li>
                    <li><a @click="logout">{{$t('logout')}}</a></li>
                  </ul>
                </transition>
              </div>
            </div>
          </template>
          <div class="top-lang-nav" v-else>
            <span @click="$i18n.locale='th'" v-if="$i18n.locale=='en'">ภาษาไทย</span>
            <span @click="$i18n.locale='en'" v-if="$i18n.locale=='th'">English</span>
          </div>
        </nav>
      </div>
    </div>
  </header>  
  <template v-if="isAuth">
    <mini-profile :show-badge="showBadges"/>
  </template>
</template>

<script>
import { userService } from '@/services/user';
import MiniProfile from '@/components/MiniProfile.vue'
export default {
  components: {
    MiniProfile
  },
  data() {
    return { 
      dropdown_user: false
    }
  },
  mounted() {
    this.$i18n.locale='th'
  },
  name: "Header",
  methods: {
    logout(event) {
      userService.logout()
      this.$store.dispatch('user/logout')
      this.$router.push('login')
      setTimeout(()=>{window.location.reload()},100)
    },
    userDropdown() {
      this.dropdown_user = !this.dropdown_user
    }
  },
  computed: {
    isAuth() {
      return this.$store.state.user.loggedIn
    },
    showBadges() {
      return this.$route.name==='Home' || this.$route.name==='Profile'
    },
    profile_pic() {
      if( this.$store.state.user.profile ) {
        return this.$store.state.user.profile.profile_pic;
      } else {
        return null;
      }
    }
  }
}
</script>

<style lang="scss">
#top-header {
  background: $color-primary;
  color: $color-primary-tint;
  padding: 13px 0;
  line-height: 22px;
  box-shadow: $shadow-small;
  position: relative;
  z-index: 100;
  font-family: $font-family-heading;

  a {
    color: $color-primary-tint;
  }

  .header-content {
    display:flex;
    justify-content: space-between;
  }

  @media only screen and (max-width: 768px) {
    .header-content {
      flex-direction: column;
    }
  }
}
#top-logo {
  font-size: 1em;
  strong {
    font-size: 1.15em;
    padding: 0 10px 0 0;
    border-right: 1px solid rgba(255,255,255,0.7);
  }
  @media only screen and (max-width: 768px) {
    font-size: 0.9em;
    margin: 0 0 10px;
    text-align: center;
  }
}
#top-nav {
  display: flex;
  align-content: center;
  padding: 0;
  margin: 0;
  line-height: 22px;

  @media only screen and (max-width: 768px) {
    border-top: 1px solid rgba(255,255,255,0.7);
    padding-top: 10px;
    justify-content: center;
  }

  a {
    color: $color-primary-tint;
    border-right: 1px solid rgba(255,255,255,0.7);
    padding: 0 20px;
    display: block;
    font-weight: bold;
    font-size: 0.85em;

    @media only screen and (max-width: 768px) {
      padding: 0 20px;
      border: none;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }

  .nav-image {
    line-height: 0;
  }
  .dropdown-profile-pic {
    margin-top: -3px;
    width: 26px;
    height: 26px;
    border-radius: 26px;
    box-shadow: $shadow-small;
    overflow: hidden;
    background: #eee;
    margin-left: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      min-width: 100%;
    }
  }
}
.top-lang-nav {
  padding: 0 0 0 20px;
  font-size: 0.9em;
  span {
    cursor: pointer;
  }
}

#dropdown-wrapper {
  position: relative;
  line-height: 0;

  > ul {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    background: #fff;
    width: 130px;
    color: $color-primary;
    right: -15px;
    top: 35px;
    border-radius: 10px;
    box-shadow: $shadow-big;
    text-align:center;
    line-height: 1.6em;
    z-index: 9999;

    a {
      color: $color-primary;
      padding: 3px 0;
      margin: 5px;
      border-radius: 5px;
      z-index: 999;
      position: relative;
      cursor: pointer;
      &:hover {
        background: #f1f1f1;
      }
    }
    &:after {
      bottom: 100%;
      right: 20px;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #ffffff;
      border-width: 8px;
      margin-left: -8px;
    }
    &:before {
      content:"";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      display: block;
    }
  }
}
</style>
