import axios from 'axios'
import authHeader from '@/helpers/auth_header'
import unauthorized from '@/helpers/unauthorized'
import badge_event from '@/helpers/badge_event'
import { reactive } from 'vue';
import store from '@/store'


const REST_ENDPOINT = process.env.VUE_APP_API_SERVER

export const courseService = {
  enroll,
  course,
  content,
  done,
  comment,
  uploadHomework,
  save_note,
  downloadCert,
  downloadAttachment
};

function course (id) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}course/${id}`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        }
      }
    })
  })
}

function content (course_id,content_id) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}course/${course_id}/content/${content_id}`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function enroll (id,data) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}course/enroll/${id}`,
      method: 'POST',
      data: data,
      headers: authHeader()
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function done (course_id,content_id,data) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}course/${course_id}/content/${content_id}`,
      method: 'POST',
      data: data,
      headers: authHeader()
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function save_note (course_id,chapter_id,data) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}course/${course_id}/chapters/${chapter_id}/note`,
      method: 'POST',
      data: data,
      headers: authHeader()
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function comment (course_id,chapter_id,data) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}course/${course_id}/chapters/${chapter_id}/comment`,
      method: 'POST',
      data: data,
      headers: authHeader()
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function uploadHomework (course_id, content_id, data) {
  let header = {...{"Content-Type": "multipart/form-data"},...authHeader()}
  return new Promise((resolve, reject) => {
    axios({
      url: REST_ENDPOINT+'course/'+course_id+'/content/'+content_id+'/assignment',
      method: 'POST',
      headers: header,
      data: data
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown'])
      }
    })
  })
}

function downloadAttachment (course_id,chapter_id,attachment_id) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}course/${course_id}/chapters/${chapter_id}/attachments/${attachment_id}`,
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + store.getters['user/token'], "accept": "application/x-www-form-urlencoded"},
      responseType: 'arraybuffer'
    }).then((res) => {
      //badge_event(res.data)
      //resolve(res.data)
      let blob = new Blob([res.data], { type: 'application/pdf' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Attachment_'+attachment_id+'.pdf'
      link.click()
      resolve({'success':1})
    }).catch(function (error) {
      if(error && error.response && error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function downloadCert (course_id,content_id) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}course/${course_id}/content/${content_id}/certification`,
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + store.getters['user/token'], "accept": "application/x-www-form-urlencoded"},
      responseType: 'arraybuffer'
    }).then((res) => {
      let blob = new Blob([res.data], { type: 'application/pdf' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Certificate_'+content_id+'.pdf'
      link.click()
      resolve({'success':1})
    }).catch(function (error) {
      if(error && error.response && error.response.status === 401) {
        unauthorized()
      } else if(error && error.response) {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      } else {
        reject(['Unknown'])
      }
    })
  })
}