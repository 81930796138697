import axios from 'axios'
import authHeader from '@/helpers/auth_header'
import unauthorized from '@/helpers/unauthorized'
import badge_event from '@/helpers/badge_event'

const REST_ENDPOINT = process.env.VUE_APP_API_SERVER
const loginParameters = {"grant_type": "password","client_id" : "1","client_secret" : "fJ1iMb9Pj4knACKRKbcFdcGmTxhKFsQO5LT2VNeJ","scope": ""} 

export const userService = {
  login,
  logout,
  register,
  forgot,
  profile,
  organizations,
  uploadAvatar,
  form,
  edit,
  fblogin,
  fbregister
};

function organizations (data) {
  return new Promise((resolve, reject) => {
    data = { 
      ...data, 
      ...loginParameters
    }
    axios({
      url: `${REST_ENDPOINT}organizations`,
      method: 'GET',
      headers: {'accept': 'application/json'}
    }).then((res) => {
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown'])
      }
    })
  })
}

function register (data) {
  return new Promise((resolve, reject) => {
    data = { 
      ...data, 
      ...loginParameters
    }
    axios({
      url: `${REST_ENDPOINT}register`,
      method: 'POST',
      data: data,
      headers: {'accept': 'application/json'}
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown'])
      }
    })
  })
}

function login (data) {
  return new Promise((resolve, reject) => {
    data = { 
      ...data, 
      ...loginParameters
    }
    axios({
      url: `${REST_ENDPOINT}login`,
      method: 'POST',
      data: data,
      headers: {'accept': 'application/json'}
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown'])
      }
    })
  })
}

function logout () {
  axios({
    url: `${REST_ENDPOINT}logout`,
    method: 'GET',
    headers: authHeader()
  })
}

function profile () {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}profile/mini`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function uploadAvatar (data) {
  let header = {...{"Content-Type": "multipart/form-data"},...authHeader()}
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}media/avatar`,
      method: 'POST',
      headers: header,
      data: data
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown'])
      }
    })
  })
}


function form () {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}profile/edit`,
      method: 'GET',
      headers: authHeader()
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      if(error.response.status === 401) {
        unauthorized()
      } else {
        const data = error.response.data
        if(data && data.errors && data.errors.length > 0) {
          reject(data.errors)
        } else {
          reject(['Unknown'])
        }
      }
    })
  })
}

function edit (data) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${REST_ENDPOINT}profile/edit`,
      method: 'POST',
      headers: authHeader(),
      data: data
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown'])
      }
    })
  })
}


function fblogin (data) {
  return new Promise((resolve, reject) => {
    data = { 
      ...data
    }
    axios({
      url: `${REST_ENDPOINT}facebook-login`,
      method: 'POST',
      data: data,
      headers: {'accept': 'application/json'}
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown'])
      }
    })
  })
}

function fbregister (data) {
  return new Promise((resolve, reject) => {
    data = { 
      ...data
    }
    axios({
      url: `${REST_ENDPOINT}facebook-register`,
      method: 'POST',
      data: data,
      headers: {'accept': 'application/json'}
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown'])
      }
    })
  })
}

function forgot (data) {
  return new Promise((resolve, reject) => {
    data = { 
      ...data
    }
    axios({
      url: `${REST_ENDPOINT}forgot-password`,
      method: 'POST',
      data: data,
      headers: {'accept': 'application/json'}
    }).then((res) => {
      badge_event(res.data)
      resolve(res.data)
    }).catch(function (error) {
      const data = error.response.data
      if(data && data.errors && data.errors.length > 0) {
        reject(data.errors)
      } else {
        reject(['Unknown'])
      }
    })
  })
}