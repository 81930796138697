<template>
  <div class="video-player-wrapper" @mouseover="videoHover" @mouseleave="videoLeave">
    <img v-if="autoplay===false && playing===false" :src="thumbnail"/>
    <video v-if="video" ref="videoPlayer" class="video-js vjs-16-9 vjs-fill vjs-theme-fantasy"></video>
  </div>
</template>

<script>
import videojs from 'video.js'

export default {
  name: 'ThumbnailVideo',
  props: {
    video: {
      type: String,
      default: ""
    },
    thumbnail: {
      type: String,
      default: ""
    },
    clickstop: {
      type: Boolean,
      default: false
    },
    hoverstop: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      player: null,
      hover: false,
      playing: false
    }
  },
  mounted () {
    if(this.video) {
      let options = {
        sources: [{src:this.video}],
        autoplay: this.autoplay,
        controls: false,
        muted: true,
        responsive: true,
        fluid: true,
        loop: true,
        fill: true
      }
      this.player = videojs(this.$refs.videoPlayer, options, function onPlayerReady () {})
      this.player.on('play',()=>{
        this.playing = true;
      })
      this.player.on(['pause','stop'],()=>{
        this.playing = false;
      })
      if(this.clickstop){
        this.player.on('click',(event)=>{
          if(this.player.paused()){
            this.player.play()
          } else {
            this.player.pause()
          }
        })
      }
    }
  },
  beforeUnmount () {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    videoHover() {
      if(!this.hoverstop || !this.player)return;
      if(this.player.paused()){
        this.player.play()
      }
    },
    videoLeave() {
      if(!this.hoverstop || !this.player)return;
      if(!this.player.paused()){
        this.player.pause()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vjs-big-play-button {
  display: none !important;
}
.video-player-wrapper {
}
.video-js.vjs-paused {
  position: relative;
  &:after {
    pointer-events: none;
    content: "";
    position: absolute;
    display: block;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    margin: -60px 0 0 -50px;
    border: 2px solid #fff;
    border-radius: 100px;
    background: transparent url(~@/assets/images/icon-play-big.svg) no-repeat center center;
    opacity: 0.9;
  }
}
.video-player-wrapper {
  position: relative;
  &>img {
    z-index:9;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>