<template>
  <div v-if="content" class="content-display-wrapper">
    <template v-if="content.infos.type_handle==='video'">
      <lesson-video :content="content" @done="doneLesson"  />
    </template>
    <template v-else-if="content.infos.type_handle==='quiz'">
      <lesson-quiz :content="content" @done="doneLesson"/>
    </template>
    <template v-else-if="content.infos.type_handle==='article'">
      <lesson-article :content="content" @done="doneLesson"/>
    </template>
    <template v-else-if="content.infos.type_handle==='assignment'">
      <lesson-homework :content="content" @done="doneLesson"/>
    </template>
    <template v-else-if="content.infos.type_handle==='game'">
      <lesson-game :content="content" @done="doneLesson"/>
    </template>
    <template v-else-if="content.infos.type_handle==='certification'">
      <lesson-certificate :content="content" @done="doneLesson"/>
    </template>

    <div class="content-display-bottom" v-if="showBottom">
      <div class="content-display-title">
        <h1>{{content.infos.title}}</h1>
        <p>{{content.infos.content}}</p>
      </div>
      <div class="content-display-button">
        <button :disabled="!skippable" class="btn-subtle" @click="completedLesson">บทเรียนต่อไป &rarr;</button>
      </div>
    </div>
  </div>
</template>

<script>
import LessonVideo from '@/components/lesson/Video.vue'
import LessonQuiz from '@/components/lesson/Quiz.vue'
import LessonArticle from '@/components/lesson/Article.vue'
import LessonHomework from '@/components/lesson/Homework.vue'
import LessonGame from '@/components/lesson/Game.vue'
import LessonCertificate from '@/components/lesson/Certificate.vue'
import { courseService } from '@/services/course';
import moment from 'moment'

export default {
  name: 'ContentDisplay',
  components: {
    LessonVideo,
    LessonQuiz,
    LessonArticle,
    LessonHomework,
    LessonGame,
    LessonCertificate
  },
  props: {
    content: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      options: {},
      done: false
    }
  },
  computed: {
    skippable() {
      let result = false
      if(this.content.infos.is_completed || !this.content.infos.is_required || this.done ) {
        result = true
      } 
      return result
    },
    showBottom() {
      return this.content.infos.type_handle !== 'quiz' && this.content.infos.type_handle !== 'certification'
    }
  }, 
  mounted () {
    this.started_at = moment()
  },
  methods: {
    completedLesson () {
      let data = {}
      data.started_at = this.started_at.format('YYYY-MM-DD hh:mm')
      data.finished_at = moment().format('YYYY-MM-DD hh:mm')
      courseService.done(this.$route.params.id,this.$route.params.content_id, data).then((res)=>{
        this.loading = false
        if(res.next_content_id) {
          this.$router.push('/course/'+this.$route.params.id+'/'+res.next_content_id)
        }
      },(res)=>{
        this.loading = false
      })
    },
    doneLesson(next_content) {
      this.done = true
      if(!next_content)return;
      if(next_content===true) {
        this.completedLesson()
      } else if(Number.isInteger(next_content)) {
        this.$router.push('/course/'+this.$route.params.id+'/'+next_content)
      }
    }
  },
  beforeUnmount () {
  }
}
</script>

<style lang="scss" scoped>
.content-display-wrapper {
  background: #fff;
}
.content-display-bottom {
  border-top: 1px solid #ddd;
  display: flex;
  padding: 10px 20px 5px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  .content-display-title {
    flex: 1;
    font-size: 0.9em;
    h1 {
      font-size: 1.2rem;
      color: $color-primary;
      margin: 0 0 5px;
    }
  }
  .content-display-button {
    width: 200px;
    margin-left: 20px;
    text-align: right;
    padding: 5px 0 0 0;
    @media only screen and (max-width: 567px) {
      width: 100%;
      margin: 0;
      text-align: center;

      .btn-subtle {
        padding: 10px 40px;
      }
    }
  }
}
</style>