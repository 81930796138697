<template>
<div class="background-video-wrapper">
  <video ref="backgroundVideo" class="video-js vjs-16-9 vjs-fill vjs-theme-fantasy"></video>
</div>
</template>

<script>
import videojs from 'video.js'

export default {
  name: 'BackgroundVideo',
  props: {
    video: {
      type: String,
      default: ""
    },
    thumbnail: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      player: null
    }
  },
  mounted () {
    let options = {
      sources: [{src:this.video}],
      autoplay: true,
      controls: false,
      muted: true,
      responsive: true,
      fluid: true,
      loop: true,
      fill: true
    }
    this.player = videojs(this.$refs.backgroundVideo, options, function onPlayerReady () {})
  },
  beforeUnmount () {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
.background-video-wrapper {
  background: red;
  width: 100%;
  height: 100%;
  pointer-events: none;
  .video-js {
    width: 100%;
    height: 100%;
    padding: 0 !important;
    max-width: none;
    max-height: none;
  }
  .vjs-tech { object-fit: cover; }
}

</style>