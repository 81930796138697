<template>
  <div class="main-content loading-area">
    <div class="header-back-nav"><router-link to="/">&larr; กลับไปก่อนหน้า</router-link></div>
    <div class="news-card" v-if="item">
      <div class="news-card-content">
        <div class="news-card-content-meta">
          <span class="news-card-content-meta-type">ข่าวสารจากโครงการ</span>
        </div>
        <div class="news-card-content-header">
          <h1>{{item.title}}</h1>
          <h2 v-if="item.author">โดย <strong>{{item.author}}</strong></h2>
        </div>
        <div class="news-card-content-detail">
          <div v-html="item.content"></div>
        </div>
      </div>
    </div>
    <LoadingSpinner v-if="loading"/>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { etcService } from '@/services/etc';
export default ({
  components: {
    LoadingSpinner
  },
  data() {
    return { 
      loading: false,
      item: null
    }
  },
  created() {
    this.loading = true
    etcService.news(this.$route.params.id).then((res)=>{
      this.loading = false
      this.item = res
    },(res)=>{
      this.loading = false
    })
  },
  methods: {
  },
  computed: {
  }
})
</script>

<style lang="scss" >
.news-card {

}
.news-card-content-meta-type {
  font-size: 0.8em;
  color: $color-gray;
  float:left;

  &:after {
    content:"";
    display: block;
    width: 20px;
    height: 3px;
    background: $color-secondary;
    margin: 10px 0;
  }
}
.news-card-content-meta-like {
  float: right;
  border-radius: 20px;
  font-size: 0.9em;
  line-height: 18px;
  padding: 3px 10px 3px 32px;
  border: 1px solid $color-secondary;
  color: $color-secondary;
  font-weight: normal;

  background: url(~@/assets/images/icon-heart-red.svg) no-repeat 10px center;
  background-size: 15px;
}
.news-card-content-header {
  clear: both;

  h1 {
    color: $color-primary;
    font-size: 1.6em;
    margin: 0 0 8px;
  }
  h2 {
    color: $color-gray;
    font-size: 1.3em;
    font-weight: normal;
    margin: 0 0 20px;
  }
}
.news-card-content-detail {
  img {
    max-width: 100%;
    height: auto !important;
  }
}
</style>
