const state = {
  active_chat: null,
  receiver_id: null,
  show: false
}

const actions = {
  setActiveChat(context, active_chat) {
    context.commit('show',true)
    context.commit('active_chat',active_chat)
  },
  clearActiveChat(context) {
    context.commit('active_chat',null)
  },
  showMessage(context, show) {
    context.commit('show',show)
  },
  hideMessage(context) {
    context.commit('show',null)
  },
  sendMessage(context, receiver_id) {
    context.commit('show',true)
    context.commit('receiver_id',receiver_id)
  }
}

const mutations = {
  active_chat(state,active_chat) {
    state.active_chat = active_chat
  },
  show(state,show) {
    state.show = show
  },
  receiver_id(state,receiver_id){
    state.receiver_id = receiver_id
  }
}

const getters = {
  getActiveChat(state) {
    return state.active_chat;
  },
  getShow(state) {
    return state.show;
  },
  getReveiverId(state) {
    return state.receiver_id;
  }
}

export const message = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}