<template>
  <teleport to="body">
    <div class="modal-wrapper" @click.self="hide">
      <div class="modal-container" :class="size">
        <div class="modal-close" @click="hide">&#10005;</div>
        <div class="modal-content"><slot></slot></div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  emits: ['close'],
  props: {
    size: {
      type: String,
      default: 'medium'
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    hide() {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss">
</style>