<template>
  <div class="main-content loading-area">
    <transition name="slide-fade">
    <div v-if="user_info && provinces">
      <section>
        <div class="card-container">
          <hgroup>
            <span>ข้อมูลส่วนตัว</span>
            <h1>แก้ไขข้อมูล</h1>
          </hgroup>
          <form @submit.prevent="doSubmit">
            <div class="row">
              <styled-input type="text" v-model="user_info.display_name" placeholder="ชื่อที่แสดง" required/>
              <styled-input type="email" v-model="user_info.email" placeholder="อีเมลล์" required/>
            </div>
            <div class="row">
              <styled-input type="text" v-model="user_info.fullname" placeholder="ชื่อ-นามสกุลจริง" required/>
            </div>
            <div class="row">
              <styled-input type="number" class="phone-number" v-model="user_info.tel" placeholder="เบอร์โทร" required/>
              <div v-if="provinces">
                <Multiselect :searchable="true" v-model="user_info.province_id" :options="selectProvinces" />
              </div>
            </div>
            <div class="row">
              <div>
                <styled-textarea v-model="user_info.address" title="ที่อยู่"></styled-textarea>
              </div>
            </div>
            <div class="form-bottom row">
              <div class="text-right"><button type="submit" class="btn-primary">บันทึกข้อมูล</button></div>
            </div>
          </form>
          <button @click="$router.go(-1)">&larr; กลับไปก่อนหน้า</button>
        </div>
      </section>
    </div>
    </transition>
    <loading-spinner v-if="loading"/>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { userService } from '@/services/user';
import StyledInput from '@/components/Styled/Input.vue'
import StyledTextarea from '@/components/Styled/Textarea.vue'
import Multiselect from '@vueform/multiselect'

export default ({
  components: {
    LoadingSpinner,
    StyledInput,
    StyledTextarea,
    Multiselect
  },
  data() {
    return { 
      loading: true,
      user_info: null,
      provinces: null
    }
  },
  created() {
    userService.form().then((res)=>{
      this.loading = false
      this.user_info = res.user_info
      this.provinces = res.provinces
    },(res)=>{
      this.loading = false
    })
  },
  methods: {
    doSubmit() {
      //todo
      this.loading = true
      userService.edit(this.user_info).then((res)=>{
        this.loading = false
        this.$router.push('profile')
      },(res)=>{
        this.loading = false
      })
    }
  },
  computed: {
    selectProvinces() {
      let options = []
      if(this.provinces) {
        this.provinces.map((province)=>{
          options.push({value:province.id,label:province.name_th})
        })
      }
      return options
    }
  }
})
</script>

<style lang="scss" scoped>
.card-container {
  @extend %box;
  max-width: 800px;
  margin: 0 auto;
}
.row {
  display: flex;

  &>div {
    flex: 1;
    margin: 10px;
  }
}
</style>
