<template>
  <div id="page-body">
    <div id="page-body-content">
      <Header/>
      <router-view :key="$route.fullPath"/>
    </div>
    <div id="page-body-footer">
      <Footer/>
    </div> 
    <badge-event/>
    <messages/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import BadgeEvent from "@/components/BadgeEvent";
import Messages from "@/components/Messages";
export default {
  name: "App",
  components: {
    Header,Footer,BadgeEvent,Messages
  }
};
</script>

<style lang="scss" scoped>
#page-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>