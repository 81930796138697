<template>
  <div class="styled-textarea">
    <label>{{title}}</label>
    <textarea v-bind="$attrs" :rows="rows?rows:5" @input="$emit('update:modelValue', $event.target.value)" :value="modelValue"></textarea>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: ['modelValue','title','rows']
}
</script>

<style lang="scss" scoped>
.styled-textarea {
  margin: 0 0 10px;
}
</style>
