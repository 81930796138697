const state = {
  badges: null
}

const actions = {
  setBadges(context, badges) {
    context.commit('badges',badges)
  },
  clearBadges(context) {
    context.commit('badges',null)
  }
}

const mutations = {
  badges(state,badges) {
    state.badges = badges
  }
}

const getters = {
  getBadges(state) {
    return state.badges;
  }
}

export const event = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}