<template>
  <div class="homework-wrapper">
      <hgroup>
        <span>การบ้าน</span>
        <h2>{{content.infos.title}}</h2>
      </hgroup>
      <div class="homeword-content">
        {{content.infos.content}}
        <template v-if="!result">
          <file-upload title="คลิกหรือลากไฟล์เข้ามาเพื่ออัพโหลดส่งการบ้าน" @change="doUpload"/>
        </template>
        <div class="uploaded" v-else>
          <div v-if="result.is_graded">
            <p>
            <strong v-if="result.is_passed===true">
              คะแนนผ่านเกณฑ์
            </strong>
            <strong v-else-if="result.is_passed===false">
              คะแนนไม่ผ่านเกณฑ์
            </strong>
            </p>
            <p><small>คะแนนที่ได้: {{result.score}}/{{result.total_score}}</small></p>
            <p>
              {{result.comment}}
            </p>
          </div>
          <p v-else>
            <strong>ได้รับไฟล์การบ้านแล้ว กรุณารอการตรวจ</strong>
          </p>
          

          <button v-if="!result.is_graded" class="btn" @click="reUpload">อัพโหลดไฟล์อีกครั้ง  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-rotate" width="20" height="20" viewBox="0 0 24 24" stroke-width="2"  fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M19.95 11a8 8 0 1 0 -.5 4m.5 5v-5h-5" /></svg></button>
          &nbsp;
          <a class="btn" :href="result.file" target="_blank">ดูไฟล์ที่อัพโหลด <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-external-link" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" /><line x1="10" y1="14" x2="20" y2="4" /><polyline points="15 4 20 4 20 9" /></svg></a>
          <br/><br/>
          <button class="btn btn-lg btn-primary" @click="gotoNext">ไปยังบทเรียนต่อไป  &rarr;</button>
        </div>
      </div>
      <LoadingSpinner v-if="loading"/>
  </div>
</template>
<script>

import FileUpload from '@/components/FileUpload.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { courseService } from '@/services/course';
export default {
  name: 'LessonHomework',
  props: ['content'],
  emits: ['done'],
  components: {
    FileUpload,
    LoadingSpinner
  },
  data () {
    return {
      loading: false,
      uploaded: null,
      result: null
    }
  },
  mounted () {
    if(this.content && this.content.result && this.content.result.file) {
      this.result = this.content.result
    }
  },
  beforeUnmount () {
  },
  methods: {
    reUpload() {
      this.result = null
    },
    doUpload(data) {
      this.loading = true
      courseService.uploadHomework( this.content.infos.course_id, this.content.infos.id, data )
        .then((res)=>{
          console.log(res)
          this.loading = false
          this.result = res
        },(res)=>{
          alert('ไม่สามารถอัพโหลดได้')
          this.loading = false
        })
    },
    gotoNext() {
      this.$emit('done',true)
    }
  }
}
</script>

<style lang="scss" scoped>
.homework-wrapper {
  min-height: 400px;
  background: #fff;
  padding: 20px;
  position: relative;

  .homeword-content {
    font-size: 1.2em;
    padding:0 20px;
  }

  .uploaded {
    margin: 20px 0;
    text-align: center;
    background: #f1f1f1;
    border-radius: 20px;
    padding: 30px 10px;
  }
}
</style>