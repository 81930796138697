<template>
  <div class="styled-select">
    <select v-bind="$attrs" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
      <slot></slot>
    </select>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: ['modelValue']
}
</script>

<style lang="scss" scoped>
.styled-select {
  position: relative;
  margin: 0 0 10px;

  select {
    display:inline-block;
    width: 100%;
    background: #fff;
    appearance: none;
    padding: 7px 10px 3px;
    font-size: 0.9rem;
    line-height: 2em;
  }

  &::after {
    content: "";
    position: absolute;
    right: 12px;
    top: 15px;
    z-index: 11;
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #999;
    border-left: 2px solid #999;
  }
  &::after {
    top: 10px;
    transform: rotate(225deg);
  }
}
</style>
