<template>
  <div class="article-wrapper">
      <hgroup>
        <span>บทความ</span>
        <h2>{{content.infos.title}}</h2>
      </hgroup>
      <div v-html="content.article"></div>
  </div>
</template>

<script>

export default {
  name: 'LessonArticle',
  props: ['content'],
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  beforeUnmount () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.article-wrapper {
  min-height: 400px;
  background: #fff;
  padding: 20px 40px;
}
</style>