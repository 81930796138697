<template>
  <div class="quiz-wrapper">
    <div class="quiz-body">
      <hgroup>
        <span>แบบทดสอบ <i class="fa fa-user"></i></span>
        <h2>{{content.infos.title}}</h2>
      </hgroup>
      <div v-if="submitted" class="quiz-result">
        <p>ส่งแบบทดสอบเรียบร้อยแล้ว</p>
        <div v-if="result.is_graded===true">
          <div v-if="result.is_passed" class="quiz-passed">
            ผลการทดสอบ
          </div>
          <div v-else class="quiz-failed">
            ผลการทดสอบ
          </div>
          <div v-if="result.score" class="pie" :class="'p'+percentScore"></div>
          <div class="quiz-score">
            คะแนน {{result.score}} / {{result.total_score}}
          </div>
        </div>
        <div v-else>
          <h2>รอการตรวจและให้คะแนน</h2>
        </div>

        <div class="question-bottom">
          <button v-if="content.is_allow_retake" type="button" class="btn btn-subtle" @click="clearQuiz"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-rotate" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M19.95 11a8 8 0 1 0 -.5 4m.5 5v-5h-5" /></svg> ทำ<span>แบบทดสอบ</span>อีกครั้ง</button>
          <button v-if="canGoNext" @click="nextLesson" type="button" class="btn btn-primary"><span>ไปยัง</span>บทเรียนถัดไป &rarr;</button>
        </div>
      </div>
      <template v-else>
      <div class="quiz-timer" v-if="remain_seconds">
        เหลือเวลา {{showRemainsTime}}
      </div>
      <form class="quiz-form" @submit.prevent="submitQuiz" v-if="questions">
        <div class="questions">
          <div class="question" v-for="(question,index) in questions" :key="question.id" v-show="current_question==index">
            <div class="question-title">{{index+1}}. {{question.title}}</div>
            <lesson-question :question="question" v-model="question.user_answer" :key="question.id" :index="index"/>
          </div><!-- .question -->
        </div><!-- .questions -->

        <div v-if="show_final" >
          <div class="summery-questions">
            <div class="summery-question" v-for="(question,index) in questions" :key="question.id" :class="{'has-answer':hasAnswer(question.id)}">
              <div @click="showQuestion(index)">{{index+1}}. {{question.title}}</div>
            </div>
          </div>
          <div class="question-bottom">
            <div class="question-bottom-center">
              <button class="btn btn-primary" type="submit">ยืนยันและส่งคำตอบ</button>
            </div>
          </div>
        </div>
        <div v-else class="question-bottom">
          <div>
            <button class="btn btn-subtle" type="button" @click="prevQuestion">&larr; ก่อนหน้า</button>
          </div>
          <div>
            <button class="btn btn-subtle" type="button" @click="lastQuestion">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-list" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <line x1="9" y1="6" x2="20" y2="6" />
              <line x1="9" y1="12" x2="20" y2="12" />
              <line x1="9" y1="18" x2="20" y2="18" />
              <line x1="5" y1="6" x2="5" y2="6.01" />
              <line x1="5" y1="12" x2="5" y2="12.01" />
              <line x1="5" y1="18" x2="5" y2="18.01" />
            </svg>&nbsp;
            <span>รายการคำถาม</span></button>
          </div>
          <div>
            <button class="btn btn-primary" type="button" @click="nextQuestion">ถัดไป &rarr;</button>
          </div>
        </div>
      </form>
      </template>

    </div>
    <LoadingSpinner v-if="loading"/>

  </div>
</template>

<script>
import { courseService } from '@/services/course';
import LessonQuestion from '@/components/lesson/Question.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import moment from 'moment'
export default {
  name: 'LessonQuiz',
  components: {
    LoadingSpinner,
    LessonQuestion
  },
  props: ['content'],
  emits: ['done'],
  data () {
    return {
      submitted: false,
      result: {},
      loading: false,
      questions: null,
      current_question: 0,
      show_final: false,
      started_at: null,
      remain_seconds: null,
      timeint: null
    }
  },
  watch: {
  },
  mounted () {
    this.questions = this.content.questions
    if(this.content.result) {
      this.result = this.content.result
      this.submitted = true
    }
    this.started_at = moment()

    if( this.content.infos.duration && this.content.infos.duration != "00:00:00" ) {
      this.remain_seconds = this.hmsToSeconds(this.content.infos.duration)
      this.timeint = setInterval(()=>{
        if(this.remain_seconds===0) {
          clearInterval(this.timeint)
          this.submitQuiz()
        } else {
          this.remain_seconds--;
        }
      },1000)
    }
    
  },
  computed: {
    canGoNext() {
      if( this.submitted && this.result.is_graded && this.result.is_passed && this.result.next_content_id ) {
        return true
      } else {
        return false
      }
    },
    percentScore() {
      let result = 0;
      if(this.result) {
        result = (this.result.score / this.result.total_score) * 100
        result = result.toFixed(0)
      }
      return result
    },
    showRemainsTime() {
      return this.secondsToHms(this.remain_seconds)
    }
  },
  methods: {
    nextLesson(){
      this.$emit('done',this.result.next_content_id);
    },
    prevQuestion() {
      if(this.current_question > 0)
      {
        this.current_question--
      } else {
        this.show_final = false
      }
    },
    nextQuestion() {
      this.current_question++
      if(this.current_question === this.questions.length)
      {
        this.show_final = true
      } else {
        this.show_final = false
      }
    },
    lastQuestion() {
      this.current_question = this.questions.length
      this.show_final = true
    },
    showQuestion(index) {
      this.current_question = index
      this.show_final = false
    },
    clearQuiz() {
      let result = false
      this.questions = this.questions.map((question)=>{
        if(question.type=='matching') {
          result = false
          if(question.user_answer) {
            question.user_answer = question.user_answer.map((ans)=>{
              ans.value = null
              return ans
            })
          } else {
            question.user_answer = null
          }
        } else {
          question.user_answer = null
        }
        return question
      })
      this.started_at = moment()
      this.submitted = false
      this.current_question = 0
      this.show_final = false
    },
    hasAnswer(id) {
      let result = false
      this.questions.map((question)=>{
        if(question.id === id && question.user_answer ) {
          if(question.type=='matching') {
            result = false
            question.user_answer.map((ans)=>{
              if(ans.value)result = true
            })
          } else {
            result=true
          }
        }
      })
      return result
    },
    submitQuiz() {
      this.loading = true
      let data = {}
      data.answers = this.questions.map((question)=>{
        let result = {version_id: question.version_id}
        if(question.type==="multiple_choice") {
          result.choice_id = (question.user_answer)?question.user_answer:null
        } else if(question.type==="short_answer" || question.type==="assay") {
          result.value = (question.user_answer)?question.user_answer:null
        } else if(question.type==="multiple_selection") {
          result.choices = question.choices.map((choice)=>{
            let val = 0;
            if(question.user_answer)
            {
              question.user_answer.map((ans)=>{
                if(ans===choice.id)val=1
              })
            }
            return {
              choice_id: choice.id,
              value: val
            }
          });
        }  else if(question.type==="matching") {
          result.choices = question.user_answer.map((ans)=>{
            
            return {
              choice_id: ans.id,
              matching_choice_id: ans.value
            }
          })
        } 
        return result;
      })
      //console.log(data.answers)
      data.started_at = this.started_at.format('YYYY-MM-DD hh:mm')
      data.finished_at = moment().format('YYYY-MM-DD hh:mm')
      courseService.done(this.$route.params.id,this.$route.params.content_id, data).then((res)=>{
        this.loading = false
        if(res.success) {
          this.submitted = true;
          this.result = res
        }
      },(res)=>{
        this.loading = false
      })
    },
    hmsToSeconds(d) {
      var hms = d;
      var a = hms.split(':');
      var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
      return seconds
    },
    secondsToHms(d) {
      d = Number(d);

      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);

      return ('0' + h).slice(-2) + ":" + ('0' + m).slice(-2) + ":" + ('0' + s).slice(-2);
    }

  },
  unmounted() {
    if(this.timeint)clearInterval(this.timeint)
  }
}
</script>

<style lang="scss" scoped>
.quiz-wrapper {
  position: relative;
  min-height: 400px;
  background: #fff;
  padding: 20px 40px 80px;

  @media only screen and (max-width: 768px) {
    padding: 10px 20px 80px;
    border: none;
  }

  .quiz-body {
  }
  .question-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    button {
      font-weight: 500;
    }


    @media only screen and (max-width: 560px) {
      padding: 10px 15px;

      span {
        display: none
      }
    }
  }

  .question-bottom-center{
    flex: 1;
    text-align: center;
  }
  .quiz-timer {
    text-align:center;
    margin: -10px 0 20px;
  }
}
.summery-questions {
  border-top: 1px solid #eee;
  margin: 0 0 30px;
  .summery-question {
    display: block;
    border: 1px solid #eee;
    border-left: 5px solid $color-secondary;
    border-top: none;
    padding: 5px 10px;
    cursor: pointer;

    &.has-answer {
      border-left: 5px solid $color-success;
    }
  }
}
.quiz-result {
  text-align: center;
  font-size: 1.3em;
  line-height: 1.5em;
  
  .quiz-passed {
    color: $color-success;
    font-size: 1.5em;
    font-weight: bold;
    margin: 10px 0;
  }
  .quiz-failed {
    color: $color-alert;
    font-size: 1.5em;
    font-weight: bold;
  }
  .quiz-score {
    display: block;
    font-weight: bold;
    padding: 10px 0 0;
  }
  .pie {
    margin: 5px auto 0;
    zoom: 4;
  }
}
</style>