<template>
  <transition name="slide-fade">
  <div id="badge-event-wrapper" v-if="badges" @click="clear">
    <div class="new-badges-wrapper">
      <h1>ขอแสดงความยินดี<br/>คุณได้รับเหรียญตราใหม่!</h1>
      <div class="new-badges">
        <div class="new-badge" v-for="badge in badges" :key="badge.id">
          <div class="new-badge-display">
            <img :src="badge.thumbnail"/>
            <strong>{{badge.title}}</strong>
            <small>{{badge.content}}</small>
          </div>
        </div>
      </div>
      <div class="new-badges-button">
        <audio autoplay="true" src="/static/games/base_finder/correct.mp3"></audio>
        <button @click="clear">ตกลง</button>
      </div>
    </div>
  </div>
  </transition>
</template>

<script>
import { userService } from '@/services/user';
export default {
  components: {
  },
  data() {
    return { 
      
    }
  },
  name: "BadgeEvent",
  methods: {
    clear(event) {
      userService.profile().then((res)=>{
        this.loading = false
        this.$store.dispatch('user/profile',res)
      },(res)=>{
        this.loading = false
      })
      this.$store.dispatch('event/clearBadges')
    }
  },
  computed: {
    badges() {
      return this.$store.state.event.badges
    }
  }
}
</script>

<style lang="scss">
#badge-event-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.2);
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  padding: 100px;

  h1 {
    color: $color-primary;
    text-align: center;
    font-size: 2em;
    line-height: 1.3em;
  }
  .new-badges-wrapper {
    background: #fff;
    padding: 30px;
    border-radius: 40px;
    .new-badges {
      display:flex;
      justify-content: center;
    }
    .new-badge {
      margin: 30px;
      max-width: 250px;
      flex: 1;
      text-align: center;
      position: relative;
      strong, small {
        display: block;
        line-height: 1.4em;
      }
      img {
        width: 80px;
        height: 80px;
      }
      .new-badge-display {
        position: relative;
        
        animation-name: bounceInDown;
        animation-duration: 0.6s;
        animation-fill-mode: both;
      }
      &:nth-child(1) {
        .new-badge-display {
          animation-delay: 0.1s;
        }
      }
      &:nth-child(2) {
        .new-badge-display {
          animation-delay: 0.3s;
        }
      }
      &:nth-child(3) {
        .new-badge-display {
          animation-delay: 0.6s;
        }
      }
      &:nth-child(4) {
        .new-badge-display {
          animation-delay: 0.9s;
        }
      }
      &:nth-child(5) {
        .new-badge-display {
          animation-delay: 1.2s;
        }
      }
    }
    @keyframes bounceInDown {
      0% {
          opacity: 0;
          transform: translateY(-150px);
      }
      50% {
          opacity: 1;
          transform: translateY(20px);
      }
      75% {
          transform: translateY(-10px);
      }
      100% {
          transform: translateY(0);
      }
    } 
  }
  .new-badges-button {
    text-align: center;
  }
}
</style>
