<template>
  <div v-if="url" class="preview-profile">
    <div class="preview-profile-image"><img :src="url"/></div>
    <button @click="upload" class="btn-primary">อัพโหลดรูปภาพ</button>
  </div>
  <div v-else class="upload" @click="selectFile" @dragover="dragover" @dragleave="dragleave" @drop="drop">
    <input type="file" name="file"  @change="onFileChange" ref="file" :accept="type_accept" class="upload-input"/>
    {{title}}
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      files: null,
      accept: '.pdf,.jpg,.jpeg,.png',
      url: null
    }
  },
  props: ['title'],
  emits: ['change'],
  computed: {
    type_accept() {
      return this.accept;
    } 
  },
  methods: {
    selectFile() {
      this.$refs.file.click()
    },
    onFileChange() {
      if(this.$refs.file.files.length > 0) {
        console.log(this.$refs.file.files[0])
        this.url= URL.createObjectURL(this.$refs.file.files[0])
        this.files = this.$refs.file.files
        //this.upload()
      }
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onFileChange();
    },
    upload() {
      let data = new FormData();
      this.files.forEach((f,x) => {
        data.append('file', f);
      });
      data.append('name', 'avatar')
      this.url = null
      this.$emit('change', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-input {
  display: none
}
.preview-profile {
  text-align:center;
  .preview-profile-image {
    border: 1px solid #f1f1f1;
    padding: 5px;
    width: 200px;
    height: 200px;
    margin: 0 auto 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>