export default {
  "for_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สำหรับสมาชิก"])},
  "access_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ห้องเรียน"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อผู้ใช้งาน"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่าน"])},
  "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลืมรหัสผ่าน"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ระบบ"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หรือ"])},
  "login_with_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ระบบด้วยบัญชี Facebook"])},
  "not_a_member_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่เป็นสมาชิก? สมัครสมาชิก"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมัครสมาชิก"])},
  "more_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเพิ่มเติม"])},
  "back_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลับไปหน้าเข้าสู่ระบบ"])},
  "choose_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกหน่วยงาน"])},
  "group_code_if_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสกลุ่ม (ถ้ามี)"])},
  "group_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสกลุ่ม"])},
  "login_to_the_system_means_that_you_agree_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเข้าระบบนี้ ถือว่าท่านได้ยอมรับ"])},
  "register_means_that_you_agree_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสมัครสมาชิกนี้ ถือว่าท่านได้ยอมรับ"])},
  "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นโยบายข้อมูลส่วนบุคคล"])},
  "accept_and_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยันและสมัครสมาชิก"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
  "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ-นามสกุล"])},
  "set_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งรหัสผ่าน"])},
  "set_password_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งรหัสผ่าน (ซ้ำ)"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบอร์โทร"])},
  "register_to_access_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงทะเบียนเพื่อเข้าสู่ห้องเรียน"])},
  "enter_your_information_to_reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรอกข้อมูลเพื่อรับรหัสผ่านใหม่"])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับรหัสผ่านใหม่"])},
  "home_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รวมบทเรียนที่เต็มไปด้วยสาระ ความรู้ และประสบการณ์"])},
  "home_intro_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่ช่วยให้อาสาสมัครและคนทำงานพร้อมปฏิบัติงานจริง<br/>ช่วยให้เพื่อนผู้ใช้ยาลดพฤติกรรมเสี่ยง  ปลอดภัยจากเชื้อเอชไอวี<br/>เพื่อคุณภาพชีวิตที่ดีขึ้น"])},
  "knowledge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความรู้"])},
  "knowledge_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เสริมความรู้เรื่องยาเสพติด<br/>อย่างรอบด้าน"])},
  "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทักษะ"])},
  "skill_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างทักษะที่จำเป็นในการ<br>ทำงานร่วมกับเพื่อนผู้ใช้ยา"])},
  "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประสบการณ์"])},
  "experience_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับฟังประสบการณ์จริง<br/>จากมุมมองคนทำงาน"])},
  "many_contents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนื้อหาหลากหลาย"])},
  "many_contents_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนรู้จากผู้เชี่ยวชาญและทำงานจริง"])},
  "many_contents_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รับฟังประสบการณ์จากมุมมองอาสาสมัครและคนทำงาน"])},
  "many_contents_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถ่ายทอดเนื้อหาด้วยรูปแบบที่หลากหลาย"])},
  "many_contents_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สนุกกับเกมมากมายระหว่างการเรียน"])},
  "easy_fun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้ง่าย สนุกสนาน"])},
  "easy_fun_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียนได้ทุกเวลาตามที่สะดวก สนุกกับการเก็บเหรียญตราเป็นรางวัลระหว่างเรียน"])},
  "result_watch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัดผลและติดตามอย่างใกล้ชิด"])},
  "result_watch_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทบทวนความรู้ได้ตลอด"])},
  "result_watch_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัดความเข้าใจผ่านแบบทดสอบได้ทันที"])},
  "result_watch_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่ช่วยดูแลแก้ปัญหาอย่างตรงจุด"])},
  "all_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลักสูตรทั้งหมด"])},
  "seven_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกอบไปด้วย 7 หลักสูตร"])},
  "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หลักสูตร"])},
  "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บทเรียน"])},
  "knowledges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความรู้"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชั่วโมง"])},
  "inspiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรื่องราวบันดาลใจ"])},
  "from_frient_to_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เสียงจากเพื่อนสู่เพื่อน"])},
  "sponsors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้สนับสนุน"])},
  "peer_to_peer_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โครงการเพื่อนเพื่อเพื่อน"])},
  "peer_to_peer_project_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ในความร่วมมือของมูลนิธิรักษ์ไทยและผู้สนับสนุน ให้คนในสังคมมีคุณภาพ ชีวิตที่ดีขึ้นใส่ใจ พัฒนา และเสริมสร้างกลุ่มคน"])},
  "raksthai_foundation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มูลนิธิรักษ์ไทย"])},
  "contact_rakthai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ติดต่อ มูลนิธิรักษ์ไทย"])},
  "raksthai_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["185 ซอยประดิพัทธ์ 6 ถนนประดิพัทธ์ แขวงพญาไท เขตพญาไท กรุงเทพ 10400"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โทร"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล"])},
  "member_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลสมาชิก"])},
  "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขข้อมูลส่วนตัว"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออกจากระบบ"])},
  "choose_province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกจังหวัด"])},
  "choose_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกเพศ"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันเกิด"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัน"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดือน"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปี"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชาย"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หญิง"])},
  "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มกราคม"])},
  "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กุมภาพันธ์"])},
  "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีนาคม"])},
  "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมษายน"])},
  "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พฤษภาคม"])},
  "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มิถุนายน"])},
  "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรกฎาคม"])},
  "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิงหาคม"])},
  "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กันยายน"])},
  "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตุลาคม"])},
  "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พฤศจิกายน"])},
  "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ธันวาคม"])},
  "other_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบุชื่อหน่วยงาน"])},
  "peer_to_peer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพื่อนเพื่อเพื่อน"])}
}